const ENUMS = {
  COMMON_MSG: {
    INVALID_EMAIL: "Oops! The email format is incorrect",
    INVALID_PASSWORD:
      "Password must contain a minimum of 8 characters, including 1 number, 1 special character",
    PASSWORD_NOTMATCH: "Password and Confirm Password doesn't match",
    INVALID_TOKEN: "Sorry! Invalid Token",
    BLANK_FIELD_NOTALLOW: "Fields cannot be left blank",
    INVALID_URL: "Enter valid URL",
    INVALID_IMAGE_SIZE:
      "Uploaded image should not exceed a maximum size of 2 MB",
    INVALID_IMAGE_FILE_FORMAT: "Uploaded image must be in PNG, JPG, or JPEG",
    INVALID_IMAGE_ASPECT_RATIO: "Uploaded image must have a 16:9 aspect ratio",
    INVALID_VIDEO_SIZE:
      "Uploaded video should not exceed a maximum size of 2 MB",
    INVALID_VIDEO_FILE_FORMAT: "Uploaded video must be in MP4 or webm",
    VIDEO_FILE_SIZE: "Uploaded video must not exceed 2 GB",
    INVALID_TEXT_FILE_FORMAT: "Uploaded file must be in txt",
    INVALID_FILE: "Uploaded file is invalid",
    INVALID_DATA: "Uploaded file has no valid data",
  },
  USER_STATUS: [
    { value: 1, label: "Approved", color: "#61D345" },
    { value: 2, label: "Declined", color: "#FF5F6E" },
    { value: 3, label: "Request Sent", color: "#EF6B33" },
  ],
  USER_ROLE: {
    STAFF: "Staff",
    ADMIN: "Admin",
  },
  TEMPLATE_STATUS_LIST: [
    { id: 1, label: "Draft", color: "#606060" },
    { id: 2, label: "Approved", color: "#61D345" },
    { id: 3, label: "Rejected", color: "#FF5F6E" },
    { id: 4, label: "Pending", color: "#0085FF" },
    { id: 5, label: "Pending for Approval", color: "#0085FF" },
  ],
  TEMPLATE_STATUS_ENUM: {
    Draft: "Draft",
    Approved: "Approved",
    Rejected: "Rejected",
    Pending: "Pending",
    PendingForApproval: "Pending for Approval",
  },
  COUNTRY_LIST: [
    { label: "Afghanistan", value: "AF" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "American Samoa", value: "AS" },
    { label: "Andorra", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguilla", value: "AI" },
    { label: "Antarctica", value: "AQ" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bermuda", value: "BM" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Brazil", value: "BR" },
    { label: "British Indian Ocean Territory", value: "IO" },
    { label: "British Virgin Islands", value: "VG" },
    { label: "Brunei", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Cape Verde", value: "CV" },
    { label: "Cayman Islands", value: "KY" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Christmas Island", value: "CX" },
    { label: "Cocos Islands", value: "CC" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Cook Islands", value: "CK" },
    { label: "Costa Rica", value: "CR" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Curacao", value: "CW" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Democratic Republic of the Congo", value: "CD" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "East Timor", value: "TL" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Ethiopia", value: "ET" },
    { label: "Falkland Islands", value: "FK" },
    { label: "Faroe Islands", value: "FO" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "French Polynesia", value: "PF" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Greece", value: "GR" },
    { label: "Greenland", value: "GL" },
    { label: "Grenada", value: "GD" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernsey", value: "GG" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Isle of Man", value: "IM" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Ivory Coast", value: "CI" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: "Laos", value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Macau", value: "MO" },
    { label: "Macedonia", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mayotte", value: "YT" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia", value: "FM" },
    { label: "Moldova", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montenegro", value: "ME" },
    { label: "Montserrat", value: "MS" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "New Caledonia", value: "NC" },
    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "North Korea", value: "KP" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Palestine", value: "PS" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Qatar", value: "QA" },
    { label: "Republic of the Congo", value: "CG" },
    { label: "Reunion", value: "RE" },
    { label: "Romania", value: "RO" },
    { label: "Russia", value: "RU" },
    { label: "Rwanda", value: "RW" },
    { label: "Saint Barthelemy", value: "BL" },
    { label: "Saint Helena", value: "SH" },
    { label: "Saint Kitts and Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Martin", value: "MF" },
    { label: "Saint Pierre and Miquelon", value: "PM" },
    { label: "Saint Vincent and the Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia", value: "RS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Sint Maarten", value: "SX" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Korea", value: "KR" },
    { label: "South Sudan", value: "SS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Suriname", value: "SR" },
    { label: "Svalbard and Jan Mayen", value: "SJ" },
    { label: "Swaziland", value: "SZ" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syria", value: "SY" },
    { label: "Taiwan", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad and Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Turks and Caicos Islands", value: "TC" },
    { label: "Tuvalu", value: "TV" },
    { label: "U.S. Virgin Islands", value: "VI" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States", value: "US" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Vatican", value: "VA" },
    { label: "Venezuela", value: "VE" },
    { label: "Vietnam", value: "VN" },
    { label: "Wallis and Futuna", value: "WF" },
    { label: "Western Sahara", value: "EH" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" },
  ],
  REPEATMODE_LIST: [
    { label: "One Time", value: 1 },
    { label: "Daily", value: 2 },
    { label: "Weekly", value: 3 },
    // { label: 'Monthly', value: 4 },
    // { label: 'Yearly', value: 5 },
    // { label: "Birthday", value: 6 },
  ],
  WEEK_LIST: [
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
    { label: "Sunday", value: 7 },
  ],
  MONTH_LIST: [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ],
  TARGET_AUDIENCE_LIST: [
    { label: "All", value: "all" },
    { label: "iOS", value: "ios" },
    { label: "Android", value: "android" },
  ],
  REPEATE_MODE: {
    ONE_TIME: 1,
    DAILY: 2,
    WEEKLY: 3,
    MONTHLY: 4,
    YEARLY: 5,
    BIRTHDAY: 6,
  },
  ANNOUNCEMENT_STATUS: [
    { value: 1, label: "Draft", color: "#606060" },
    { value: 2, label: "Published", color: "#61D345" },
    { value: 3, label: "Rejected", color: "#FF5F6E" },
    { value: 4, label: "Pending", color: "#0085FF" },
    { value: 5, label: "Pending for Approval", color: "#0085FF" },
    { value: 6, label: "Unpublish", color: "#925ACF" },
    { value: 7, label: "Expired", color: "#FF0000" },
    { value: 8, label: "Active Soon", color: "#F9A533" },
    { value: 9, label: "Active", color: "#028B31" },
  ],
  ANNOUNCEMENT_STATUS_ENUM: {
    Draft: "Draft",
    Published: "Published",
    Rejected: "Rejected",
    Pending: "Pending",
    PendingForApproval: "Pending for Approval",
    Unpublish: "Unpublish",
    Expired: "Expired",
    ActiveSoon: "Active Soon",
    Active: "Active",
  },
  ACTION_STATUS_ENUM: {
    Approved: "approved",
    Rejected: "rejected",
    Unpublish: "unpublish",
    Published: "published",
  },
  SELECTALL_OPTION: {
    label: "Select all",
    value: "*",
  },
  THEME: {
    MAIN_COLOR: "#2D3838",
    LIGHT_COLOR: "#F1E7D5",
    BLACK_COLOR: "#000",
    WHITE_COLOR: "#fff",
    BORDER_COLOR: "#909696",
    FONT_FAMILY: "'Open Sans', sans-serif",
  },
  ANNOUNCEMENT_STATUS_LIST: [
    { value: "draft", label: "Draft" },
    { value: "pending", label: "Pending" },
    { value: "active", label: "Active" },
    { value: "active_soon", label: "Active Soon" },
    { value: "unpublish", label: "Unpublish" },
    { value: "published", label: "Published" },
    { value: "rejected", label: "Rejected" },
    { value: "expired", label: "Expired" },
  ],
  BIRTHDAY_STATUS_LIST: [
    { value: "draft", label: "Draft" },
    { value: "pending", label: "Pending" },
    { value: "unpublish", label: "Unpublish" },
    { value: "published", label: "Published" },
    { value: "rejected", label: "Rejected" },
  ],
  NOTIFYBEFORE_DAY_LIST: [
    { label: "Same Day", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
  ],
  ROWS_PER_PAGETEXT: "Records per Page:",
  DEFAULT_PAGESIZE: 10,
  COMMON_STYLE: {
    cardStyle: {
      boxShadow: "none",
      borderRadius: "15px",
      border: "1px solid #909696",
    },
    innerCardStyle: {
      boxShadow: "none",
      borderRadius: "15px",
      mb: 2,
      border: "1px solid #909696",
    },
    dividerStyle: {
      borderTop: "1px solid #909696",
    },
    tableStyle: function () {
      return {
        tableHeadCellStyle: {
          background: ENUMS.THEME.LIGHT_COLOR,
          fontWeight: "bold",
          fontSize: "16px",
          fontFamily: ENUMS.THEME.FONT_FAMILY,
        },
        tableBodyCellStyle: {
          fontSize: "14px",
          fontFamily: ENUMS.THEME.FONT_FAMILY,
        },
        popOverListItemIconStyle: { minWidth: "0px", marginRight: "10px" },
        popOverListItemStyle: { padding: "5px 14px" },
      };
    },
  },
  ROUTES: {
    VIRTUAL_TRAINING_PROGRAM: {
      LIST: "/virtual-training-program",
      ADD: "/virtual-training-program/add",
      EDIT: "/virtual-training-program/edit/",
      VIEW: "/virtual-training-program/view/",
    },
    BIRTHDAY: {
      LIST: "/birthday",
    },
  },
  COMMON_LABEL: {
    Add: "Add",
    VirtualTrainingProgram: "Virtual Training Program",
    Video: "Virtual Training Program",
  },
  VTP_STATUS_LIST: [
    { value: "Draft", label: "Draft" },
    { value: "Unpublish", label: "Unpublish" },
    { value: "Published", label: "Published" },
  ],
  VTP_ACTION_STATUS_ENUM: {
    Unpublish: "Unpublish",
    Published: "Published",
  },
  VTP_DEVICE_LIST: [
    { label: "Bike", value: "bike" },
    { label: "Rowing Machine", value: "rowing_machine" },
  ],
};
export default ENUMS;
