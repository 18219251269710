import React from "react";

const VirtualTrainingProgram = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="18"
    viewBox="0 0 512 512"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M580 4953 c-52 -8 -147 -47 -197 -80 -91 -60 -153 -141 -194 -253
  l-24 -65 0 -1355 0 -1355 24 -65 c30 -83 80 -159 139 -211 58 -51 168 -105
  243 -119 37 -7 711 -9 2025 -8 l1969 3 57 22 c77 29 159 85 209 141 50 57 105
  167 119 241 14 74 14 2628 0 2702 -14 74 -69 184 -119 241 -50 56 -132 112
  -209 141 l-57 22 -1980 1 c-1089 1 -1991 0 -2005 -3z m3958 -334 c26 -13 54
  -38 70 -62 l27 -41 0 -1316 0 -1316 -27 -41 c-16 -24 -44 -49 -70 -62 l-44
  -21 -1934 0 -1934 0 -44 21 c-26 13 -54 38 -70 62 l-27 41 0 1316 0 1316 27
  41 c15 23 44 49 70 62 l43 21 1934 0 1935 0 44 -21z"
      />
      <path
        d="M2015 4146 c-41 -18 -83 -69 -90 -109 -3 -18 -5 -406 -3 -864 3 -822
  3 -832 24 -859 38 -52 71 -69 132 -69 l57 0 655 409 c360 224 669 423 687 441
  56 59 56 151 0 210 -18 18 -326 216 -686 440 -577 360 -659 409 -700 412 -26
  2 -60 -3 -76 -11z m668 -711 c202 -126 367 -232 367 -235 0 -4 -630 -402 -772
  -488 l-38 -23 0 511 0 511 38 -23 c21 -13 203 -126 405 -253z"
      />
      <path
        d="M3339 1265 c-171 -42 -334 -183 -391 -338 l-15 -42 -1336 -5 c-1329
  -5 -1336 -5 -1363 -26 -53 -39 -69 -71 -69 -134 0 -63 16 -95 69 -134 27 -21
  34 -21 1361 -26 l1334 -5 41 -83 c187 -374 699 -418 946 -81 20 28 47 76 60
  107 l23 57 335 5 c320 5 336 6 363 26 48 35 73 82 73 134 0 52 -25 99 -73 134
  -27 20 -43 21 -363 26 l-335 5 -22 55 c-45 109 -141 216 -247 274 -108 59
  -271 80 -391 51z m243 -337 c95 -52 144 -168 114 -271 -45 -154 -220 -223
  -354 -140 -103 64 -141 198 -87 308 60 123 207 170 327 103z"
      />
    </g>
  </svg>
);

export default VirtualTrainingProgram;
