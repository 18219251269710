import * as React from "react";
import { useState, useRef } from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import ControlIcons from "./ControlIcons";
import { useEffect } from "react";
import { getVirtualTrainingUrlById } from "../../services/virtualTrainingProgram";

const format = (seconds) => {
  if (isNaN(seconds)) {
    return "00:00";
  }

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  } else {
    return `${mm}:${ss}`;
  }
};

function PreviewVirtualTrainingProgram({ file, fileName, id }) {
  const [playerstate, setPlayerState] = useState({
    playing: true,
    muted: false,
    volume: 1,
    playerbackRate: 1.0,
    played: 0,
    seeking: false,
    controlsVisible: false,
    loading: true,
  });
  const [videoUrl, setVideoUrl] = useState(null);

  //Destructure State in other to get the values in it
  const {
    playing,
    muted,
    volume,
    playerbackRate,
    played,
    seeking,
    controlsVisible,
    loading,
  } = playerstate;
  const playerRef = useRef(null);
  const playerDivRef = useRef(null);
  const controlsRef = useRef(null);

  //This function handles play and pause onchange button
  const handlePlayAndPause = () => {
    setPlayerState({ ...playerstate, playing: !playerstate.playing });
  };

  // Handlers for showing and hiding controls
  const showControls = () => {
    setPlayerState({ ...playerstate, controlsVisible: true });
  };

  const hideControls = () => {
    setPlayerState({ ...playerstate, controlsVisible: false });
  };

  const handleMuting = () => {
    setPlayerState({ ...playerstate, muted: !playerstate.muted });
  };

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30);
  };

  const handleVolumeChange = (e, newValue) => {
    setPlayerState({
      ...playerstate,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const handleVolumeSeek = (e, newValue) => {
    setPlayerState({
      ...playerstate,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const handlePlayerRate = (rate) => {
    setPlayerState({ ...playerstate, playerbackRate: rate });
  };

  const handleFullScreenMode = () => {
    screenfull.toggle(playerDivRef.current);
  };

  const handlePlayerProgress = (state) => {
    if (!playerstate.seeking) {
      setPlayerState({ ...playerstate, ...state });
    }
  };

  const handlePlayerSeek = (e, newValue) => {
    setPlayerState({ ...playerstate, played: parseFloat(newValue / 100) });
    playerRef.current.seekTo(parseFloat(newValue / 100));
  };

  const handlePlayerMouseSeekDown = (e) => {
    setPlayerState({ ...playerstate, seeking: true });
  };

  const handlePlayerMouseSeekUp = (e, newValue) => {
    setPlayerState({ ...playerstate, seeking: false });
    playerRef.current.seekTo(newValue / 100);
  };

  const handleReady = () => {
    setPlayerState({ ...playerstate, loading: false });
  };

  const handleBufferStart = () => {
    setPlayerState({ ...playerstate, loading: true });
  };

  const handleBufferEnd = () => {
    setPlayerState({ ...playerstate, loading: false });
  };

  const handlePlayerRestart = () => {
    setPlayerState({ ...playerstate, played: parseFloat(0 / 100) });
    playerRef.current.seekTo(parseFloat(0 / 100));
  };

  const currentPlayerTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : "00:00";
  const movieDuration = playerRef.current
    ? playerRef.current.getDuration()
    : "00:00";
  const playedTime = format(currentPlayerTime);
  const fullMovieTime = format(movieDuration);
  const clientHeight = document.documentElement.clientHeight - 220;

  useEffect(() => {
    if (file instanceof Blob) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const blob = new Blob([arrayBuffer], { type: file.type });
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
      };

      reader.readAsArrayBuffer(file);
    } else {
      const fetchUrlById = async () => {
        const response = await getVirtualTrainingUrlById(id);
        setVideoUrl(response.data.presigned_url);
      };

      fetchUrlById();
    }
  }, [file]);

  return (
    <div
      className="playerDiv"
      style={{ height: `${clientHeight}px` }}
      ref={playerDivRef}
      onMouseEnter={showControls}
      onMouseLeave={hideControls}
    >
      <ReactPlayer
        width={"100%"}
        height="100%"
        ref={playerRef}
        url={videoUrl}
        playing={playing}
        volume={volume}
        playbackRate={playerbackRate}
        onProgress={handlePlayerProgress}
        muted={muted}
        onReady={handleReady}
        onBuffer={handleBufferStart}
        onBufferEnd={handleBufferEnd}
      />

      <ControlIcons
        ref={controlsRef}
        key={volume.toString()}
        playandpause={handlePlayAndPause}
        playing={playing}
        rewind={handleRewind}
        fastForward={handleFastForward}
        muting={handleMuting}
        muted={muted}
        volumeChange={handleVolumeChange}
        volumeSeek={handleVolumeSeek}
        volume={volume}
        playerbackRate={playerbackRate}
        playRate={handlePlayerRate}
        fullScreenMode={handleFullScreenMode}
        played={played}
        onSeek={handlePlayerSeek}
        onSeekMouseUp={handlePlayerMouseSeekUp}
        onSeekMouseDown={handlePlayerMouseSeekDown}
        playedTime={playedTime}
        fullMovieTime={fullMovieTime}
        seeking={seeking}
        fileName={fileName}
        screenfull={screenfull}
        loading={loading}
        restart={handlePlayerRestart}
        controlsVisible={controlsVisible}
      />
    </div>
  );
}

export default PreviewVirtualTrainingProgram;
