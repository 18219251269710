import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Grid,
  Box,
  Tooltip,
  IconButton,
  TableSortLabel,
  Popover,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import Layout from "../layout/layout";
import { useNavigate } from "react-router-dom";
import {
  deleteTemplate,
  templateAction,
  templateListData,
} from "../../services/template-service";
import _ from "lodash";
import SearchComponent from "../common/search";
import { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DialogPrompt,
  NoRecordFoundBox,
  StatusComponent,
} from "../common/other-component";
import NoTemplateFoundImage from "../../assets/images/no-template.png";
import TableLoader from "../common/table-loader";
import ENUMS from "../../constants/enum";
import { getDateTime } from "../../helpers/datetime-util";
import {
  CheckCircle,
  CheckCircleOutlined,
  Close,
  Delete,
  Edit,
  MoreVert,
  Visibility,
} from "@mui/icons-material";
import moment from "moment";
import getUserRole from "../../helpers/getuser-role";
import { SkandikaContextData } from "../../context";
import { Toast } from "../common/toast-utils";

function Templates() {
  const checkUserRole = new getUserRole();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [pagesize, setPageSize] = useState(ENUMS.DEFAULT_PAGESIZE);
  const [size, setSize] = useState(0);
  const [page, setPage] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [reRender, setRender] = useState(0);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("desc");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [isDelteItem, setDeleteItem] = useState(false);
  const { refetchPendingCountData } = useContext(SkandikaContextData);
  const addTemplate = () => {
    navigate("/templates/create");
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const sorting = order === "desc" ? "-" + orderBy : orderBy;
        const request = {
          page_index: pageindex,
          page_size: pagesize,
          search_string: searchString,
          sorting: sorting,
        };
        const res = await templateListData(request);
        if (res.success) {
          const list_data = res.data.results
            ? res.data.results.map((e) => ({
                ...e,
                formatted_createddate: getDateTime(e.created_at),
              }))
            : [];
          setSize(res.data.count);
          setRows(list_data);
        } else {
          setRows([]);
          Toast.error(res.error_message);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        Toast.error(error);
        setRows([]);
        setLoading(false);
      }
    })();
  }, [reRender, pageindex, pagesize, searchString, order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageIndex(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageIndex(1);
    setPageSize(parseInt(event.target.value, 10));
  };

  const getStatus = (status) => {
    const item = ENUMS.TEMPLATE_STATUS_LIST.find(
      (d) => d.label.toLowerCase() === status.toLowerCase()
    );
    if (item) {
      return (
        <StatusComponent color={item.color} label={item.label} size="small" />
      );
    } else {
      return "";
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const sortedData = rows.sort((a, b) => {
  //   const aValue = a[orderBy]
  //   const bValue = b[orderBy]
  //   if (orderBy === 'role' && rows.some(e => e.role !== null)) {
  //     return order === 'asc' ? aValue['name'].localeCompare(bValue['name']) : bValue['name'].localeCompare(aValue['name'])
  //   }
  //   if (typeof aValue === 'number' && typeof bValue === 'number') {
  //     return order === 'asc' ? aValue - bValue : bValue - aValue
  //   } else if (moment(aValue, 'MM-DD-YYYY LT', true).isValid() && moment(bValue, 'MM-DD-YYYY LT', true).isValid()) {
  //     // Sort formatted date-time using moment.js
  //     return order === 'asc' ? moment(aValue, 'MM-DD-YYYY LT') - moment(bValue, 'MM-DD-YYYY LT') : moment(bValue, 'MM-DD-YYYY LT') - moment(aValue, 'MM-DD-YYYY LT')
  //   } else {
  //     return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
  //   }
  // })

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleAction = async (action) => {
    if (action === "APPROVE")
      await updateStatus(ENUMS.ACTION_STATUS_ENUM.Approved, selectedRow.id);
    else if (action === "REJECT")
      await updateStatus(ENUMS.ACTION_STATUS_ENUM.Rejected, selectedRow.id);
    else if (action === "DELETE") setDeleteItem(true);
    setAnchorEl(null);
  };

  const updateStatus = async (action_status, id) => {
    setActionLoading(true);
    try {
      let payload = {
        status: action_status,
      };
      const res = await templateAction(id, payload);
      if (res.success) {
        Toast.success(res.data.detail);
        setRender((p) => p + 1);
        await refetchPendingCountData();
      } else {
        Toast.error(res.error_message);
      }
    } catch (error) {
      Toast.error(error);
      console.log(error);
    }
    setActionLoading(false);
  };

  const handleTemplateItem = (item) => {
    if (
      checkUserRole.isAdmin() &&
      !_.isEmpty(item.status) &&
      (item.status.toLowerCase() ===
        ENUMS.TEMPLATE_STATUS_ENUM.Draft.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.TEMPLATE_STATUS_ENUM.Pending.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.TEMPLATE_STATUS_ENUM.PendingForApproval.toLowerCase())
    ) {
      navigate("/templates/edit/" + item.id);
    } else if (
      checkUserRole.isStaff() &&
      item.editable &&
      !_.isEmpty(item.status) &&
      item.status.toLowerCase() ===
        ENUMS.TEMPLATE_STATUS_ENUM.Draft.toLowerCase()
    ) {
      navigate("/templates/edit/" + item.id);
    } else {
      navigate("/templates/view/" + item.id);
    }
  };

  const confirmDelete = async () => {
    setActionLoading(true);
    try {
      const res = await deleteTemplate(selectedRow.id);
      if (res.success) {
        setDeleteItem(false);
        Toast.success(res.data.detail);
        setRender((p) => p + 1);
        await refetchPendingCountData();
      } else {
        Toast.error(res.error_message);
      }
    } catch (error) {
      Toast.error(error);
      console.log(error);
    }
    setActionLoading(false);
  };

  const labelDisplayedRows = ({ count }) => {
    // Calculate the total number of pages based on the total number of rows and rows per page
    const totalPages = Math.ceil(count / pagesize);
    return `Page ${pageindex} of ${totalPages}`;
  };
  return (
    <Layout>
      <Box component="div" mb={2}>
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid item>
            <Button
              variant="contained"
              size="large"
              className="common-btn"
              onClick={() => addTemplate()}
            >
              Add Template
            </Button>
          </Grid>
          <Grid item>
            <SearchComponent
              searchString={searchString}
              setSearchString={setSearchString}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="dashboardtblScroll theme-scroll-bar" id="style-1">
        {_.isEmpty(rows) && !loading ? (
          <NoRecordFoundBox img_url={NoTemplateFoundImage} />
        ) : (
          <Table size="small" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "40%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  Template Name
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "15%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "posted_by__user__first_name"}
                    direction={
                      orderBy === "posted_by__user__first_name" ? order : "asc"
                    }
                    onClick={() =>
                      handleRequestSort("posted_by__user__first_name")
                    }
                  >
                    Created By
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "posted_by__role__name"}
                    direction={
                      orderBy === "posted_by__role__name" ? order : "asc"
                    }
                    onClick={() => handleRequestSort("posted_by__role__name")}
                  >
                    Role
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={orderBy === "status" ? order : "asc"}
                    onClick={() => handleRequestSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "15%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "created_at"}
                    direction={orderBy === "created_at" ? order : "asc"}
                    onClick={() => handleRequestSort("created_at")}
                  >
                    Created Date
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableLoader cols={6} />
            ) : (
              <TableBody>
                {rows.map((tableRow, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.name}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.posted_by}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.role ? tableRow.role.name : ""}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.status ? getStatus(tableRow.status) : ""}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.formatted_createddate}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {checkUserRole.isStaff() && (
                          <>
                            {tableRow.editable &&
                            !_.isEmpty(tableRow.status) &&
                            tableRow.status.toLowerCase() ===
                              ENUMS.TEMPLATE_STATUS_ENUM.Draft.toLowerCase() ? (
                              <Tooltip
                                title="Edit"
                                placement="top"
                                PopperProps={{ disablePortal: true }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleTemplateItem(tableRow)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="View"
                                placement="top"
                                PopperProps={{ disablePortal: true }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleTemplateItem(tableRow)}
                                >
                                  <Visibility />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                        {checkUserRole.isAdmin() && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {!_.isEmpty(selectedRow) &&
                            tableRow.id === selectedRow.id &&
                            actionLoading ? (
                              <CircularProgress size={20} thickness={5} />
                            ) : (
                              <>
                                {!_.isEmpty(tableRow.status) &&
                                  (tableRow.status.toLowerCase() ===
                                    ENUMS.TEMPLATE_STATUS_ENUM.Approved.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.TEMPLATE_STATUS_ENUM.Rejected.toLowerCase()) && (
                                    <Tooltip
                                      title="View"
                                      placement="top"
                                      PopperProps={{ disablePortal: true }}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleTemplateItem(tableRow)
                                        }
                                      >
                                        <Visibility />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {!_.isEmpty(tableRow.status) &&
                                  (tableRow.status.toLowerCase() ===
                                    ENUMS.TEMPLATE_STATUS_ENUM.Draft.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.TEMPLATE_STATUS_ENUM.Pending.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.TEMPLATE_STATUS_ENUM.PendingForApproval.toLowerCase()) && (
                                    <Tooltip
                                      title="Edit"
                                      placement="top"
                                      PopperProps={{ disablePortal: true }}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleTemplateItem(tableRow)
                                        }
                                      >
                                        <Edit />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {!_.isEmpty(tableRow.status) && (
                                  <>
                                    <Tooltip
                                      title="More Actions"
                                      placement="top"
                                      PopperProps={{ disablePortal: true }}
                                    >
                                      <IconButton
                                        onClick={(event) =>
                                          handleMenuClick(event, tableRow)
                                        }
                                      >
                                        <MoreVert />
                                      </IconButton>
                                    </Tooltip>
                                    <Popover
                                      open={
                                        selectedRow === tableRow &&
                                        Boolean(anchorEl)
                                      }
                                      anchorEl={anchorEl}
                                      onClose={handleMenuClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <List
                                        sx={{
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        {!_.isEmpty(tableRow.status) &&
                                          (tableRow.status.toLowerCase() ===
                                            ENUMS.TEMPLATE_STATUS_ENUM.Pending.toLowerCase() ||
                                            tableRow.status.toLowerCase() ===
                                              ENUMS.TEMPLATE_STATUS_ENUM.PendingForApproval.toLowerCase() ||
                                            (!_.isEmpty(tableRow.role) &&
                                              tableRow.role.name ===
                                                ENUMS.USER_ROLE.ADMIN &&
                                              tableRow.status.toLowerCase() ===
                                                ENUMS.TEMPLATE_STATUS_ENUM.Draft.toLowerCase())) && (
                                            <>
                                              <ListItemButton
                                                style={
                                                  ENUMS.COMMON_STYLE.tableStyle()
                                                    .popOverListItemStyle
                                                }
                                                onClick={() =>
                                                  handleAction("APPROVE")
                                                }
                                                disabled={actionLoading}
                                              >
                                                <ListItemIcon
                                                  sx={
                                                    ENUMS.COMMON_STYLE.tableStyle()
                                                      .popOverListItemIconStyle
                                                  }
                                                  style={{ color: "#4BAE4F" }}
                                                >
                                                  <CheckCircleOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Approve" />
                                              </ListItemButton>
                                              <ListItemButton
                                                style={
                                                  ENUMS.COMMON_STYLE.tableStyle()
                                                    .popOverListItemStyle
                                                }
                                                onClick={() =>
                                                  handleAction("REJECT")
                                                }
                                                disabled={actionLoading}
                                              >
                                                <ListItemIcon
                                                  sx={
                                                    ENUMS.COMMON_STYLE.tableStyle()
                                                      .popOverListItemIconStyle
                                                  }
                                                  style={{ color: "#EE404C" }}
                                                >
                                                  <Close />
                                                </ListItemIcon>
                                                <ListItemText primary="Reject" />
                                              </ListItemButton>
                                            </>
                                          )}
                                        <ListItemButton
                                          style={
                                            ENUMS.COMMON_STYLE.tableStyle()
                                              .popOverListItemStyle
                                          }
                                          onClick={() => handleAction("DELETE")}
                                          disabled={actionLoading}
                                        >
                                          <ListItemIcon
                                            sx={
                                              ENUMS.COMMON_STYLE.tableStyle()
                                                .popOverListItemIconStyle
                                            }
                                            style={{ color: "#FC0005" }}
                                          >
                                            <Delete />
                                          </ListItemIcon>
                                          <ListItemText primary="Delete" />
                                        </ListItemButton>
                                      </List>
                                    </Popover>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        )}
      </div>
      {!_.isEmpty(rows) && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={size}
          rowsPerPage={pagesize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={ENUMS.ROWS_PER_PAGETEXT}
        />
      )}
      <DialogPrompt
        open={isDelteItem}
        onCancel={() => setDeleteItem(false)}
        onConfirm={() => confirmDelete()}
        title="Are you sure you want to delete this template?"
        loading={actionLoading}
      />
    </Layout>
  );
}

export default Templates;
