import React from "react";
import "./ControlIcons.css";
import Grid from "@mui/material/Grid";
import { CircularProgress, IconButton } from "@mui/material";

const SpinnerIcons = () => {
  return (
    <Grid
      container
      direction={"row"}
      alignItems="center"
      justifyContent="center"
      className="loader"
    >
      <IconButton className="controls__icons" aria-label="reqind">
        <CircularProgress
          sx={{
            color: "#f1e7d5",
          }}
        />
      </IconButton>
    </Grid>
  );
};

export default SpinnerIcons;
