import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import SignIn from "./components/authentication/sign-in";
import ForgotPassword from "./components/authentication/forgot-password";
import UserList from "./components/staff-user/user-list";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import VerifyCode from "./components/authentication/verify-code";
import ResetPassword from "./components/authentication/reset-password";
import Announcements from "./components/announcement/announcements";
import VirtualTrainingProgram from "./components/VirtulTrainingProgram/virtualTrainingProgram";
import Templates from "./components/template/templates";
import ENUMS from "./constants/enum";
import getUserRole from "./helpers/getuser-role";
import AddTemplate from "./components/template/add-template";
import DeclineInvite from "./components/authentication/decline-invite";
import AddAnnouncement from "./components/announcement/add-announcement";
import Context from "./context";
import UnderMaintenance from "./components/under-maintenance";
import AddVirtualTrainingProgram from "./components/VirtulTrainingProgram/addVirtualTrainingProgram";
import Birthday from "./components/birthday/birthday";
import AddBirthday from "./components/birthday/add-birthday";

const checkAuth = () => {
  var auth = localStorage.getItem("AccessToken");
  if (auth !== null) {
    if (
      localStorage.key("role") &&
      (localStorage.getItem("role") === ENUMS.USER_ROLE.STAFF ||
        localStorage.getItem("role") === ENUMS.USER_ROLE.ADMIN)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const AuthRoute = () => {
  const AdminAccessibleSites = [
    "/users",
    "/announcements",
    "/birthday",
    "/templates",
    ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.LIST,
  ];
  const StaffAccessibleSites = ["/announcements", "/birthday", "/templates"];
  const checkUserRole = new getUserRole();
  let location = useLocation();
  if (!checkAuth()) {
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} />;
  } else {
    const currentPath = "/" + window.location.pathname.split("/")[2];
    if (
      checkUserRole.isAdmin() &&
      !AdminAccessibleSites.includes(currentPath)
    ) {
      return (
        <Navigate to="/announcements" state={{ from: location }} replace />
      );
    }
    if (
      checkUserRole.isStaff() &&
      !StaffAccessibleSites.includes(currentPath)
    ) {
      return (
        <Navigate to="/announcements" state={{ from: location }} replace />
      );
    }
  }

  toast.dismiss();
  return <Outlet />;
};

function App() {
  return (
    <Context>
      <Router basename={process.env.REACT_APP_PUBLIC_URL}>
        <Routes>
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/verifycode" element={<VerifyCode />} />
          <Route path="/setpassword/:tokenparam" element={<ResetPassword />} />
          <Route
            path="/declineinvite/:tokenparam"
            element={<DeclineInvite />}
          />
          <Route path="/undermaintenance" element={<UnderMaintenance />} />
          <Route element={<AuthRoute />}>
            <Route path="/users" element={<UserList />} />
            {/* Announcement module start */}
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/announcements/create" element={<AddAnnouncement />} />
            <Route
              path="/announcements/edit/:id"
              element={<AddAnnouncement />}
            />
            <Route
              path="/announcements/view/:id"
              element={<AddAnnouncement />}
            />
            {/* Announcement module end */}

            {/* Birthday module start */}
            <Route path={ENUMS.ROUTES.BIRTHDAY.LIST} element={<Birthday />} />
            <Route
              path={`${ENUMS.ROUTES.BIRTHDAY.LIST}/create`}
              element={<AddBirthday />}
            />
            <Route
              path={`${ENUMS.ROUTES.BIRTHDAY.LIST}/edit/:id`}
              element={<AddBirthday />}
            />
            <Route
              path={`${ENUMS.ROUTES.BIRTHDAY.LIST}/view/:id`}
              element={<AddBirthday />}
            />
            {/* Birthday module end */}

            {/* VTP module start */}
            <Route
              path={ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.LIST}
              element={<VirtualTrainingProgram />}
            />
            <Route
              path={ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.ADD}
              element={<AddVirtualTrainingProgram />}
            />
            <Route
              path={`${ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.EDIT}:id`}
              element={<AddVirtualTrainingProgram />}
            />
            <Route
              path={`${ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.VIEW}:id`}
              element={<AddVirtualTrainingProgram />}
            />
            {/* VTP module end */}

            <Route path="/templates" element={<Templates />} />
            <Route path="/templates/create" element={<AddTemplate />} />
            <Route path="/templates/edit/:id" element={<AddTemplate />} />
            <Route path="/templates/view/:id" element={<AddTemplate />} />
          </Route>
          <Route path="/" element={<SignIn />} />
        </Routes>
        <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true} />
      </Router>
    </Context>
  );
}

export default App;
