import momenttimezone from 'moment-timezone'
import _ from 'lodash'
import moment from 'moment/moment'

export const getDateTime = (date, tz = null) => {
  if (tz == null) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    tz = timeZone
  }
  if (_.isEmpty(date)) return 'NA'
  const dt = momenttimezone.tz(date, tz).format('MM-DD-YYYY LT')
  return dt
}
export const convertUtcTimeToTimezone = (utcTimeString, targetTimezone) => {
  if (targetTimezone == null) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    targetTimezone = timeZone
  }
  // Assuming utcTimeString is in the format 'HH:mm'
  const utcMoment = moment.utc(utcTimeString, 'HH:mm')
  // Convert the UTC time to the specified timezone
  const timezoneMoment = utcMoment.tz(targetTimezone)
  return timezoneMoment.format('HH:mm') // Adjust the format as needed
}
export const convertTimePartWithTimezone = (timeString, targetTimezone) => {
  if (targetTimezone == null) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    targetTimezone = timeZone
  }
  // Assuming utcTimeString is in the format 'HH:mm'
  const utcMoment = moment(timeString, 'HH:mm')
  // Convert the UTC time to the specified timezone
  const timezoneMoment = utcMoment.tz(targetTimezone)
  return timezoneMoment.format('HH:mm') // Adjust the format as needed
}
