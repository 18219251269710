import { AppBar, Box, Container, Link, Toolbar, Typography } from '@mui/material'
import UnderMaintenanceImg from '../assets/images/undermaintenance.png'
import ENUMS from '../constants/enum'

export default function UnderMaintenance() {
  return (
    <div className='undermaintenance-container theme-scroll-bar'>
      {/* <Container maxWidth='md' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}></Container> */}
      <Box className='no-border no-record-box' sx={{ height: 'unset' }}>
        <img src={UnderMaintenanceImg} alt='undermaintenance' style={{ maxWidth: '200px', marginTop: '70px', padding: '50px' }} />
        <Typography component='h4' align='center' marginBottom='15px'>
          <b>We're Sorry!</b>
        </Typography>
        <Typography component='p' sx={{ margin: '0px 50px 15px' }} align='center'>
          The system is currently undergoing maintenance. We apologize for any inconvenience this may cause. Please bear with us as we work to improve your experience.
        </Typography>
        <Typography component='p' sx={{ margin: '0 150px 15px' }} align='center'>
          During this time, you may experience temporary disruptions in accessing our services. We appreciate your patience and understanding. If you have any urgent inquiries, please contact our{' '}
          <Link variant='body2' href='mailto:app-support@skandika.de' className='common-link' underline='none'>
            support
          </Link>{' '}
          team.
        </Typography>
        <Typography component='p' sx={{ margin: '0 150px 15px' }} align='center'>
          We thank you for your cooperation and look forward to providing you with an even better experience soon!
        </Typography>
      </Box>
    </div>
  )
}
