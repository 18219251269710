import React from "react";

// TODO: Needs to change video upload icon as it's upload button svg file
const VideoUpload = () => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.3438 23.0087C42.7854 15.1024 35.8417 9.16699 27.5 9.16699C20.8771 9.16699 15.125 12.9253 12.2604 18.4253C5.3625 19.1587 0 25.0024 0 32.0837C0 39.6691 6.16458 45.8337 13.75 45.8337H43.5417C49.8667 45.8337 55 40.7003 55 34.3753C55 28.3253 50.3021 23.4212 44.3438 23.0087ZM32.0833 29.792V38.9587H22.9167V29.792H16.0417L27.5 18.3337L38.9583 29.792H32.0833Z"
      fill="#2D3838"
    />
  </svg>
);

export default VideoUpload;
