import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Container,
  Divider,
  Typography,
  CardActions,
  Box,
  FormHelperText,
  LinearProgress,
} from "@mui/material";
import Layout from "../layout/layout";
import { HighlightOff, SmartDisplay } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import { useNavigate, useParams } from "react-router-dom";
import ENUMS from "../../constants/enum";
import { FloatingButton, MinimalMultipleSelect } from "../common/form-control";
import { DialogPrompt } from "../common/other-component";
import _, { isEmpty, last } from "lodash";
import {
  bytesToMB,
  formatDuration,
  getVideoDuration,
  isValidFileType,
  isValidTextFileType,
  isValidVideoFileSize,
  isValidVideoFileType,
} from "../../helpers/common-helper";
import { validateSchema } from "../../helpers/schema-validation";
import * as yup from "yup";
import { Toast } from "../common/toast-utils";
import {
  abortVirtualTrainingUpload,
  addVirtualTrainingProgram,
  completeVirtualTrainingUpload,
  generatePresignedUrl,
  getCompatibleDeviceForVTP,
  getVirtualTrainingProgramDetailById,
  updateVirtualTrainingProgram,
} from "../../services/virtualTrainingProgram";
import axios from "axios";
import PreviewVirtualTrainingProgram from "./previewVirtualTrainingProgram";
import { VTPLanguageContentCard } from "./VTPLanguageContentCard";
import { CardTitle } from "./VTPCardTitle";
import { ParseGPSData, extractUniqueRecords } from "./ExtractMetadata";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FileUpload from "../../assets/icons/fileUpload";
import ImageUpload from "../../assets/icons/imageUpload";
import VideoUpload from "../../assets/icons/videoUpload";

function AddVirtualTrainingProgram() {
  const urlParams = useParams();
  const navigate = useNavigate();

  const titleCharLimit = 60;
  const descriptionCharLimit = 400;
  const isViewOnly = window.location.pathname.includes("/view") ? true : false;

  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [showBackClickDialogue, setShowBackClickDialogue] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [infinitLinearProgress, setInfinitLinearProgress] = useState({
    video: false,
    file: false,
  });
  const [videoChunk, setVideoChunk] = useState(null);
  const [vtpContent, setVtpContent] = useState({
    id: null,
    name: {
      de: {
        name: "",
      },
      en: {
        name: "",
      },
      fr: {
        name: "",
      },
    },
    description: {
      de: {
        description: "",
      },
      en: {
        description: "",
      },
      fr: {
        description: "",
      },
    },
    device_codes: [],
    distance: 0.0,
    thumbnail: null,
    video_name: null,
    video_url: null,
    time: null,
    video_size: null,
    metadata: null,
    metadata_filename: null,
  });
  const [uploadedParts, setUploadedParts] = useState([]);
  const [completedUploads, setCompletedUploads] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [previewVideo, setPreviewVideo] = useState(false);
  const [disableBrowse, setDisableBrowse] = useState(isViewOnly);
  const [abortUpload, setAbortUpload] = useState(false);
  const [compatibleDeviceTypeList, setCompatibleDeviceTypeList] = useState([]);

  const cancellationFlag = useRef(false);
  const imageFileUploadInputRefs = {
    vtpInputRefs: useRef(),
  };
  const videoFileUploadInputRefs = {
    vtpInputRefs: useRef(),
  };
  const metadataFileUploadInputRefs = {
    vtpInputRefs: useRef(),
  };
  const textareaRefs = {
    deDescription: useRef(),
    enDescription: useRef(),
    frDescription: useRef(),
  };
  const textfieldRefs = {
    deTitle: useRef(),
    enTitle: useRef(),
    frTitle: useRef(),
  };

  const initialData = useRef();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const compatibleDeviceList = await getCompatibleDeviceForVTP();
        const convertedCompatibleDeviceList = compatibleDeviceList?.data.map(
          (item) => {
            const key = Object.keys(item)[0];
            const value = item[key];
            return { label: key, value: value };
          }
        );

        setCompatibleDeviceTypeList(convertedCompatibleDeviceList);
        if (!_.isEmpty(urlParams) && urlParams.id !== 0) {
          setLoading(true);
          const res = await getVirtualTrainingProgramDetailById(urlParams.id);
          if (res.success) {
            const {
              id,
              name,
              description,
              device_codes,
              video_name,
              video_url,
              thumbnail,
              video_size,
              time,
              status,
              distance,
              avg_speed,
              calories,
              altitude_min,
              altitude_max,
              map_data,
              graph_data,
            } = res.data;

            const extractedVtpContent = {
              id,
              name,
              description,
              device_codes: device_codes.map((value) =>
                convertedCompatibleDeviceList.find(
                  (item) => item.value === value
                )
              ),
              thumbnail,
              video_url,
              video_name,
              video_size,
              time,
              status,
              distance,
              avg_speed,
              calories,
              altitude_min,
              altitude_max,
              map_data,
              metadata: map_data?.metadata_url,
              metadata_filename: map_data?.metadata_filename,
              graph_data,
            };

            setVtpContent(extractedVtpContent);

            initialData.current = extractedVtpContent;
          } else {
            Toast.error(res.error_message);
          }
        }
      } catch (error) {
        Toast.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [urlParams]);

  useEffect(() => {
    setUploadProgress(
      Math.round(
        uploadedParts?.length
          ? (uploadedParts?.length / completedUploads?.totalChunks) * 100
          : -1
      )
    );
    if (uploadedParts?.length === completedUploads?.totalChunks) {
      handleCompleteUpload();
    }
  }, [uploadedParts]);

  const handleFileSelect = (event) => {
    setIsChangesMade(true);
    const file = event.target.files[0];
    if (file && isValidFileType(file)) {
      if (event.target.id) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (file.size > 2 * 1024 * 1024) {
            const error_message = {
              error: true,
              msg: ENUMS.COMMON_MSG.INVALID_IMAGE_SIZE,
            };
            handleLanguageContentError("thumbnail", error_message);
            return;
          }

          const base64 = e.target.result;
          const img = new Image();
          img.src = base64;

          img.onload = () => {
            const width = img.width;
            const height = img.height;
            const aspectRatio = width / height;
            let error_message = null;
            if (Math.abs(aspectRatio - 16 / 9) > 0.01) {
              error_message = {
                error: true,
                msg: ENUMS.COMMON_MSG.INVALID_IMAGE_ASPECT_RATIO,
              };
            } else {
              setVtpContent({
                ...vtpContent,
                thumbnail: e.target.result,
              });
            }
            handleLanguageContentError("thumbnail", error_message);
          };
        };
        reader.readAsDataURL(file);
        event.target.value = null;
      }
    } else if (file && !isValidFileType(file)) {
      const error_message = {
        error: true,
        msg: ENUMS.COMMON_MSG.INVALID_IMAGE_FILE_FORMAT,
      };
      handleLanguageContentError("thumbnail", error_message);
    }
  };

  const handleLanguageContentError = (property_name, error_message = null) => {
    setFieldErrors({ ...fieldErrors, [property_name]: error_message });
  };

  const handleVideoLanguageContentError = (
    property_name,
    error_message = null
  ) => {
    setFieldErrors({ ...fieldErrors, [property_name]: error_message });
  };

  const clearImage = () => {
    setVtpContent({ ...vtpContent, thumbnail: null });
  };

  const handleInputChange = (field, name, value) => {
    setIsChangesMade();
    if (field === "name" && field === "description") {
      setVtpContent({ ...vtpContent, [name]: value });
    } else if (field === "name" && value.length <= titleCharLimit) {
      const [field, lang, propName] = name.split(".");
      setVtpContent({
        ...vtpContent,
        [field]: {
          ...vtpContent[field],
          [lang]: {
            ...vtpContent[field][lang],
            [propName]: value,
          },
        },
      });
    } else if (
      field === "description" &&
      value.length <= descriptionCharLimit
    ) {
      const [field, lang, propName] = name.split(".");
      setVtpContent({
        ...vtpContent,
        [field]: {
          ...vtpContent[field],
          [lang]: {
            ...vtpContent[field][lang],
            [propName]: value,
          },
        },
      });
    }
  };

  const handleVideoBoxClick = (fileInputRef) => {
    // Trigger the hidden file input
    fileInputRef.current.click();
  };

  const handleImageBoxClick = (fileInputRef) => {
    // Trigger the hidden file input
    fileInputRef.current.click();
  };

  const handleSaveOrReviewBtnClick = async (e) => {
    e.preventDefault();
    const vtpContentValid = await checkContentValidation();

    if (vtpContentValid === true) {
      setLoading(true);
      if (vtpContent.id) {
        const updatedKeyValues = _.omitBy(vtpContent, (value, key) =>
          _.isEqual(value, initialData.current[key])
        );

        if (!isEmpty(updatedKeyValues) || isDataChanged) {
          updatedKeyValues.status = "Draft";

          if (updatedKeyValues.device_codes) {
            updatedKeyValues.device_codes = updatedKeyValues?.device_codes
              .filter((item) => item.label !== ENUMS.SELECTALL_OPTION.label)
              .map((item) => item.value);
          }

          try {
            const res = await updateVirtualTrainingProgram(
              vtpContent.id,
              updatedKeyValues
            );
            if (res.success) {
              navigate(ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.LIST);
              Toast.success(res.data.detail);
            } else {
              Toast.error(res.error_message);
            }
          } catch (error) {
            Toast.error(error);
          }
        }
      } else {
        const requestPayload = {
          ...vtpContent,
          status: "Draft",
          avg_speed: "0.0000000000", // Update value once ready
          calories: "0.0000000000", // Update value once ready
          altitude_min: "0.0000000000", // Update value once ready
          altitude_max: "0.0000000000", // Update value once ready
          graph_data: {}, // Update value once ready
          type: "Virtual Training",
        };

        requestPayload.device_codes = requestPayload?.device_codes
          .filter((item) => item.label !== ENUMS.SELECTALL_OPTION.label)
          .map((item) => item.value);

        try {
          const res = await addVirtualTrainingProgram(requestPayload);
          if (res.success) {
            navigate(ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.LIST);
            Toast.success(res.data.detail);
          } else {
            Toast.error(res.error_message);
          }
        } catch (error) {
          Toast.error(error);
        }
      }

      setLoading(false);
    }
  };

  const checkContentValidation = async () => {
    let isValid = null;

    // Define schemas for each language
    const titleSchema = yup.object().shape({
      name: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
    });

    const descriptionSchema = yup.object().shape({
      description: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
    });

    const schema = yup.object().shape({
      name: yup.object().shape({
        en: titleSchema,
        de: titleSchema,
        fr: titleSchema,
      }),
      description: yup.object().shape({
        en: descriptionSchema,
        de: descriptionSchema,
        fr: descriptionSchema,
      }),
      thumbnail: yup
        .string()
        .nullable()
        .required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      video_url: yup
        .string()
        .nullable()
        .required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      video_name: yup.string().nullable(),
      metadata: yup
        .string()
        .nullable()
        .required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      device_codes: yup.array().min(1, ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
    });

    isValid = await validateSchema(vtpContent, schema);
    setFieldErrors(isValid);
    return isValid;
  };

  const handleVideoError = (msg) => {
    const error_message = {
      error: true,
      msg: msg,
    };
    handleVideoLanguageContentError("video_url", error_message);
  };

  const handleVideoFileSelect = (event) => {
    cancellationFlag.current = false;
    setIsChangesMade(true);
    const file = event.target.files[0];

    if (file) {
      if (isValidVideoFileType(file) && isValidVideoFileSize(file)) {
        setInfinitLinearProgress({
          ...infinitLinearProgress,
          video: true,
        });
        clearVideo();
        setDisableBrowse(true);
        setVtpContent({
          ...vtpContent,
          video_url: file,
          video_name: file?.name,
          video_size: bytesToMB(file.size),
        });
        handleVideoLanguageContentError("video_url", null);
        generateVideoPresignedUrlAPI(file);
        setVideoChunk(file);
      } else {
        if (!isValidVideoFileType(file)) {
          handleVideoError(ENUMS.COMMON_MSG.INVALID_VIDEO_FILE_FORMAT);
        } else if (!isValidVideoFileSize(file)) {
          handleVideoError(ENUMS.COMMON_MSG.VIDEO_FILE_SIZE);
        }
      }
      event.target.value = null;
    }
  };

  const generateVideoPresignedUrlAPI = async (file) => {
    const fileSize = file.size;
    const chunkSize = Math.max(Math.round(fileSize / 20), 5 * 1024 * 1024);
    const totalChunks = Math.ceil(fileSize / chunkSize);
    const fileType = file.type;

    const reqData = {
      content_type: fileType,
      parts: totalChunks,
    };

    const MAX_CONCURRENT_UPLOADS = 5; // Maximum concurrent uploads

    try {
      const response = await generatePresignedUrl(reqData);
      setInfinitLinearProgress({
        ...infinitLinearProgress,
        video: false,
      });
      setCompletedUploads({
        complete: { key: response.data.key, uploadId: response.data.upload_id },
        totalChunks: totalChunks,
      });
      setUploadProgress(0);

      const uploadPromises = [];

      for (let i = 0; i < totalChunks; i++) {
        // Check if cancellation is requested
        if (cancellationFlag?.current) {
          break;
        }

        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, fileSize);
        const chunk = file.slice(start, end);

        uploadPromises.push(
          (async () => {
            try {
              await uploadPart(response.data.presigned_url[i], chunk, i + 1);
            } catch (error) {
              console.error("Error uploading chunk:", error);
            }
          })()
        );

        // If we reach the maximum concurrent uploads or the end of chunks, wait for ongoing uploads to finish
        if (
          uploadPromises.length === MAX_CONCURRENT_UPLOADS ||
          i === totalChunks - 1
        ) {
          await Promise.all(uploadPromises);
          uploadPromises.length = 0; // Clear the array
        }
      }
    } catch (error) {
      console.error("Error generating presigned URL:", error);
    }
  };

  const uploadPart = async (url, chunk, partNumber) => {
    try {
      const response = await axios.put(url, chunk);
      setUploadedParts((prevParts) => [
        ...prevParts,
        {
          ETag: response.headers.etag
            ? response.headers.etag.slice(1, -1).replace(/\\"/g, '"')
            : null,
          PartNumber: partNumber,
        },
      ]);
    } catch (error) {
      console.error("Error uploading part", partNumber, error);
    }
  };

  const handleCompleteUpload = async () => {
    // Call complete upload endpoint with uploadedParts
    const uploadedPartsASC = uploadedParts.sort(
      (a, b) => a.PartNumber - b.PartNumber
    );
    const completePayload = {
      parts: uploadedPartsASC,
      key: completedUploads.complete.key,
      upload_id: completedUploads.complete.uploadId,
    };
    try {
      await completeVirtualTrainingUpload(completePayload);
      setAbortUpload(false);
      setDisableBrowse(false);
    } catch (error) {
      console.error("Error completing upload", error);
    }

    try {
      const duration = await getVideoDuration(vtpContent?.video_url);
      setVtpContent({
        ...vtpContent,
        time: formatDuration(duration),
        video_url: completedUploads.complete.key,
      });
    } catch (error) {
      console.error("Error getting video duration:", error);
      setVtpContent({
        ...vtpContent,
        time: "00:00:00",
      });
    }
  };

  const handleAbortUploadAPI = async () => {
    // Call complete upload endpoint with uploadedParts
    setAbortUpload(false);
    const completePayload = {
      key: completedUploads.complete.key,
      upload_id: completedUploads.complete.uploadId,
    };
    cancellationFlag.current = true;
    setInfinitLinearProgress({
      ...infinitLinearProgress,
      video: true,
    });
    try {
      await abortVirtualTrainingUpload(completePayload);
      setInfinitLinearProgress({
        ...infinitLinearProgress,
        video: false,
      });
      setDisableBrowse(false);
      clearVideo(true);
    } catch (error) {
      console.error("handleCancleUpload => ", error);
    }
  };

  const clearVideo = (isFromAbort = false) => {
    const hasVideoName = !isEmpty(vtpContent.video_name);
    const isUploading = uploadProgress >= 0;
    const isUploadIncomplete =
      uploadProgress !== 100 || infinitLinearProgress.video;

    if (
      hasVideoName &&
      !infinitLinearProgress.video &&
      isUploading &&
      !isFromAbort &&
      isUploadIncomplete
    ) {
      setAbortUpload(true);
    } else {
      setVtpContent({ ...vtpContent, video_url: null, video_name: null });
      setVideoChunk(null);
      setUploadProgress(-1);
      setCompletedUploads(null);
      setUploadedParts([]);
      setDisableBrowse(false);
    }
  };

  const setIsChangesMade = (isFileChanged = false) => {
    if (vtpContent.id) {
      const updatedKeyValues = _.omitBy(vtpContent, (value, key) =>
        _.isEqual(value, initialData.current[key])
      );

      setIsDataChanged(!isEmpty(updatedKeyValues) || isFileChanged);
    } else {
      setIsDataChanged(true);
    }
  };

  // Metadata document Upload start

  const handleMeatadataBoxClick = (fileInputRef) => {
    fileInputRef.current.click();
  };

  const clearMetadata = () => {
    setVtpContent({ ...vtpContent, metadata: null, metadata_filename: null });
  };

  const handleFileError = (error) => {
    if (error?.invalid_gps_data) {
      const error_message = {
        error: true,
        msg: ENUMS.COMMON_MSG.INVALID_DATA,
      };
      handleTextLanguageContentError("metadata", error_message);
      setInfinitLinearProgress({
        ...infinitLinearProgress,
        file: false,
      });
      clearMetadata();
    } else {
      const error_message = {
        error: true,
        msg: ENUMS.COMMON_MSG.INVALID_FILE,
      };
      handleTextLanguageContentError("metadata", error_message);
      setInfinitLinearProgress({
        ...infinitLinearProgress,
        file: false,
      });
      clearMetadata();
    }
  };

  const handleMetadataFileSelect = (event) => {
    setIsChangesMade(true);
    const file = event.target.files[0];

    if (file) {
      if (isValidTextFileType(file)) {
        setInfinitLinearProgress({
          ...infinitLinearProgress,
          file: true,
        });
        clearMetadata();
        const fileName = file.name?.split(".")?.[0] || "VTP";
        setVtpContent({
          ...vtpContent,
          metadata_filename: fileName,
        });
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target.result;
          ParseGPSData(text)
            .then((parsedData) => {
              extractUniqueRecords(parsedData)
                .then((gpsData) => {
                  if (gpsData?.[0] && isEmpty(gpsData?.[0])) {
                    const error_message = {
                      error: true,
                      msg: ENUMS.COMMON_MSG.INVALID_FILE,
                    };
                    handleTextLanguageContentError("metadata", error_message);
                    setInfinitLinearProgress({
                      ...infinitLinearProgress,
                      file: false,
                    });
                    clearMetadata();
                  } else {
                    generateTextPresignedUrlAPI(
                      gpsData,
                      fileName,
                      last(gpsData)?.distance
                    );
                  }
                })
                .catch((error) => {
                  handleFileError(error);
                });
            })
            .catch((error) => {
              handleFileError(error);
            });
        };
        reader.readAsText(file);
        handleTextLanguageContentError("metadata", null);
      } else {
        const error_message = {
          error: true,
          msg: ENUMS.COMMON_MSG.INVALID_TEXT_FILE_FORMAT,
        };
        handleTextLanguageContentError("metadata", error_message);
        setInfinitLinearProgress({
          ...infinitLinearProgress,
          file: false,
        });
      }
      event.target.value = null;
    }
  };

  const uploadMetadataJson = async (
    presignedUrl,
    formData,
    metadata_key = "",
    metadataFileName = "",
    distance = 0
  ) => {
    try {
      // Make the PUT request to upload the file
      const response = await axios.put(presignedUrl, formData, {
        headers: { "X-Amz-Acl": "public-read" },
      });

      if (response) {
        setVtpContent({
          ...vtpContent,
          metadata: metadata_key,
          metadata_filename: metadataFileName,
          map_data: {
            metadata_url: metadata_key,
            metadata_filename: metadataFileName,
          },
          distance: Number(distance.toFixed(2)),
        });

        setInfinitLinearProgress({
          ...infinitLinearProgress,
          file: false,
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const generateTextPresignedUrlAPI = async (jsonData, fileName, distance) => {
    const blob = new Blob([JSON.stringify(jsonData)]);

    const reqData = {
      parts: 1,
      is_metadata: true,
    };

    try {
      const response = await generatePresignedUrl(reqData);
      await uploadMetadataJson(
        response.data.metadata_url,
        blobToFile(blob, fileName),
        response.data.metadata_key,
        fileName,
        distance
      );
    } catch (error) {}
  };

  function blobToFile(blob, filename, lastModified = Date.now()) {
    const newFile = new File([blob], filename, {
      type: blob.type,
      lastModified,
    });
    return newFile;
  }

  const handleTextLanguageContentError = (
    property_name,
    error_message = null
  ) => {
    setFieldErrors({ ...fieldErrors, [property_name]: error_message });
  };

  const handleDeviceCompatiblity = (e) => {
    setVtpContent({
      ...vtpContent,
      device_codes: e,
    });
  };

  useEffect(() => {
    setIsChangesMade();
  }, [vtpContent]);

  // Metadata document Upload End

  return (
    <Layout>
      <RingSpinnerOverlay
        loading={loading}
        overlayColor="rgba(0,0,0,0.2)"
        size={50}
        color="#2d38384d"
        borderWidth={3}
      />
      {!previewVideo ? (
        <Container component="div" maxWidth="xl" className="plr-0">
          <Card component="div" sx={ENUMS.COMMON_STYLE.cardStyle}>
            <CardHeader
              title={
                <CardTitle
                  previewVideo={previewVideo}
                  isViewOnly={isViewOnly}
                  isDataChanged={isDataChanged}
                  setShowBackClickDialogue={setShowBackClickDialogue}
                  setPreviewVideo={setPreviewVideo}
                />
              }
              className="template-cardheader"
            />
            <CardContent className="template-card-content-scroll theme-scroll-bar">
              <Card
                component="div"
                sx={ENUMS.COMMON_STYLE.innerCardStyle}
                style={{ overflow: "visible" }}
              >
                <CardHeader
                  title="Device Categories"
                  className="template-sub-cardheader"
                />
                <CardContent>
                  <Grid container spacing={2} display={"flex"}>
                    <Grid item xs={4} className="reactselect-dropdown-block">
                      <MinimalMultipleSelect
                        placeholder="Select Categories"
                        value={vtpContent.device_codes}
                        options={[...compatibleDeviceTypeList]}
                        onInputChange={(e) => handleDeviceCompatiblity(e)}
                        onInputFocus={() => {
                          setFieldErrors({
                            ...fieldErrors,
                            device_codes: { error: false, msg: "" },
                          });
                        }}
                        error={
                          fieldErrors.device_codes &&
                          fieldErrors.device_codes.error
                        }
                        helperText={
                          fieldErrors.device_codes &&
                          fieldErrors.device_codes.msg
                        }
                        id=" device_codes"
                        disabled={isViewOnly}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <VTPLanguageContentCard
                language="German"
                shortLanguage="de"
                vtpContent={vtpContent}
                handleInputChange={handleInputChange}
                fieldErrors={fieldErrors}
                setFieldErrors={setFieldErrors}
                textfieldRefs={textfieldRefs}
                textareaRefs={textareaRefs}
                titleCharLimit={titleCharLimit}
                descriptionCharLimit={descriptionCharLimit}
                isViewOnly={isViewOnly}
              />
              <VTPLanguageContentCard
                language="English"
                shortLanguage="en"
                vtpContent={vtpContent}
                handleInputChange={handleInputChange}
                fieldErrors={fieldErrors}
                setFieldErrors={setFieldErrors}
                textfieldRefs={textfieldRefs}
                textareaRefs={textareaRefs}
                titleCharLimit={titleCharLimit}
                descriptionCharLimit={descriptionCharLimit}
                isViewOnly={isViewOnly}
              />
              <VTPLanguageContentCard
                language="French"
                shortLanguage="fr"
                vtpContent={vtpContent}
                handleInputChange={handleInputChange}
                fieldErrors={fieldErrors}
                setFieldErrors={setFieldErrors}
                textfieldRefs={textfieldRefs}
                textareaRefs={textareaRefs}
                titleCharLimit={titleCharLimit}
                descriptionCharLimit={descriptionCharLimit}
                isViewOnly={isViewOnly}
              />

              <div className="mb-20">
                <Grid container spacing={2}>
                  {/* Upload Video */}
                  <Grid item xs={6} sx={{ mt: "16px" }}>
                    <Box elevation={3} style={{ height: "170px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Box
                            elevation={3}
                            className="dashed-border no-record-box"
                            sx={{ height: "150px", padding: "10px" }}
                            onClick={() => {
                              if (
                                !disableBrowse &&
                                !isViewOnly &&
                                !vtpContent.video_name
                              )
                                handleVideoBoxClick(
                                  videoFileUploadInputRefs.vtpInputRefs
                                );
                            }}
                          >
                            <input
                              ref={videoFileUploadInputRefs.vtpInputRefs}
                              type="file"
                              accept="video/*"
                              style={{ display: "none" }}
                              onChange={handleVideoFileSelect}
                              required
                              id="en.video"
                            />
                            {isEmpty(vtpContent.video_name) && (
                              <>
                                <VideoUpload
                                  sx={{
                                    fontSize: "55px",
                                    color: ENUMS.THEME.MAIN_COLOR,
                                    cursor: "pointer",
                                  }}
                                />
                                <Typography component="p">
                                  Upload your video here
                                </Typography>
                                <Typography component="p">Upto 2 GB</Typography>
                              </>
                            )}

                            {!isEmpty(vtpContent.video_name) && (
                              <>
                                <SmartDisplay
                                  sx={{
                                    fontSize: "55px",
                                    color: ENUMS.THEME.MAIN_COLOR,
                                  }}
                                />
                                <Typography component="p">
                                  {vtpContent.video_name}
                                </Typography>
                                {infinitLinearProgress.video && (
                                  <Box sx={{ width: "100%" }}>
                                    <LinearProgress color="inherit" />{" "}
                                  </Box>
                                )}
                                {!isEmpty(vtpContent.video_name) &&
                                  !infinitLinearProgress.video &&
                                  uploadProgress >= 0 && (
                                    <Box sx={{ width: "100%" }}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box sx={{ width: "100%", mr: 1 }}>
                                          <LinearProgress
                                            color="inherit"
                                            variant="determinate"
                                            value={uploadProgress}
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            minWidth: 35,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            color="text.secondary"
                                          >{`${uploadProgress}%`}</Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            minWidth: 35,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {(uploadProgress !== 100 ||
                                            infinitLinearProgress.video) && (
                                            <HighlightOff
                                              sx={{
                                                color: ENUMS.THEME.MAIN_COLOR,
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                setAbortUpload(true)
                                              }
                                            />
                                          )}
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                              </>
                            )}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            display: "inline-flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <div>
                            <FloatingButton
                              baseClassName="common-btn"
                              text="Clear"
                              onClick={() => {
                                if (!isViewOnly) clearVideo();
                              }}
                              sx={{ mb: 2 }}
                              disabled={
                                isEmpty(vtpContent.video_name) || isViewOnly
                              }
                            />
                            <FloatingButton
                              baseClassName="common-btn"
                              text="Preview"
                              onClick={() => {
                                setPreviewVideo(true);
                              }}
                              sx={{ mb: 2 }}
                              disabled={isEmpty(vtpContent.video_name)}
                            />
                            <FloatingButton
                              baseClassName="common-btn"
                              text="Browse"
                              onClick={() => {
                                if (!isViewOnly)
                                  handleVideoBoxClick(
                                    videoFileUploadInputRefs.vtpInputRefs
                                  );
                              }}
                              disabled={disableBrowse || isViewOnly}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <FormHelperText
                        error={
                          fieldErrors.video_url && fieldErrors.video_url.error
                        }
                      >
                        {fieldErrors.video_url && fieldErrors.video_url.msg}
                      </FormHelperText>
                      {/* Content for the left grid box */}
                    </Box>
                  </Grid>

                  {/* Upload Image */}
                  <Grid item xs={6} sx={{ mt: "16px" }}>
                    <Box elevation={3} style={{ height: "170px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Box
                            elevation={3}
                            className="dashed-border no-record-box"
                            sx={{ height: "150px", padding: "10px" }}
                            onClick={() => {
                              if (!isViewOnly && isEmpty(vtpContent.thumbnail))
                                handleImageBoxClick(
                                  imageFileUploadInputRefs.vtpInputRefs
                                );
                            }}
                          >
                            <input
                              ref={imageFileUploadInputRefs.vtpInputRefs}
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={handleFileSelect}
                              required
                              id="en.image"
                            />
                            {isEmpty(vtpContent.thumbnail) && (
                              <>
                                <ImageUpload
                                  sx={{
                                    fontSize: "55px",
                                    color: ENUMS.THEME.MAIN_COLOR,
                                    cursor: "pointer",
                                  }}
                                />
                                <Typography component="p">
                                  Upload your image here
                                </Typography>
                                <Typography component="p">
                                  16:9 in ratio
                                </Typography>
                              </>
                            )}
                            {!isEmpty(vtpContent.thumbnail) && (
                              <img
                                src={vtpContent.thumbnail}
                                alt="english content img"
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            display: "inline-flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <div>
                            <FloatingButton
                              baseClassName="common-btn"
                              text="Clear"
                              onClick={() => {
                                if (!isViewOnly) clearImage();
                              }}
                              sx={{ mb: 2 }}
                              disabled={
                                isViewOnly || isEmpty(vtpContent.thumbnail)
                              }
                            />
                            <FloatingButton
                              baseClassName="common-btn"
                              text="Browse"
                              onClick={() => {
                                if (!isViewOnly)
                                  handleImageBoxClick(
                                    imageFileUploadInputRefs.vtpInputRefs
                                  );
                              }}
                              disabled={isViewOnly}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <FormHelperText
                        error={
                          fieldErrors.thumbnail && fieldErrors.thumbnail.error
                        }
                      >
                        {fieldErrors.thumbnail && fieldErrors.thumbnail.msg}
                      </FormHelperText>
                      {/* Content for the left grid box */}
                    </Box>
                  </Grid>

                  {/* Upload Doc */}
                  <Grid item xs={6} sx={{ mt: "26px" }}>
                    <Box elevation={3} style={{ height: "170px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Box
                            elevation={3}
                            className="dashed-border no-record-box"
                            sx={{ height: "150px", padding: "10px" }}
                            onClick={() => {
                              if (!isViewOnly && !vtpContent.metadata_filename)
                                handleMeatadataBoxClick(
                                  metadataFileUploadInputRefs.vtpInputRefs
                                );
                            }}
                          >
                            <input
                              ref={metadataFileUploadInputRefs.vtpInputRefs}
                              type="file"
                              accept="text/plain"
                              style={{ display: "none" }}
                              onChange={handleMetadataFileSelect}
                              required
                              id="en.metadata"
                            />
                            {!vtpContent.metadata_filename && (
                              <>
                                <FileUpload
                                  sx={{
                                    fontSize: "55px",
                                    color: ENUMS.THEME.MAIN_COLOR,
                                    cursor: "pointer",
                                  }}
                                />
                                <Typography component="p">
                                  Upload your metadata file here
                                </Typography>
                              </>
                            )}
                            {vtpContent.metadata_filename && (
                              <>
                                <DescriptionOutlinedIcon
                                  sx={{
                                    fontSize: "55px",
                                    color: ENUMS.THEME.MAIN_COLOR,
                                  }}
                                />
                                <Typography component="p">
                                  {vtpContent.metadata_filename}
                                </Typography>
                                {infinitLinearProgress.file && (
                                  <Box sx={{ width: "100%" }}>
                                    <LinearProgress color="inherit" />{" "}
                                  </Box>
                                )}
                              </>
                            )}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            display: "inline-flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <div>
                            <FloatingButton
                              baseClassName="common-btn"
                              text="Clear"
                              onClick={() => {
                                if (!isViewOnly) clearMetadata();
                              }}
                              sx={{ mb: 2 }}
                              disabled={
                                isViewOnly || isEmpty(vtpContent.metadata)
                              }
                            />
                            <FloatingButton
                              baseClassName="common-btn"
                              text="Browse"
                              onClick={() => {
                                if (!isViewOnly)
                                  handleMeatadataBoxClick(
                                    metadataFileUploadInputRefs.vtpInputRefs
                                  );
                              }}
                              disabled={isViewOnly}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <FormHelperText
                        error={
                          fieldErrors.metadata && fieldErrors.metadata.error
                        }
                      >
                        {fieldErrors.metadata && fieldErrors.metadata.msg}
                      </FormHelperText>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
            <Divider sx={ENUMS.COMMON_STYLE.dividerStyle} />
            <CardActions
              className="template-cardfooter"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Box>
                <FloatingButton
                  text="Save"
                  baseClassName="common-btn"
                  onClick={(e) => {
                    if (!isViewOnly) handleSaveOrReviewBtnClick(e);
                  }}
                  disabled={
                    loading ||
                    isViewOnly ||
                    !isDataChanged ||
                    (uploadProgress !== 100 && uploadProgress >= 0)
                  }
                />
              </Box>
            </CardActions>
          </Card>
        </Container>
      ) : (
        <Container component="div" maxWidth="xl" className="plr-0">
          <Card component="div" sx={ENUMS.COMMON_STYLE.cardStyle}>
            <CardHeader
              title={
                <CardTitle
                  previewVideo={previewVideo}
                  isViewOnly={isViewOnly}
                  isDataChanged={isDataChanged}
                  setShowBackClickDialogue={setShowBackClickDialogue}
                  setPreviewVideo={setPreviewVideo}
                />
              }
              className="template-cardheader"
            />
            <CardContent>
              <PreviewVirtualTrainingProgram
                file={videoChunk || vtpContent?.video_url}
                fileName={vtpContent?.video_name}
                id={urlParams.id}
              />
            </CardContent>
          </Card>
        </Container>
      )}
      <DialogPrompt
        open={showBackClickDialogue}
        onCancel={() => setShowBackClickDialogue(false)}
        onConfirm={() => {
          navigate(ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.LIST);
        }}
        title="Clicking on the back, will discard your changes"
      />
      <DialogPrompt
        open={abortUpload}
        onCancel={() => setAbortUpload(false)}
        onConfirm={() => handleAbortUploadAPI()}
        title="Are you sure you want to abort the uploading process?"
      />
    </Layout>
  );
}

export default AddVirtualTrainingProgram;
