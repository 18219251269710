import React, { useEffect } from 'react'
import { Button, CircularProgress, TextField, Select, MenuItem, FormHelperText, Box, IconButton, Tooltip } from '@mui/material'
import '../../App.css'
import { Event, Info, KeyboardArrowDown } from '@mui/icons-material'
import MultipleSelect from './multiple-reactselect'
import makeAnimated from 'react-select/animated'
import ReactSelect, { components } from 'react-select'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import _, { isEmpty } from 'lodash'
import ENUMS from '../../constants/enum'

export const FloatingButton = ({ loading, baseClassName, text, style, disabled, onClick, sx, size = 'large', type = 'button' }) => {
  const styles = { ...style }
  return (
    <Button onClick={onClick} style={styles} disabled={disabled} sx={sx} size={size} className={baseClassName} variant='contained' type={type}>
      {text}
      {loading && <CircularProgress size={20} thickness={5} style={{ color: '#fff', marginLeft: '10px' }} />}
    </Button>
  )
}

export const MinimalTextField = ({ value, placeholder, style, error, helperText, endAdornmentProp, InputRef, sx, isRequired = false, isBoxShadow = false, label = '', type = 'text', onChange = () => {}, onInput = () => {}, onFocus = () => {}, ...props }) => {
  const styles = { ...style }
  return (
    <TextField
      margin='normal'
      {...isRequired}
      fullWidth
      label={label}
      InputProps={{
        style: {
          boxShadow: isBoxShadow ? '0px 0px 15px rgba(0, 0, 0, .2)' : 'none',
          fontFamily: ENUMS.THEME.FONT_FAMILY,
        },
        endAdornment: endAdornmentProp,
        inputRef: InputRef,
      }}
      InputLabelProps={{
        required: false, // Set required to false to hide the asterisk,
        shrink: false, //prevent floating label
      }}
      onFocus={onFocus}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      value={value}
      onInput={onInput}
      style={styles}
      type={type}
      onChange={onChange}
      sx={sx}
      autoComplete='off'
      {...props}
    />
  )
}

export const MinimalTextArea = ({ value, placeholder, style, error, helperText, endAdornmentProp, InputRef, isRequired = false, isBoxShadow = false, label = '', type = 'text', onChange = () => {}, onInput = () => {}, onFocus = () => {}, ...props }) => {
  const styles = { ...style }
  return (
    <TextField
      fullWidth
      multiline
      rows={4}
      margin='normal'
      {...isRequired}
      label={label}
      InputProps={{
        style: {
          boxShadow: isBoxShadow ? '0px 0px 15px rgba(0, 0, 0, .2)' : 'none',
          fontFamily: ENUMS.THEME.FONT_FAMILY,
        },
        endAdornment: endAdornmentProp,
        inputRef: InputRef,
      }}
      onFocus={onFocus}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      value={value}
      onInput={onInput}
      style={styles}
      type={type}
      onChange={onChange}
      autoComplete='off'
      {...props}
    />
  )
}

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input type='checkbox' checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const allOption = {
  label: 'Select all',
  value: '*',
}

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>
}

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = 'All'
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

const animatedComponents = makeAnimated()
export const MinimalMultipleSelect = ({ value, placeholder, options, error, helperText, onInputChange = () => {}, onInputFocus = () => {}, id = null, disabled = false }) => {
  return (
    <>
      <MultipleSelect
        placeholder={<div className='select-placeholder-text'>{placeholder}</div>}
        value={value}
        options={options}
        onChange={onInputChange}
        onFocus={onInputFocus}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          MultiValue,
          ValueContainer,
          animatedComponents,
        }}
        allowSelectAll={true}
        styles={{
          control: (provided, state) => ({
            ...provided,
            boxShadow: 'none',
            padding: '5px 14px',
            fontSize: '1rem',
            fontWeight: 400,
            letterSpacing: '0.00938em',
            marginTop: '5px',
            borderColor: ENUMS.THEME.BORDER_COLOR,
            '&:hover': {
              borderColor: ENUMS.THEME.BORDER_COLOR,
            },
          }),
          valueContainer: provided => ({
            ...provided,
            padding: '9px 0',
          }),
          placeholder: provided => ({
            ...provided,
            color: '#a2a2a2',
          }),
          multiValue: provided => ({
            ...provided,
          }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
          }),
          option: provided => ({
            ...provided,
            backgroundColor: ENUMS.THEME.WHITE_COLOR,
            color: ENUMS.BLACK_COLOR,
            '&:hover': {
              backgroundColor: ENUMS.THEME.MAIN_COLOR,
              color: ENUMS.THEME.WHITE_COLOR,
            },
          }),
          dropdownIndicator: provided => ({
            ...provided,
            color: ENUMS.THEME.BORDER_COLOR,
          }),
          menuList: provided => ({
            ...provided,
            maxHeight: '250px',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: ENUMS.THEME.BORDER_COLOR,
              outline: '1px solid ' + ENUMS.THEME.BORDER_COLOR,
            },
          }),
        }}
        inputId={!_.isEmpty(id) ? id : null}
        isDisabled={disabled}
        menuPlacement='auto'
        minMenuHeight={300}
      />
      <FormHelperText error={error} sx={{ marginLeft: '14px' }}>
        {helperText}
      </FormHelperText>
    </>
  )
}

export const MinimalSingleSelect = ({ value, placeholder, options, error, helperText, style, onInputChange = () => {}, onInputFocus = () => {}, info = null, id = null, disabled = false }) => {
  const DropdownIndicator = props => {
    if (!isEmpty(info)) {
      return (
        <Box style={{ color: 'rgba(0, 0, 0, 0.54)', marginLeft: '5px' }} display='flex' alignItems='center'>
          <Tooltip title={<span style={{ fontSize: '14px' }}>{info}</span>} placement='top'>
            <Info />
          </Tooltip>
          <components.IndicatorsContainer {...props}>
            <KeyboardArrowDown sx={{ color: ENUMS.THEME.BORDER_COLOR }} />
          </components.IndicatorsContainer>
        </Box>
      )
    } else {
      return (
        <>
          <components.DropdownIndicator {...props}>
            <KeyboardArrowDown sx={{ color: ENUMS.THEME.BORDER_COLOR }} />
          </components.DropdownIndicator>
        </>
      )
    }
  }
  return (
    <>
      <ReactSelect
        placeholder={<div className='select-placeholder-text'>{placeholder}</div>}
        value={value}
        options={options}
        onChange={onInputChange}
        onFocus={onInputFocus}
        styles={{
          control: (provided, state) => ({
            ...provided,
            boxShadow: 'none',
            padding: '5px 14px',
            fontSize: '1rem',
            fontWeight: 400,
            letterSpacing: '0.00938em',
            marginTop: '5px',
            borderColor: ENUMS.THEME.BORDER_COLOR,
            '&:hover': {
              borderColor: ENUMS.THEME.BORDER_COLOR,
            },
          }),
          valueContainer: provided => ({
            ...provided,
            padding: '9px 0',
          }),
          placeholder: provided => ({
            ...provided,
            color: '#a2a2a2',
          }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? ENUMS.THEME.MAIN_COLOR : 'white',
            '&:active': {
              backgroundColor: ENUMS.THEME.MAIN_COLOR,
            },
            '&:hover': state.isSelected
              ? { backgroundColor: ENUMS.THEME.MAIN_COLOR }
              : {
                  backgroundColor: ENUMS.THEME.MAIN_COLOR,
                  color: ENUMS.THEME.WHITE_COLOR,
                },
          }),
          menuList: provided => ({
            ...provided,
            maxHeight: '150px',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: ENUMS.THEME.BORDER_COLOR,
              outline: '1px solid ' + ENUMS.THEME.BORDER_COLOR,
            },
          }),
          singleValue: provided => ({
            ...provided,
            color: ENUMS.THEME.BLACK_COLOR,
          }),
        }}
        components={{ DropdownIndicator }}
        inputId={!_.isEmpty(id) ? id : null}
        isDisabled={disabled}
        menuPlacement='auto'
        minMenuHeight={300}
      />
      <FormHelperText error={error} sx={{ marginLeft: '14px' }}>
        {helperText}
      </FormHelperText>
    </>
  )
}

export const MinimalDateTimePicker = ({ value, placeholder = '', error, helperText, minDateTime, maxDateTime, onInputChange = () => {}, onInputFocus = () => {}, onEditLoad = false, info = null, id = null, disabled = false }) => {
  let dynamicClass = ''
  if (onEditLoad) {
    dynamicClass = ' oneditload-datetime-picker'
  }
  const [open, setOpen] = React.useState(false)
  const getInputAdornment = () => {
    return (
      <Box display='flex' alignItems='center' padding={0}>
        {!isEmpty(info) && disabled ? (
          <IconButton sx={{ padding: '2px' }} disabled={disabled}>
            <Info />
          </IconButton>
        ) : (
          <Tooltip title={<span style={{ fontSize: '14px' }}>{info}</span>} placement='top'>
            <IconButton sx={{ padding: '2px' }}>
              <Info />
            </IconButton>
          </Tooltip>
        )}
        <IconButton onClick={() => setOpen(true)} sx={{ padding: 0 }} disabled={disabled}>
          <Event />
        </IconButton>
      </Box>
    )
  }
  return (
    <>
      <div id={!_.isEmpty(id) ? id : null} className='datetime-pickers'>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              label={placeholder}
              value={value}
              onChange={onInputChange}
              slotProps={{ textField: { placeholder: placeholder } }}
              className={'common-datetime-input' + dynamicClass}
              onOpen={onInputFocus}
              minDate={minDateTime}
              maxDateTime={maxDateTime}
              onClose={() => setOpen(false)}
              open={open}
              slots={{ inputAdornment: getInputAdornment }}
              disabled={disabled}
              sx={{ fontFamily: ENUMS.THEME.FONT_FAMILY }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
      <FormHelperText error={error} sx={{ marginLeft: '14px' }}>
        {helperText}
      </FormHelperText>
    </>
  )
}
export const MinimalDatePicker = ({ value, placeholder, label = '', error, helperText, minDate, maxDate, onInputChange = () => {}, onInputFocus = () => {}, onEditLoad = false, id = null, disabled = false }) => {
  let dynamicClass = ''
  if (onEditLoad) {
    dynamicClass = ' oneditload-datetime-picker'
  }
  return (
    <>
      <div id={!_.isEmpty(id) ? id : null} className='datetime-pickers'>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker label={label} value={value} onChange={onInputChange} slotProps={{ textField: { placeholder: placeholder } }} className={'common-datetime-input full-width' + dynamicClass} onOpen={onInputFocus} minDate={minDate} maxDate={maxDate} disabled={disabled} />
          </DemoContainer>
        </LocalizationProvider>
      </div>
      <FormHelperText error={error} sx={{ marginLeft: '14px' }}>
        {helperText}
      </FormHelperText>
    </>
  )
}
export const MinimalTimePicker = ({ value, placeholder, label = '', error, helperText, ampm = true, onInputChange = () => {}, onInputFocus = () => {}, maxTime, id = null, disabled = false }) => {
  return (
    <>
      <div id={!_.isEmpty(id) ? id : null} className='datetime-pickers'>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DemoContainer components={['TimePicker']}>
            <TimePicker label={label} value={value} onChange={onInputChange} ampm={ampm} slotProps={{ textField: { placeholder: placeholder } }} className='common-datetime-input full-width' onOpen={onInputFocus} maxTime={maxTime} disabled={disabled} />
          </DemoContainer>
        </LocalizationProvider>
      </div>
      <FormHelperText error={error} sx={{ marginLeft: '14px' }}>
        {helperText}
      </FormHelperText>
    </>
  )
}
export const MinimalYearPicker = ({ value, placeholder, onInputChange = () => {} }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={['TimePicker']}>
        <DatePicker views={['year']} label='' value={value} onChange={onInputChange} slotProps={{ textField: { placeholder: placeholder } }} className='common-datetime-input full-width' />
      </DemoContainer>
    </LocalizationProvider>
  )
}
