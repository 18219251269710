import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import img_url from '../../assets/images/not-found.png'
import { useEffect, useState } from 'react'
import { rejectInvite, userInviteValidity } from '../../services/auth-services'
import ENUMS from '../../constants/enum'
import { Toast } from '../common/toast-utils'

export default function DeclineInvite() {
  const navigate = useNavigate()
  const urlParams = useParams()
  const [success, setSuccess] = useState(null)
  const [errorMsg, setErrorMsg] = useState('')
  useEffect(() => {
    async function userInviteValidityOnDecline() {
      try {
        if (urlParams !== null && urlParams.tokenparam !== null) {
          const res = await userInviteValidity(urlParams.tokenparam, 'decline')
          if (res.success && res.data && res.data.detail === 'ok') {
            try {
              const res = await rejectInvite(urlParams.tokenparam)
              if (res.success) {
                setSuccess(true)
              } else {
                setSuccess(false)
                setErrorMsg(res.error_message)
              }
            } catch (error) {
              console.log(error)
              Toast.error(error)
              setErrorMsg(error)
              setSuccess(false)
            }
          } else if (res.success && res.data && res.data.login) {
            //do nothing
          } else {
            setErrorMsg(res.error_message)
          }
        }
      } catch (error) {
        console.log(error)
        Toast.error(error)
      }
    }

    userInviteValidityOnDecline()
  }, [urlParams])
  return (
    <>
      <AppBar position='static' sx={{ backgroundColor: ENUMS.THEME.MAIN_COLOR, boxShadow: 'none' }}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <img src={logo} alt='Skandika' className='appbar-logo' onClick={() => window.location.reload()}/>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth='xl' sx={{ padding: 2 }}>
        {(success || errorMsg !== '') && (
          <>
            <Box className='no-border no-record-box'>
              <img src={img_url} alt='Not found' style={{ width: '150px' }} />
              <Typography component='p'>{errorMsg !== '' ? errorMsg : 'You have successfully decline the member request.'}</Typography>
            </Box>
          </>
        )}
      </Container>
    </>
  )
}
