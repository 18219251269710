import { useContext, useState } from 'react'
import * as yup from 'yup'
import { Card, CardContent, CardMedia, Container, Grid, Link, CssBaseline, InputAdornment, IconButton } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { validateSchema } from '../../helpers/schema-validation'
import { login } from '../../services/auth-services'
import { useNavigate } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import ENUMS from '../../constants/enum'
import logo from '../../assets/images/logo.png'
import { FloatingButton, MinimalTextField } from '../common/form-control'
import getUserRole from '../../helpers/getuser-role'
import _ from 'lodash'
import { Toast } from '../common/toast-utils'
import { toast } from 'react-toastify'

export default function SignIn() {
  const checkUserRole = new getUserRole()
  localStorage.clear()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState({
    email: { error: false, msg: '' },
    password: { error: false, msg: '' },
  })
  const [showPassword, setShowPassword] = useState(false)
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)

  const checkEmailAddressValid = async payload => {
    const schema = yup.object().shape({
      email: yup.string().email(ENUMS.COMMON_MSG.INVALID_EMAIL),
    })
    const isValid = await validateSchema(payload, schema)
    return isValid
  }
  const handleEmailChange = async value => {
    if (!_.isEmpty(value)) value = value.trim()
    setFormValues({ ...formValues, email: value })
    const payload = { email: value }
    const isValid = await checkEmailAddressValid(payload)
    setErrors(isValid)
    if (formValues.password !== '' && value !== '' && isValid === true) {
      setEnableSubmitBtn(true)
    } else {
      setEnableSubmitBtn(false)
    }
  }

  const handlePasswordChange = async value => {
    setFormValues({ ...formValues, password: value })
    const payload = { email: formValues.email }
    const isValid = await checkEmailAddressValid(payload)
    if (formValues.email !== '' && value !== '' && isValid === true) {
      setEnableSubmitBtn(true)
    } else {
      setEnableSubmitBtn(false)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    let newFormValues = { ...formValues }
    if (newFormValues.email !== '' && newFormValues.password !== '') {
      const schema = yup.object().shape({
        email: yup.string().email(ENUMS.COMMON_MSG.INVALID_EMAIL),
      })
      const payload = {
        email: newFormValues.email,
        password: newFormValues.password,
      }
      const isValid = await validateSchema(payload, schema)
      setErrors(isValid)
      if (isValid === true) {
        setFormValues(newFormValues)
        setLoading(true)
        checkAuthentication(newFormValues)
      }
    }
  }

  const checkAuthentication = async loginrequest => {
    try {
      toast.dismiss()
      const res = await login(loginrequest)
      if (res.success) {
        let userData = res.data.user_data
        userData.is_super_user = res.data.user_data.staff_user ? false : true
        localStorage.setItem('UserDetail', JSON.stringify(userData))
        localStorage.setItem('AccessToken', res.data.key)
        if (userData && userData.staff_user && !_.isEmpty(userData.staff_user.role)) {
          localStorage.setItem('roleId', userData.staff_user.role.id)
          localStorage.setItem('role', userData.staff_user.role.name)
          if (checkUserRole.isAdmin()) {
            navigate('/users', { replace: true })
          } else {
            navigate('/announcements', { replace: true })
          }
        }
      } else {
        Toast.error(res.error_message)
      }
    } catch (error) {
      Toast.error(error)
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <div className='auth-section'>
      <Container component='main' maxWidth='sm'>
        <CssBaseline />
        <Card component='form' onSubmit={handleSubmit} noValidate className='auth-cardview'>
          <CardMedia className='auth-cardheader'>
            <img src={logo} alt='Skandika' onClick={() => window.location.reload()} />
          </CardMedia>
          <CardContent sx={{ width: '100%', padding: 4 }}>
            <MinimalTextField
              placeholder='Email'
              error={errors.email && errors.email.error}
              helperText={errors.email && errors.email.msg}
              value={formValues.email}
              onInput={e => handleEmailChange(e.target.value)}
              onFocus={() => {
                setErrors({ ...errors, email: { error: false, msg: '' } })
              }}
              isRequired={true}
            />
            <MinimalTextField
              placeholder='Password'
              error={errors.password && errors.password.error}
              helperText={errors.password && errors.password.msg}
              value={formValues.password}
              onChange={e => handlePasswordChange(e.target.value)}
              onFocus={() => {
                setErrors({ ...errors, password: { error: false, msg: '' } })
              }}
              isRequired={true}
              type={showPassword ? 'text' : 'password'}
              endAdornmentProp={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} sx={{ color: '#2D38384D' }}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FloatingButton type='submit' baseClassName='common-btn' disabled={!enableSubmitBtn || loading} text='Sign In' onClick={() => {}} loading={loading} sx={{ mt: 3, mb: 2 }} />
            <Grid container>
              <Grid item xs>
                <Link variant='body2' underline='none' className='common-link' component={RouterLink} to='/forgotpassword'>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
