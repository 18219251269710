import React from "react";

const FileUpload = () => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9968_24)">
      <path
        d="M31.3068 11.2748H37.3783L28.0854 1.49902V8.04084C28.0854 9.81288 29.5226 11.2748 31.3068 11.2748Z"
        fill="#2D3838"
      />
      <path
        d="M21.2338 35.9313H10.4665C9.92141 35.9313 9.47535 35.4857 9.47535 34.9402C9.47535 34.3951 9.92141 33.949 10.4665 33.949H21.8905C22.5221 32.3386 23.3646 30.9752 24.4057 29.6123H10.4665C9.92141 29.6123 9.47535 29.1667 9.47535 28.6212C9.47535 28.0761 9.92141 27.63 10.4665 27.63H26.2889C29.2623 25.028 33.2025 23.368 37.5019 23.368C37.9106 23.368 38.2455 23.4049 38.7411 23.4423V13.2574H31.3067C28.4323 13.2574 26.1031 10.9033 26.1031 8.0411V0H7.09655C4.64306 0 2.68555 2.00702 2.68555 4.47269V42.2877C2.68555 44.7533 4.64306 46.7108 7.09655 46.7108H22.0265C21.1717 44.7286 20.7009 42.4983 20.7009 40.169C20.6883 38.7071 20.8868 37.2942 21.2338 35.9313ZM10.4665 21.4348H23.018C23.5631 21.4348 24.0092 21.8809 24.0092 22.4264C24.0092 22.9715 23.5631 23.4175 23.018 23.4175H10.4665C9.92141 23.4175 9.47535 22.9715 9.47535 22.4264C9.47535 21.8809 9.92141 21.4348 10.4665 21.4348Z"
        fill="#2D3838"
      />
      <path
        d="M37.4893 25.3877C29.3244 25.3877 22.6831 32.0285 22.6831 40.1938C22.6831 48.3587 29.3244 55 37.4893 55C45.6546 55 52.2958 48.3587 52.2958 40.1938C52.2958 32.0285 45.6546 25.3877 37.4893 25.3877ZM45.407 40.4167C45.2211 40.5904 44.9731 40.6772 44.7377 40.6772C44.4775 40.6772 44.2174 40.5656 44.0189 40.3672L38.493 34.4199V48.5694C38.493 49.1149 38.0469 49.5609 37.5018 49.5609C36.9568 49.5609 36.5107 49.1149 36.5107 48.5694V34.4078L30.96 40.355C30.5883 40.7515 29.9437 40.7763 29.5472 40.4045C29.1511 40.0327 29.1259 39.4008 29.4977 39.0042L36.7583 31.2107C36.9442 31.0126 37.2043 30.901 37.4893 30.901C37.7746 30.901 38.0222 31.0126 38.2206 31.2107L45.4813 39.0042C45.8283 39.4134 45.8031 40.0453 45.407 40.4167Z"
        fill="#2D3838"
      />
    </g>
    <defs>
      <clipPath id="clip0_9968_24">
        <rect width="55" height="55" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FileUpload;
