import post, { deleteAction, get } from './base-service'
import API_HELPER from '../constants/api-urls'
import _ from 'lodash'

export const staffUserListData = async requestData => {
  try {
    let url = `${API_HELPER.URL.staffUserList}?page_size=${requestData.page_size}&page=${requestData.page_index}&search=${requestData.search_string}`
    if (!_.isEmpty(requestData.sorting)) {
      url += '&ordering=' + requestData.sorting
    }
    const response = await get(url, null)
    return response
  } catch (error) {
    console.log('staffUserListData service catch-----------------', error)
    return error
  }
}

export const sendInvite = async requestData => {
  try {
    const response = await post(`${API_HELPER.URL.sendInvite}`, requestData)
    return response
  } catch (error) {
    console.log('sendInvite service catch-----------------', error)
    return error
  }
}

export const userRoleListData = async () => {
  try {
    const response = await get(`${API_HELPER.URL.userRoleList}`, null)
    return response
  } catch (error) {
    console.log('userRoleListData service catch-----------------', error)
    return error
  }
}

export const deleteUser = async id => {
  try {
    const response = await deleteAction(`${API_HELPER.URL.deleteUser}${id}/delete/`)
    return response
  } catch (error) {
    console.log('deleteUser service catch-----------------', error)
    return error
  }
}
