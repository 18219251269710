import { useNavigate } from "react-router-dom";
import Layout from "../layout/layout";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  Box,
  Tooltip,
  IconButton,
  TableSortLabel,
  Popover,
  List,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  ListItemButton,
  Drawer,
} from "@mui/material";
import NoAnnouncementFoundImage from "../../assets/images/no-birthday.png";
import {
  DialogPrompt,
  NoRecordFoundBox,
  StatusComponent,
} from "../common/other-component";
import SearchComponent from "../common/search";
import { useContext, useEffect, useState } from "react";
import {
  birthdayListData,
  birthdayAction,
  deleteBirthday,
} from "../../services/birthday-services.js";
import _ from "lodash";
import TableLoader from "../common/table-loader";
import getUserRole from "../../helpers/getuser-role";
import ENUMS from "../../constants/enum";
import {
  CheckCircleOutlined,
  Close,
  CloseOutlined,
  Delete,
  Edit,
  MoreVert,
  UnpublishedOutlined,
  Visibility,
} from "@mui/icons-material";
import { FloatingButton, MinimalSingleSelect } from "../common/form-control";
import { SkandikaContextData } from "../../context";
import { Toast } from "../common/toast-utils";

const defaultFilterDetail = {
  target_audience: null,
  status: null,
};
function Birthday() {
  const checkUserRole = new getUserRole();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [pagesize, setPageSize] = useState(ENUMS.DEFAULT_PAGESIZE);
  const [size, setSize] = useState(0);
  const [page, setPage] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [reRender, setRender] = useState(0);
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("desc");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [isDelteItem, setDeleteItem] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filterDetail, setFilterDetail] = useState(defaultFilterDetail);
  const { refetchPendingCountData, handleBirthdayExist, contextData } =
    useContext(SkandikaContextData);
  const addBirthday = () => {
    navigate("/birthday/create");
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const sorting = order === "desc" ? "-" + orderBy : orderBy;
        let request = {
          page_index: pageindex,
          page_size: pagesize,
          search_string: searchString,
          sorting: sorting,
        };
        if (!_.isEmpty(filterDetail.target_audience)) {
          request.target_audience = filterDetail.target_audience.value;
        }
        if (!_.isEmpty(filterDetail.status)) {
          request.status = filterDetail.status.value;
        }
        const res = await birthdayListData(request);
        if (res.success) {
          const list_data = res.data.results
            ? res.data.results.map((e) => ({
                ...e,
                announcement_id: "Birthday " + e.id,
              }))
            : [];
          setSize(res.data.count);
          setRows(list_data);
          if (list_data.length > 0) {
            handleBirthdayExist(true);
          } else if (
            contextData &&
            contextData.isBirthdayExist &&
            !list_data.length
          ) {
            handleBirthdayExist(false);
          }
        } else {
          setRows([]);
          Toast.error(res.error_message);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        Toast.error(error);
        setRows([]);
        setLoading(false);
      }
    })();
  }, [reRender, pageindex, pagesize, searchString, order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageIndex(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageIndex(1);
    setPageSize(parseInt(event.target.value, 10));
  };

  const getStatus = (row) => {
    if (_.isEmpty(row.status)) {
      return "";
    }
    const item = [...ENUMS.ANNOUNCEMENT_STATUS].find(
      (d) => d.label.toLowerCase() === row.status.toLowerCase()
    );
    if (item) {
      return (
        <StatusComponent color={item.color} label={item.label} size="small" />
      );
    } else {
      return "";
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAction = async (action) => {
    if (action === "PUBLISH")
      await updateStatus(ENUMS.ACTION_STATUS_ENUM.Published, selectedRow.id);
    else if (action === "REJECT")
      await updateStatus(ENUMS.ACTION_STATUS_ENUM.Rejected, selectedRow.id);
    else if (action === "UNPUBLISH")
      await updateStatus(ENUMS.ACTION_STATUS_ENUM.Unpublish, selectedRow.id);
    else if (action === "DELETE") setDeleteItem(true);
    setAnchorEl(null);
  };

  const updateStatus = async (action_status, id) => {
    setActionLoading(true);
    try {
      let payload = {
        status: action_status,
      };
      const res = await birthdayAction(id, payload);
      if (res.success) {
        Toast.success(res.data.detail);
        setRender((p) => p + 1);
        await refetchPendingCountData();
      } else {
        Toast.error(res.error_message);
      }
    } catch (error) {
      Toast.error(error);
      console.log(error);
    }
    setActionLoading(false);
  };

  const handleBirthdayItem = (item) => {
    if (
      checkUserRole.isAdmin() &&
      !_.isEmpty(item.status) &&
      (item.status.toLowerCase() ===
        ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.ANNOUNCEMENT_STATUS_ENUM.Pending.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.ANNOUNCEMENT_STATUS_ENUM.PendingForApproval.toLowerCase())
    ) {
      navigate("/birthday/edit/" + item.id);
    } else if (
      checkUserRole.isStaff() &&
      item.editable &&
      !_.isEmpty(item.status) &&
      (item.status.toLowerCase() ===
        ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase())
    ) {
      navigate("/birthday/edit/" + item.id);
    } else {
      navigate("/birthday/view/" + item.id);
    }
  };

  const confirmDelete = async () => {
    setActionLoading(true);
    try {
      const res = await deleteBirthday(selectedRow.id);
      if (res.success) {
        setDeleteItem(false);
        Toast.success(res.data.detail);
        setRender((p) => p + 1);
        await refetchPendingCountData();
      } else {
        Toast.error(res.error_message);
      }
    } catch (error) {
      Toast.error(error);
      console.log(error);
    }
    setActionLoading(false);
  };

  const handleInputChange = async (name, value) => {
    setFilterDetail({ ...filterDetail, [name]: value });
  };

  const handleApplyFilter = async () => {
    setIsOpen(false);
    try {
      setPage(0);
      setPageIndex(1);
      setPageSize(ENUMS.DEFAULT_PAGESIZE);
      setOrderBy("id");
      const sorting = order === "desc" ? "-" + orderBy : orderBy;
      let request = {
        page_index: 1,
        page_size: ENUMS.DEFAULT_PAGESIZE,
        search_string: searchString,
        sorting: sorting,
      };
      if (!_.isEmpty(filterDetail.target_audience)) {
        request.target_audience = filterDetail.target_audience.value;
      }
      if (!_.isEmpty(filterDetail.status)) {
        request.status = filterDetail.status.value;
      }
      const res = await birthdayListData(request);
      if (res.success) {
        const list_data = res.data.results
          ? res.data.results.map((e) => ({
              ...e,
              announcement_id: "Birthday " + e.id,
            }))
          : [];
        setSize(res.data.count);
        setRows(list_data);
        if (list_data.length > 0) {
          handleBirthdayExist(true);
        } else if (
          contextData &&
          contextData.isBirthdayExist &&
          !list_data.length
        ) {
          handleBirthdayExist(false);
        }
      } else {
        setRows([]);
        Toast.error(res.error_message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      Toast.error(error);
      setRows([]);
      setLoading(false);
    }
  };

  const handleClearFilter = () => {
    setIsOpen(false);
    setRender((p) => p + 1);
    setFilterDetail(defaultFilterDetail);
  };

  const labelDisplayedRows = ({ count }) => {
    // Calculate the total number of pages based on the total number of rows and rows per page
    const totalPages = Math.ceil(count / pagesize);
    return `Page ${pageindex} of ${totalPages}`;
  };

  return (
    <Layout>
      <Box component="div" mb={2}>
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid item>
            <Box display="flex" justifyContent="flex-start">
              <FloatingButton
                text="Filter"
                baseClassName="common-btn"
                onClick={() => setIsOpen(true)}
                sx={{ marginRight: "5px" }}
              />
              <FloatingButton
                text="Add Birthday"
                baseClassName="full-width common-btn"
                onClick={() => addBirthday()}
              />
            </Box>
          </Grid>
          <Grid item>
            <SearchComponent
              searchString={searchString}
              setSearchString={setSearchString}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="dashboardtblScroll theme-scroll-bar" id="style-1">
        {_.isEmpty(rows) && !loading ? (
          <NoRecordFoundBox img_url={NoAnnouncementFoundImage} />
        ) : (
          <Table size="small" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "id"}
                    direction={orderBy === "id" ? order : "asc"}
                    onClick={() => handleRequestSort("id")}
                  >
                    Birthday Id
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "65%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  Birthday Name
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "15%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "posted_by__user__first_name"}
                    direction={
                      orderBy === "posted_by__user__first_name" ? order : "asc"
                    }
                    onClick={() =>
                      handleRequestSort("posted_by__user__first_name")
                    }
                  >
                    Created By
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "5%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={orderBy === "status" ? order : "asc"}
                    onClick={() => handleRequestSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "5%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableLoader cols={5} />
            ) : (
              <TableBody>
                {rows.map((tableRow, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.announcement_id}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.title}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.posted_by}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {getStatus(tableRow)}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {checkUserRole.isStaff() && (
                          <>
                            {tableRow.editable &&
                            !_.isEmpty(tableRow.status) &&
                            (tableRow.status.toLowerCase() ===
                              ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
                              tableRow.status.toLowerCase() ===
                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase()) ? (
                              <Tooltip
                                title="Edit"
                                placement="top"
                                sx={{ zIndex: 1, pointerEvents: "auto" }}
                                PopperProps={{ disablePortal: true }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleBirthdayItem(tableRow)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="View"
                                placement="top"
                                PopperProps={{ disablePortal: true }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleBirthdayItem(tableRow)}
                                >
                                  <Visibility />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                        {checkUserRole.isAdmin() && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {!_.isEmpty(selectedRow) &&
                            tableRow.id === selectedRow.id &&
                            actionLoading ? (
                              <CircularProgress size={20} thickness={5} />
                            ) : (
                              <>
                                {!_.isEmpty(tableRow.status) &&
                                (tableRow.status.toLowerCase() ===
                                  ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
                                  tableRow.status.toLowerCase() ===
                                    ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase() ||
                                  tableRow.status.toLowerCase() ===
                                    ENUMS.ANNOUNCEMENT_STATUS_ENUM.Pending.toLowerCase() ||
                                  tableRow.status.toLowerCase() ===
                                    ENUMS.ANNOUNCEMENT_STATUS_ENUM.PendingForApproval.toLowerCase()) ? (
                                  <Tooltip
                                    title="Edit"
                                    placement="top"
                                    PopperProps={{ disablePortal: true }}
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleBirthdayItem(tableRow)
                                      }
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="View"
                                    placement="top"
                                    PopperProps={{ disablePortal: true }}
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleBirthdayItem(tableRow)
                                      }
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {!_.isEmpty(tableRow.status) &&
                                  (tableRow.status.toLowerCase() ===
                                    ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.Pending.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.PendingForApproval.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.Published.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.Active.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.ActiveSoon.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.Expired.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.Rejected.toLowerCase()) && (
                                    <>
                                      <Tooltip
                                        title="More Actions"
                                        placement="top"
                                        PopperProps={{ disablePortal: true }}
                                      >
                                        <IconButton
                                          onClick={(event) =>
                                            handleMenuClick(event, tableRow)
                                          }
                                        >
                                          <MoreVert />
                                        </IconButton>
                                      </Tooltip>
                                      <Popover
                                        open={
                                          selectedRow === tableRow &&
                                          Boolean(anchorEl)
                                        }
                                        anchorEl={anchorEl}
                                        onClose={handleMenuClose}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                      >
                                        <List
                                          sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          {!_.isEmpty(tableRow.status) &&
                                            (tableRow.status.toLowerCase() ===
                                              ENUMS.ANNOUNCEMENT_STATUS_ENUM.Pending.toLowerCase() ||
                                              tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.PendingForApproval.toLowerCase() ||
                                              (!_.isEmpty(tableRow.role) &&
                                                tableRow.role.name ===
                                                  ENUMS.USER_ROLE.ADMIN &&
                                                (tableRow.status.toLowerCase() ===
                                                  ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
                                                  tableRow.status.toLowerCase() ===
                                                    ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase()))) && (
                                              <>
                                                <ListItemButton
                                                  style={
                                                    ENUMS.COMMON_STYLE.tableStyle()
                                                      .popOverListItemStyle
                                                  }
                                                  onClick={() =>
                                                    handleAction("PUBLISH")
                                                  }
                                                  disabled={actionLoading}
                                                >
                                                  <ListItemIcon
                                                    sx={
                                                      ENUMS.COMMON_STYLE.tableStyle()
                                                        .popOverListItemIconStyle
                                                    }
                                                    style={{ color: "#4BAE4F" }}
                                                  >
                                                    <CheckCircleOutlined />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Publish" />
                                                </ListItemButton>
                                                <ListItemButton
                                                  style={
                                                    ENUMS.COMMON_STYLE.tableStyle()
                                                      .popOverListItemStyle
                                                  }
                                                  onClick={() =>
                                                    handleAction("REJECT")
                                                  }
                                                  disabled={actionLoading}
                                                >
                                                  <ListItemIcon
                                                    sx={
                                                      ENUMS.COMMON_STYLE.tableStyle()
                                                        .popOverListItemIconStyle
                                                    }
                                                    style={{ color: "#EE404C" }}
                                                  >
                                                    <Close />
                                                  </ListItemIcon>
                                                  <ListItemText primary="Reject" />
                                                </ListItemButton>
                                              </>
                                            )}
                                          {!_.isEmpty(tableRow.status) &&
                                            (tableRow.status.toLowerCase() ===
                                              ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
                                              tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase() ||
                                              tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.Pending.toLowerCase() ||
                                              tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.PendingForApproval.toLowerCase() ||
                                              tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.Expired.toLowerCase() ||
                                              tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.Rejected.toLowerCase()) && (
                                              <ListItemButton
                                                style={
                                                  ENUMS.COMMON_STYLE.tableStyle()
                                                    .popOverListItemStyle
                                                }
                                                onClick={() =>
                                                  handleAction("DELETE")
                                                }
                                                disabled={actionLoading}
                                              >
                                                <ListItemIcon
                                                  sx={
                                                    ENUMS.COMMON_STYLE.tableStyle()
                                                      .popOverListItemIconStyle
                                                  }
                                                  style={{ color: "#FC0005" }}
                                                >
                                                  <Delete />
                                                </ListItemIcon>
                                                <ListItemText primary="Delete" />
                                              </ListItemButton>
                                            )}
                                          {!_.isEmpty(tableRow.status) &&
                                            (tableRow.status.toLowerCase() ===
                                              ENUMS.ANNOUNCEMENT_STATUS_ENUM.Published.toLowerCase() ||
                                              tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.Active.toLowerCase() ||
                                              tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.ActiveSoon.toLowerCase()) && (
                                              <ListItemButton
                                                style={
                                                  ENUMS.COMMON_STYLE.tableStyle()
                                                    .popOverListItemStyle
                                                }
                                                onClick={() =>
                                                  handleAction("UNPUBLISH")
                                                }
                                                disabled={actionLoading}
                                              >
                                                <ListItemIcon
                                                  sx={
                                                    ENUMS.COMMON_STYLE.tableStyle()
                                                      .popOverListItemIconStyle
                                                  }
                                                  style={{ color: "#FC0005" }}
                                                >
                                                  <UnpublishedOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Unpublish" />
                                              </ListItemButton>
                                            )}
                                        </List>
                                      </Popover>
                                    </>
                                  )}
                              </>
                            )}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        )}
      </div>
      {!_.isEmpty(rows) && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={size}
          rowsPerPage={pagesize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={ENUMS.ROWS_PER_PAGETEXT}
        />
      )}
      <DialogPrompt
        open={isDelteItem}
        onCancel={() => setDeleteItem(false)}
        onConfirm={() => confirmDelete()}
        title="Are you sure you want to delete this birthday?"
        loading={actionLoading}
      />
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="sidebar-drawer"
      >
        <div className="sidebar-drawer-header" style={{ width: "100%" }}>
          <div>Filter </div>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setIsOpen(false)}
          >
            <CloseOutlined style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div
          className="table-responsive"
          id="style-1"
          style={{
            maxHeight: "calc(100vh - 65px)",
            height: "calc(100vh - 65px)",
            width: "450px",
          }}
        >
          <div style={{ padding: "10px" }}>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              <MinimalSingleSelect
                placeholder="Select Target Audience"
                value={filterDetail.target_audience}
                options={[
                  { label: "Select One", value: "" },
                  ...ENUMS.TARGET_AUDIENCE_LIST,
                ]}
                onInputChange={(e) => handleInputChange("target_audience", e)}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              <MinimalSingleSelect
                placeholder="Select Status"
                value={filterDetail.status}
                options={[
                  { label: "Select One", value: "" },
                  ...ENUMS.BIRTHDAY_STATUS_LIST,
                ]}
                onInputChange={(e) => handleInputChange("status", e)}
              />
            </div>
          </div>
        </div>
        <div className="sidebar-drawer-footer">
          <FloatingButton
            baseClassName="common-btn common-btn-gray"
            text="Clear All"
            onClick={() => handleClearFilter()}
            sx={{ marginRight: "5px" }}
          />
          <FloatingButton
            baseClassName="common-btn"
            text="Filter"
            onClick={() => handleApplyFilter()}
          />
        </div>
      </Drawer>
    </Layout>
  );
}

export default Birthday;
