import ENUMS from '../constants/enum'

export default class getUserRole {
  constructor() {
    this.role = localStorage.key('role') ? localStorage.getItem('role') : ''    
  }

  isStaff = () => this.role === ENUMS.USER_ROLE.STAFF
  isAdmin = () => this.role === ENUMS.USER_ROLE.ADMIN
  currentSelectedRole = role => {        
    const __role = this.role === role
    if (!__role) role = ''
    return {
      isStaff: () => role === ENUMS.USER_ROLE.STAFF,
      isAdmin: () => role === ENUMS.USER_ROLE.ADMIN,
    }
  }
}
