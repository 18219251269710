import { useState, useEffect } from 'react'
import { Card, CardContent, CardMedia, Container, Grid, Link, CssBaseline, Typography } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useLocation, useNavigate } from 'react-router-dom'
import { sendCode, verifyCodeAndEmail } from '../../services/auth-services'
import logo from '../../assets/images/logo.png'
import { FloatingButton } from '../common/form-control'
import { Toast } from '../common/toast-utils'

export default function VerifyCode() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [timerSeconds, setTimerSeconds] = useState(state && state.otp_expiration_time ? state.otp_expiration_time : 10)
  const [otpCode, setOtpCode] = useState('')
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(false)
  const [isResendCodeProcessing, setIsResendCodeProcessing] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if (timerSeconds > 0) {
        let seconds = timerSeconds - 1 < 10 ? ('0' + (timerSeconds - 1)).slice(-2) : timerSeconds - 1
        setTimerSeconds(seconds)
      }
      if (timerSeconds === 0 || timerSeconds === '00') {
        setEnableSubmitBtn(false)
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  const resendOtpLinkClick = async () => {
    if (state && state.email) {
      let request = {
        email: state.email,
      }
      setEnableSubmitBtn(false)
      setIsResendCodeProcessing(true)
      try {
        const res = await sendCode(request)
        if (res.success) {
          Toast.success(res.data.detail)
          setTimerSeconds(res.data.otp_expiration_time_in_seconds)
          setOtpCode('')
        } else {
          Toast.error(res.error_message)
        }
      } catch (error) {
        Toast.error(error)
        console.log(error)
      }
      setIsResendCodeProcessing(false)
    }
  }
  const handleOtpCodeChange = newValue => {
    setOtpCode(newValue)
    setEnableSubmitBtn(false)
  }

  const handleOtpCodeComplete = async finalValue => {
    if (state && state.email && timerSeconds > 0) {
      let request = {
        email: state.email,
        key: finalValue,
      }
      await verifyEmailWithCode(request)
    }
  }

  const handleVerifyBtnClick = async e => {
    e.preventDefault()
    if (state && state.email && timerSeconds > 0) {
      let request = {
        email: state.email,
        key: otpCode,
      }
      await verifyEmailWithCode(request)
    }
  }

  const verifyEmailWithCode = async request => {
    setEnableSubmitBtn(true)
    setLoading(true)
    try {
      const res = await verifyCodeAndEmail(request)
      if (res.success) {
        navigate(`/setpassword/${res.data.token}`, { state: { resetpassword_token: res.data.token } })
      } else {
        Toast.error(res.error_message)
      }
    } catch (error) {
      Toast.error(error)
      console.log(error)
    }
    setLoading(false)
  }

  const minutes = Math.floor(timerSeconds / 60)
  const seconds = timerSeconds % 60

  return (
    <div className='auth-section'>
      <Container component='main' maxWidth='sm'>
        <CssBaseline />
        <Card component='form' onSubmit={handleVerifyBtnClick} noValidate className='auth-cardview'>
          <CardMedia className='auth-cardheader'>
            <img src={logo} alt='Skandika' onClick={() => window.location.reload()} />
          </CardMedia>
          <CardContent sx={{ width: '100%', padding: 4 }}>
            <MuiOtpInput length={6} autoFocus onComplete={handleOtpCodeComplete} value={otpCode} onChange={handleOtpCodeChange} />
            <Typography variant='h3' component='h3' sx={{ mt: 2, color: '#9c9c9c' }}>
              {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
            </Typography>
            <Grid container>
              <Grid item xs>
                {state && state.email && timerSeconds === '00' ? (
                  <div>
                    Didn’t receive a code?
                    <Link variant='body2' underline='none' className='common-link' onClick={() => resendOtpLinkClick()} sx={{ cursor: 'pointer' }} disabled={isResendCodeProcessing}>
                      {' '}
                      Resend Code
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <FloatingButton type='submit' baseClassName='common-btn' disabled={!enableSubmitBtn || loading} text='Verify' onClick={() => {}} loading={loading} sx={{ mt: 3, mb: 2 }} />
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
