import Layout from "../layout/layout";
import { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Grid,
  Box,
  Tooltip,
  IconButton,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import {
  deleteUser,
  sendInvite,
  staffUserListData,
} from "../../services/staff-service";
import _ from "lodash";
import TableLoader from "../common/table-loader";
import SearchComponent from "../common/search";
import { getDateTime } from "../../helpers/datetime-util";
import ENUMS from "../../constants/enum";
import DeleteIcon from "@mui/icons-material/Delete";
import InviteUser from "./invite-user";
import BlankUserListImage from "../../assets/images/blank-userlist.png";
import {
  DialogPrompt,
  NoRecordFoundBox,
  StatusComponent,
} from "../common/other-component";
import { ForwardToInbox } from "@mui/icons-material";
import moment from "moment";
import { Toast } from "../common/toast-utils";

const componentStyle = {
  tableHeadCellStyle: {
    background: "#fafafa",
    fontWeight: 700,
    fontSize: "16px",
  },
  tableBodyCellStyle: { fontSize: "14px", fontWeight: 400 },
};

function UserList() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [pagesize, setPageSize] = useState(ENUMS.DEFAULT_PAGESIZE);
  const [size, setSize] = useState(0);
  const [page, setPage] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [isInviteUserOpen, setInviteUserOpen] = useState(false);
  const [reRender, setRender] = useState(0);
  const [isDelteItem, setDeleteItem] = useState(false);
  const [userItem, setUserItem] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("desc");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const sortedData = rows.sort((a, b) => {
  //   const aValue = a[orderBy]
  //   const bValue = b[orderBy]
  //   if (orderBy === 'role' && rows.some(e => e.role !== null)) {
  //     return order === 'asc' ? aValue['name'].localeCompare(bValue['name']) : bValue['name'].localeCompare(aValue['name'])
  //   }
  //   if (typeof aValue === 'number' && typeof bValue === 'number') {
  //     return order === 'asc' ? aValue - bValue : bValue - aValue
  //   } else if (moment(aValue, 'MM-DD-YYYY LT', true).isValid() && moment(bValue, 'MM-DD-YYYY LT', true).isValid()) {
  //     // Sort formatted date-time using moment.js
  //     return order === 'asc' ? moment(aValue, 'MM-DD-YYYY LT') - moment(bValue, 'MM-DD-YYYY LT') : moment(bValue, 'MM-DD-YYYY LT') - moment(aValue, 'MM-DD-YYYY LT')
  //   } else {
  //     return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
  //   }
  // })

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const sorting = order === "desc" ? "-" + orderBy : orderBy;
        const request = {
          page_index: pageindex,
          page_size: pagesize,
          search_string: searchString,
          sorting: sorting,
        };
        const res = await staffUserListData(request);
        if (res.success) {
          const list_data = res.data.results
            ? res.data.results.map((e) => ({
                ...e,
                formatted_createddate: getDateTime(e.created_at),
              }))
            : [];
          setSize(res.data.count);
          setRows(list_data);
        } else {
          setRows([]);
          Toast.error(res.error_message);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        Toast.error(error);
        setRows([]);
        setLoading(false);
      }
    })();
  }, [reRender, pageindex, pagesize, searchString, order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageIndex(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageIndex(1);
    setPageSize(parseInt(event.target.value, 10));
  };

  const getStatus = (status) => {
    const { color, label } = ENUMS.USER_STATUS.find((d) => d.label === status);
    return <StatusComponent color={color} label={label} size="small" />;
  };

  const handleListAction = (item, action) => {
    setUserItem(item);
    if (action === "DELETE") setDeleteItem(true);
    else if (action === "RESEND_INVITE") resendInvite(item);
  };

  const confirmDelete = async () => {
    setActionLoading(true);
    try {
      const res = await deleteUser(userItem.id);
      if (res.success) {
        setDeleteItem(false);
        Toast.success(res.data.detail);
        setRender((p) => p + 1);
      } else {
        Toast.error(res.error_message);
      }
    } catch (error) {
      Toast.error(error);
      console.log(error);
    }
    setActionLoading(false);
  };

  const resendInvite = async (user_data) => {
    setActionLoading(true);
    try {
      let payload = {
        first_name: user_data.first_name,
        last_name: user_data.last_name,
        email: user_data.email,
        role: user_data.role && user_data.role.id ? user_data.role.id : null,
      };
      const res = await sendInvite(payload);
      if (res.success) {
        Toast.success(res.data.detail);
        setRender((p) => p + 1);
      } else {
        Toast.error(res.error_message);
      }
    } catch (error) {
      Toast.error(error);
      console.log(error);
    }
    setActionLoading(false);
  };

  const labelDisplayedRows = ({ count }) => {
    // Calculate the total number of pages based on the total number of rows and rows per page
    const totalPages = Math.ceil(count / pagesize);
    return `Page ${pageindex} of ${totalPages}`;
  };
  return (
    <Layout>
      <Box component="div" mb={2}>
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid item>
            <Button
              variant="contained"
              size="large"
              className="common-btn"
              onClick={() => setInviteUserOpen(true)}
            >
              Invite Member
            </Button>
          </Grid>
          <Grid item>
            <SearchComponent
              searchString={searchString}
              setSearchString={setSearchString}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="dashboardtblScroll theme-scroll-bar" id="style-1">
        {_.isEmpty(rows) && !loading ? (
          <NoRecordFoundBox img_url={BlankUserListImage} />
        ) : (
          <Table size="small" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "id"}
                    direction={orderBy === "id" ? order : "asc"}
                    onClick={() => handleRequestSort("id")}
                  >
                    Member ID
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "45%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "user__first_name"}
                    direction={orderBy === "user__first_name" ? order : "asc"}
                    onClick={() => handleRequestSort("user__first_name")}
                  >
                    Member Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={orderBy === "status" ? order : "asc"}
                    onClick={() => handleRequestSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "role"}
                    direction={orderBy === "role" ? order : "asc"}
                    onClick={() => handleRequestSort("role")}
                  >
                    Role
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "15%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "created_at"}
                    direction={orderBy === "created_at" ? order : "asc"}
                    onClick={() => handleRequestSort("created_at")}
                  >
                    Created Date
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableLoader cols={6} />
            ) : (
              <TableBody>
                {rows.map((tableRow, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        Member&nbsp;
                        {tableRow.id}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.first_name + " " + tableRow.last_name}
                        <br />
                        {tableRow.email}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {getStatus(tableRow.status)}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.role ? tableRow.role.name : ""}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.formatted_createddate}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {!_.isEmpty(userItem) &&
                          tableRow.id === userItem.id &&
                          actionLoading ? (
                            <CircularProgress size={20} thickness={5} />
                          ) : (
                            <>
                              <Tooltip
                                title="Delete"
                                placement="top"
                                PopperProps={{ disablePortal: true }}
                              >
                                <IconButton
                                  style={{ color: "#FC0005" }}
                                  size="small"
                                  onClick={() =>
                                    handleListAction(tableRow, "DELETE")
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              {tableRow.status === "Request Sent" ? (
                                <Tooltip
                                  title="Resend Invite"
                                  placement="top"
                                  PopperProps={{ disablePortal: true }}
                                >
                                  <IconButton
                                    fontSize="small"
                                    onClick={() =>
                                      handleListAction(
                                        tableRow,
                                        "RESEND_INVITE"
                                      )
                                    }
                                  >
                                    <ForwardToInbox />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        )}
      </div>
      {!_.isEmpty(rows) && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={size}
          rowsPerPage={pagesize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={ENUMS.ROWS_PER_PAGETEXT}
          sx={{ fontFamily: ENUMS.THEME.FONT_FAMILY }}
        />
      )}
      {isInviteUserOpen && (
        <InviteUser
          open={isInviteUserOpen}
          onClose={() => setInviteUserOpen(false)}
          afterSubmit={() => setRender((p) => p + 1)}
        />
      )}
      <DialogPrompt
        open={isDelteItem}
        onCancel={() => setDeleteItem(false)}
        onConfirm={() => confirmDelete()}
        title="Are you sure you want to delete this member?"
        loading={actionLoading}
      />
    </Layout>
  );
}

export default UserList;
