import './layout.css'
import { Container } from '@mui/material'
import Navbar from './navbar'
import { useContext, useEffect } from 'react'
import { SkandikaContextData } from '../../context'

// Pass the child props
export default function Layout({ children }) {
  const { refetchPendingCountData } = useContext(SkandikaContextData)

  useEffect(() => {
    ;(async () => {
      await refetchPendingCountData()
    })()
  }, [])

  return (
    <div>
      <Navbar />
      <Container maxWidth='xl' sx={{ padding: 2 }}>
        {children}
      </Container>
    </div>
  )
}
