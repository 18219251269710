import { useNavigate } from "react-router-dom";
import Layout from "../layout/layout";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  Box,
  Tooltip,
  IconButton,
  TableSortLabel,
  Popover,
  List,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  ListItemButton,
  Drawer,
} from "@mui/material";
import NoVirtualTrainingProgramFoundImage from "../../assets/images/no-video.png";
import {
  DialogPrompt,
  NoRecordFoundBox,
  StatusComponent,
} from "../common/other-component";
import SearchComponent from "../common/search";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import TableLoader from "../common/table-loader";
import { getDateTime } from "../../helpers/datetime-util";
import getUserRole from "../../helpers/getuser-role";
import ENUMS from "../../constants/enum";
import {
  CheckCircleOutlined,
  CloseOutlined,
  Delete,
  Edit,
  MoreVert,
  UnpublishedOutlined,
  Visibility,
} from "@mui/icons-material";
import moment from "moment";
import {
  FloatingButton,
  MinimalDatePicker,
  MinimalSingleSelect,
} from "../common/form-control";
import { SkandikaContextData } from "../../context";
import { Toast } from "../common/toast-utils";
import {
  virtualTrainingProgramListData,
  deleteVirtualTrainingProgram,
  virtualTrainingProgramAction,
} from "../../services/virtualTrainingProgram";
import dayjs from "dayjs";

const defaultFilterDetail = {
  updated_at: null,
  status: null,
};

function VirtualTrainingProgram() {
  const checkUserRole = new getUserRole();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [pagesize, setPageSize] = useState(ENUMS.DEFAULT_PAGESIZE);
  const [size, setSize] = useState(0);
  const [page, setPage] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [reRender, setRender] = useState(0);
  const [orderBy, setOrderBy] = useState("updated_at");
  const [order, setOrder] = useState("desc");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [isDelteItem, setDeleteItem] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filterDetail, setFilterDetail] = useState(defaultFilterDetail);
  const {
    refetchPendingCountData,
    handleVirtualTrainingProgramExist,
    contextData,
  } = useContext(SkandikaContextData);
  const addVirtualTrainingProgram = () => {
    navigate(ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.ADD);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const sorting = order === "desc" ? "-" + orderBy : orderBy;
        let request = {
          page_index: pageindex,
          page_size: pagesize,
          search_string: searchString,
          sorting: sorting,
        };
        if (!_.isEmpty(filterDetail.updated_at)) {
          // Set the initial time to the current time
          const initialTime = moment().format("HH:mm");
          // Combine the initial date and time
          const updated_at = moment(
            `${filterDetail.updated_at.format("YYYY-MM-DD")} ${initialTime}`,
            "YYYY-MM-DD HH:mm"
          );
          request.updated_at = moment.utc(updated_at).format("YYYY-MM-DD");
        }
        if (!_.isEmpty(filterDetail.status)) {
          request.status = filterDetail.status.value;
        }
        const res = await virtualTrainingProgramListData(request);
        if (res.success) {
          const list_data = res.data.results
            ? res.data.results.map((e) => ({
                ...e,
                video_id: e.id,
                video_name: e.video_name,
                video_size: e.video_size,
                updated_at: getDateTime(e.updated_at),
                uploaded_by_name: e.uploaded_by_name,
              }))
            : [];
          setSize(res.data.count);
          setRows(list_data);
          if (list_data.length > 0) {
            handleVirtualTrainingProgramExist(true);
          } else if (
            contextData &&
            contextData.isVirtualTrainingProgramExist &&
            !list_data.length
          ) {
            handleVirtualTrainingProgramExist(false);
          }
        } else {
          setRows([]);
          Toast.error(res.error_message);
        }
        setLoading(false);
      } catch (error) {
        Toast.error(error);
        setRows([]);
        setLoading(false);
      }
    })();
  }, [reRender, pageindex, pagesize, searchString, order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageIndex(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageIndex(1);
    setPageSize(parseInt(event.target.value, 10));
  };

  const getStatus = (row) => {
    if (_.isEmpty(row.status)) {
      return "";
    }
    const item = [...ENUMS.ANNOUNCEMENT_STATUS].find(
      (d) => d.label.toLowerCase() === row.status.toLowerCase()
    );
    if (item) {
      return (
        <StatusComponent color={item.color} label={item.label} size="small" />
      );
    } else {
      return "";
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAction = async (action) => {
    if (action === "PUBLISH")
      await updateStatus(
        ENUMS.VTP_ACTION_STATUS_ENUM.Published,
        selectedRow.id
      );
    else if (action === "UNPUBLISH")
      await updateStatus(
        ENUMS.VTP_ACTION_STATUS_ENUM.Unpublish,
        selectedRow.id
      );
    else if (action === "DELETE") setDeleteItem(true);
    setAnchorEl(null);
  };

  const updateStatus = async (action_status, id) => {
    setActionLoading(true);
    try {
      let payload = {
        status: action_status,
      };
      const res = await virtualTrainingProgramAction(id, payload);
      if (res.success) {
        Toast.success(res.data.detail);
        setRender((p) => p + 1);
        await refetchPendingCountData();
      } else {
        Toast.error(res.error_message);
      }
    } catch (error) {
      Toast.error(error);
    }
    setActionLoading(false);
  };

  const handleVirtualTrainingProgramItem = (item) => {
    if (
      checkUserRole.isAdmin() &&
      !_.isEmpty(item.status) &&
      (item.status.toLowerCase() ===
        ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.ANNOUNCEMENT_STATUS_ENUM.Pending.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.ANNOUNCEMENT_STATUS_ENUM.PendingForApproval.toLowerCase())
    ) {
      navigate(ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.EDIT + item.id);
    } else if (
      checkUserRole.isStaff() &&
      item.editable &&
      !_.isEmpty(item.status) &&
      (item.status.toLowerCase() ===
        ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
        item.status.toLowerCase() ===
          ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase())
    ) {
      navigate(ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.EDIT + item.id);
    } else {
      navigate(ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.VIEW + item.id);
    }
  };

  const confirmDelete = async () => {
    setActionLoading(true);
    try {
      const res = await deleteVirtualTrainingProgram(selectedRow.id);
      if (res.success) {
        setDeleteItem(false);
        Toast.success(res.data.detail);
        setRender((p) => p + 1);
        await refetchPendingCountData();
      } else {
        Toast.error(res.error_message);
      }
    } catch (error) {
      Toast.error(error);
    }
    setActionLoading(false);
  };

  const handleInputChange = async (name, value) => {
    setFilterDetail({ ...filterDetail, [name]: value });
  };

  const handleApplyFilter = async () => {
    setIsOpen(false);
    try {
      setPage(0);
      setPageIndex(1);
      setPageSize(ENUMS.DEFAULT_PAGESIZE);
      setOrderBy("updated_at");
      const sorting = order === "desc" ? "-" + orderBy : orderBy;
      let request = {
        page_index: 1,
        page_size: ENUMS.DEFAULT_PAGESIZE,
        search_string: searchString,
        sorting: sorting,
      };
      if (!_.isEmpty(filterDetail.updated_at)) {
        // Set the initial time to the current time
        const initialTime = moment().format("HH:mm");
        // Combine the initial date and time
        const updated_at = moment(
          `${filterDetail.updated_at.format("YYYY-MM-DD")} ${initialTime}`,
          "YYYY-MM-DD HH:mm"
        );
        request.updated_at = moment.utc(updated_at).format("YYYY-MM-DD");
      }
      if (!_.isEmpty(filterDetail.status)) {
        request.status = filterDetail.status.value;
      }
      const res = await virtualTrainingProgramListData(request);
      if (res.success) {
        const list_data = res.data.results
          ? res.data.results.map((e) => ({
              ...e,
              updated_at: getDateTime(e.updated_at),
              video_id: e.id,
            }))
          : [];
        setSize(res.data.count);
        setRows(list_data);
        if (list_data.length > 0) {
          handleVirtualTrainingProgramExist(true);
        } else if (
          contextData &&
          contextData.isVirtualTrainingProgramExist &&
          !list_data.length
        ) {
          handleVirtualTrainingProgramExist(false);
        }
      } else {
        setRows([]);
        Toast.error(res.error_message);
      }
      setLoading(false);
    } catch (error) {
      Toast.error(error);
      setRows([]);
      setLoading(false);
    }
  };

  const handleClearFilter = () => {
    setIsOpen(false);
    setRender((p) => p + 1);
    setFilterDetail(defaultFilterDetail);
  };

  const labelDisplayedRows = ({ count }) => {
    // Calculate the total number of pages based on the total number of rows and rows per page
    const totalPages = Math.ceil(count / pagesize);
    return `Page ${pageindex} of ${totalPages}`;
  };

  const ActionListItem = ({ icon, text, action, disabled }) => (
    <ListItemButton
      style={ENUMS.COMMON_STYLE.tableStyle().popOverListItemStyle}
      onClick={action}
      disabled={disabled}
    >
      <ListItemIcon
        sx={ENUMS.COMMON_STYLE.tableStyle().popOverListItemIconStyle}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );

  return (
    <Layout>
      <Box component="div" mb={2}>
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid item>
            <Box display="flex" justifyContent="flex-start">
              <FloatingButton
                text="Filter"
                baseClassName="common-btn"
                onClick={() => setIsOpen(true)}
                sx={{ marginRight: "5px" }}
              />
              <FloatingButton
                text={`${ENUMS.COMMON_LABEL.Add} ${ENUMS.COMMON_LABEL.Video}`}
                baseClassName="full-width common-btn"
                onClick={() => addVirtualTrainingProgram()}
              />
            </Box>
          </Grid>
          <Grid item>
            <SearchComponent
              searchString={searchString}
              setSearchString={setSearchString}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="dashboardtblScroll theme-scroll-bar" id="style-1">
        {_.isEmpty(rows) && !loading ? (
          <NoRecordFoundBox img_url={NoVirtualTrainingProgramFoundImage} />
        ) : (
          <Table size="small" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "5%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "id"}
                    direction={orderBy === "id" ? order : "asc"}
                    onClick={() => handleRequestSort("id")}
                  >
                    Id
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "25%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  Title
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "25%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  Device Name
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "video_size"}
                    direction={orderBy === "video_size" ? order : "asc"}
                    onClick={() => handleRequestSort("video_size")}
                  >
                    File Size
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "15%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "updated_at"}
                    direction={orderBy === "updated_at" ? order : "asc"}
                    onClick={() => handleRequestSort("updated_at")}
                  >
                    Updated Date
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "10%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "uploaded_by_name"}
                    direction={orderBy === "uploaded_by_name" ? order : "asc"}
                    onClick={() =>
                      handleRequestSort("uploaded_by__user__first_name")
                    }
                  >
                    Uploaded By
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "5%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={orderBy === "status" ? order : "asc"}
                    onClick={() => handleRequestSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  padding="normal"
                  style={
                    (ENUMS.COMMON_STYLE.tableStyle().tableHeadCellStyle,
                    {
                      width: "5%",
                      backgroundColor: ENUMS.THEME.LIGHT_COLOR,
                    })
                  }
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableLoader cols={8} />
            ) : (
              <TableBody>
                {rows.map((tableRow, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.video_id}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.name.de.name}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow?.device_codes_name?.length
                          ? tableRow.device_codes_name
                              .map((item) => item.de.name)
                              .join(", ")
                          : "-"}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.video_size
                          ? `${tableRow.video_size} MB`
                          : "NA"}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.updated_at}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {tableRow.uploaded_by_name}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {getStatus(tableRow)}
                      </TableCell>
                      <TableCell
                        style={
                          ENUMS.COMMON_STYLE.tableStyle().tableBodyCellStyle
                        }
                      >
                        {checkUserRole.isStaff() && (
                          <>
                            {tableRow.editable &&
                            !_.isEmpty(tableRow.status) &&
                            (tableRow.status.toLowerCase() ===
                              ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
                              tableRow.status.toLowerCase() ===
                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase()) ? (
                              <Tooltip
                                title="Edit"
                                placement="top"
                                sx={{ zIndex: 1, pointerEvents: "auto" }}
                                PopperProps={{ disablePortal: true }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleVirtualTrainingProgramItem(tableRow)
                                  }
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="View"
                                placement="top"
                                PopperProps={{ disablePortal: true }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleVirtualTrainingProgramItem(tableRow)
                                  }
                                >
                                  <Visibility />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                        {checkUserRole.isAdmin() && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {!_.isEmpty(selectedRow) &&
                            tableRow.id === selectedRow.id &&
                            actionLoading ? (
                              <CircularProgress size={20} thickness={5} />
                            ) : (
                              <>
                                {!_.isEmpty(tableRow.status) &&
                                (tableRow.status.toLowerCase() ===
                                  ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
                                  tableRow.status.toLowerCase() ===
                                    ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase() ||
                                  tableRow.status.toLowerCase() ===
                                    ENUMS.ANNOUNCEMENT_STATUS_ENUM.Pending.toLowerCase() ||
                                  tableRow.status.toLowerCase() ===
                                    ENUMS.ANNOUNCEMENT_STATUS_ENUM.PendingForApproval.toLowerCase()) ? (
                                  <Tooltip
                                    title="Edit"
                                    placement="top"
                                    PopperProps={{ disablePortal: true }}
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleVirtualTrainingProgramItem(
                                          tableRow
                                        )
                                      }
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="View"
                                    placement="top"
                                    PopperProps={{ disablePortal: true }}
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleVirtualTrainingProgramItem(
                                          tableRow
                                        )
                                      }
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {!_.isEmpty(tableRow.status) &&
                                  (tableRow.status.toLowerCase() ===
                                    ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase() ||
                                    tableRow.status.toLowerCase() ===
                                      ENUMS.ANNOUNCEMENT_STATUS_ENUM.Published.toLowerCase()) && (
                                    <>
                                      <Tooltip
                                        title="More Actions"
                                        placement="top"
                                        PopperProps={{ disablePortal: true }}
                                      >
                                        <IconButton
                                          onClick={(event) =>
                                            handleMenuClick(event, tableRow)
                                          }
                                        >
                                          <MoreVert />
                                        </IconButton>
                                      </Tooltip>
                                      <Popover
                                        open={
                                          selectedRow === tableRow &&
                                          Boolean(anchorEl)
                                        }
                                        anchorEl={anchorEl}
                                        onClose={handleMenuClose}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                      >
                                        <List
                                          sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            cursor: `${
                                              tableRow.status.toLowerCase() ===
                                              ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase()
                                                ? "not-allowed"
                                                : "pointer"
                                            }`,
                                          }}
                                        >
                                          {!_.isEmpty(tableRow.status) && (
                                            <>
                                              {(tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.Draft.toLowerCase() ||
                                                tableRow.status.toLowerCase() ===
                                                  ENUMS.ANNOUNCEMENT_STATUS_ENUM.Unpublish.toLowerCase()) && (
                                                <ActionListItem
                                                  icon={
                                                    <CheckCircleOutlined
                                                      style={{
                                                        color: "#4BAE4F",
                                                      }}
                                                    />
                                                  }
                                                  text="Publish"
                                                  action={() =>
                                                    handleAction("PUBLISH")
                                                  }
                                                  disabled={actionLoading}
                                                />
                                              )}
                                              {tableRow.status.toLowerCase() ===
                                                ENUMS.ANNOUNCEMENT_STATUS_ENUM.Published.toLowerCase() && (
                                                <ActionListItem
                                                  icon={
                                                    <UnpublishedOutlined
                                                      style={{
                                                        color: "#FC0005",
                                                      }}
                                                    />
                                                  }
                                                  text="Unpublish"
                                                  action={() =>
                                                    handleAction("UNPUBLISH")
                                                  }
                                                  disabled={actionLoading}
                                                />
                                              )}
                                            </>
                                          )}

                                          {!_.isEmpty(tableRow.status) && (
                                            <ListItemButton
                                              style={
                                                ENUMS.COMMON_STYLE.tableStyle()
                                                  .popOverListItemStyle
                                              }
                                              onClick={() =>
                                                handleAction("DELETE")
                                              }
                                              disabled={actionLoading}
                                            >
                                              <ListItemIcon
                                                sx={
                                                  ENUMS.COMMON_STYLE.tableStyle()
                                                    .popOverListItemIconStyle
                                                }
                                                style={{ color: "#FC0005" }}
                                              >
                                                <Delete />
                                              </ListItemIcon>
                                              <ListItemText primary="Delete" />
                                            </ListItemButton>
                                          )}
                                        </List>
                                      </Popover>
                                    </>
                                  )}
                              </>
                            )}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        )}
      </div>
      {!_.isEmpty(rows) && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={size}
          rowsPerPage={pagesize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={ENUMS.ROWS_PER_PAGETEXT}
        />
      )}
      <DialogPrompt
        open={isDelteItem}
        onCancel={() => setDeleteItem(false)}
        onConfirm={() => confirmDelete()}
        title="Are you sure you want to delete the Virtual Training Program?"
        loading={actionLoading}
      />
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="sidebar-drawer"
      >
        <div className="sidebar-drawer-header" style={{ width: "100%" }}>
          <div>Filter </div>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setIsOpen(false)}
          >
            <CloseOutlined style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div
          className="table-responsive"
          id="style-1"
          style={{
            maxHeight: "calc(100vh - 65px)",
            height: "calc(100vh - 65px)",
            width: "450px",
          }}
        >
          <div style={{ padding: "10px" }}>
            <MinimalDatePicker
              value={filterDetail.updated_at}
              onInputChange={(e) => handleInputChange("updated_at", e)}
              placeholder="Updated Date"
              label="Updated Date"
              maxDate={dayjs()}
            />
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <MinimalSingleSelect
                placeholder="Select Status"
                value={filterDetail.status}
                options={[...ENUMS.VTP_STATUS_LIST]}
                onInputChange={(e) => handleInputChange("status", e)}
              />
            </div>
          </div>
        </div>
        <div className="sidebar-drawer-footer">
          <FloatingButton
            baseClassName={`common-btn ${
              !filterDetail.updated_at && !filterDetail.status
                ? "common-btn-gray"
                : ""
            }`}
            text="Clear All"
            onClick={() => handleClearFilter()}
            sx={{ marginRight: "5px" }}
            disabled={!filterDetail.updated_at && !filterDetail.status}
          />
          <FloatingButton
            baseClassName="common-btn"
            text="Filter"
            onClick={() => handleApplyFilter()}
            disabled={
              dayjs() <= filterDetail.updated_at ||
              (!filterDetail.updated_at && !filterDetail.status)
            }
          />
        </div>
      </Drawer>
    </Layout>
  );
}

export default VirtualTrainingProgram;
