import React from "react";

const ImageUpload = () => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7501 18.3337C16.2814 18.3337 18.3334 16.2816 18.3334 13.7503C18.3334 11.219 16.2814 9.16699 13.7501 9.16699C11.2188 9.16699 9.16675 11.219 9.16675 13.7503C9.16675 16.2816 11.2188 18.3337 13.7501 18.3337Z"
      fill="#2D3838"
    />
    <path
      d="M25.2083 36.6663C25.2082 33.5178 26.1344 30.4388 27.8715 27.8129C29.6087 25.187 32.0801 23.1302 34.9778 21.8988L29.456 16.377C29.3496 16.2706 29.2233 16.1862 29.0843 16.1286C28.9453 16.071 28.7963 16.0414 28.6459 16.0414C28.4954 16.0414 28.3464 16.071 28.2074 16.1286C28.0684 16.1862 27.9421 16.2706 27.8358 16.377L15.4688 28.7441L11.1227 24.3979C11.0163 24.2914 10.89 24.207 10.751 24.1494C10.612 24.0919 10.463 24.0622 10.3125 24.0622C10.1621 24.0622 10.0131 24.0919 9.8741 24.1494C9.7351 24.207 9.60881 24.2914 9.50244 24.3979L2.29167 31.6086V9.16634C2.29227 8.55874 2.53391 7.9762 2.96355 7.54656C3.39319 7.11692 3.97573 6.87528 4.58333 6.87467H43.5417C44.1493 6.87528 44.7318 7.11692 45.1614 7.54656C45.5911 7.9762 45.8327 8.55874 45.8333 9.16634V21.2977C46.6191 21.5352 47.3853 21.8333 48.125 22.1892V9.16634C48.1236 7.95119 47.6403 6.78621 46.781 5.92697C45.9218 5.06773 44.7568 4.5844 43.5417 4.58301H4.58333C3.36818 4.5844 2.2032 5.06773 1.34396 5.92697C0.484721 6.78621 0.00138896 7.95119 0 9.16634V34.3747C0.00138896 35.5898 0.484721 36.7548 1.34396 37.614C2.2032 38.4733 3.36818 38.9566 4.58333 38.958H25.3906C25.2752 38.1994 25.2143 37.4336 25.2083 36.6663Z"
      fill="#2D3838"
    />
    <path
      d="M41.25 22.917C38.5305 22.917 35.8721 23.7234 33.6109 25.2343C31.3497 26.7452 29.5874 28.8926 28.5467 31.4051C27.506 33.9176 27.2337 36.6822 27.7642 39.3495C28.2948 42.0167 29.6043 44.4667 31.5273 46.3897C33.4503 48.3127 35.9003 49.6222 38.5675 50.1528C41.2348 50.6833 43.9994 50.411 46.5119 49.3703C49.0244 48.3296 51.1718 46.5673 52.6827 44.3061C54.1936 42.0449 55 39.3865 55 36.667C55 33.0203 53.5513 29.5229 50.9727 26.9443C48.3941 24.3656 44.8967 22.917 41.25 22.917ZM46.6435 36.3313C46.5371 36.4377 46.4108 36.5221 46.2718 36.5797C46.1328 36.6373 45.9838 36.6669 45.8334 36.6669C45.6829 36.6669 45.5339 36.6373 45.3949 36.5797C45.2559 36.5221 45.1297 36.4377 45.0233 36.3313L42.3958 33.7039V42.395C42.3958 42.6989 42.2751 42.9903 42.0602 43.2052C41.8453 43.4201 41.5539 43.5408 41.25 43.5408C40.9461 43.5408 40.6547 43.4201 40.4398 43.2052C40.2249 42.9903 40.1042 42.6989 40.1042 42.395V33.7039L37.4768 36.3312C37.2607 36.54 36.9713 36.6555 36.6708 36.6528C36.3704 36.6502 36.083 36.5297 35.8706 36.3173C35.6581 36.1048 35.5376 35.8174 35.535 35.517C35.5324 35.2166 35.6479 34.9271 35.8566 34.711L40.4399 30.1277C40.5463 30.0213 40.6726 29.9369 40.8116 29.8793C40.9506 29.8217 41.0996 29.7921 41.25 29.7921C41.4005 29.7921 41.5495 29.8217 41.6885 29.8793C41.8275 29.9369 41.9538 30.0213 42.0602 30.1277L46.6435 34.711C46.7499 34.8174 46.8343 34.9437 46.8919 35.0827C46.9495 35.2217 46.9792 35.3707 46.9792 35.5212C46.9792 35.6716 46.9495 35.8206 46.8919 35.9596C46.8343 36.0986 46.7499 36.2249 46.6435 36.3313Z"
      fill="#2D3838"
    />
  </svg>
);

export default ImageUpload;
