import post, { get } from './base-service'
import API_HELPER from '../constants/api-urls'

export const login = async requestData => {
  try {
    requestData = { ...requestData }
    const response = await post(`${API_HELPER.URL.login}`, requestData)
    return response
  } catch (error) {
    console.log('Login service catch-----------------', error)
    return error
  }
}

export const sendCode = async requestData => {
  try {
    const response = await post(`${API_HELPER.URL.sendCode}`, requestData)
    return response
  } catch (error) {
    console.log('SendCode service catch-----------------', error)
    return error
  }
}

export const verifyCodeAndEmail = async requestData => {
  try {
    const response = await post(`${API_HELPER.URL.verifyEmail}`, requestData)
    return response
  } catch (error) {
    console.log('verifyCodeAndEmail service catch-----------------', error)
    return error
  }
}
export const resetPassword = async (requestData, requestToken) => {
  try {
    const response = await post(`${API_HELPER.URL.resetPassword}`, requestData, requestToken)
    return response
  } catch (error) {
    console.log('resetPassword service catch-----------------', error)
    return error
  }
}

export const setPassword = async (requestData) => {
  try {
    const response = await post(`${API_HELPER.URL.setPassword}?username=${requestData.user_id}`, requestData)
    return response
  } catch (error) {
    console.log('setPassword service catch-----------------', error)
    return error
  }
}

export const rejectInvite = async (username_uuid) => {
  try {
    const response = await post(`${API_HELPER.URL.rejectInvite}?username=${username_uuid}`, null)
    return response
  } catch (error) {
    console.log('rejectInvite service catch-----------------', error)
    return error
  }
}

export const userInviteValidity = async (username_uuid, action) => {
  try {
    const response = await get(`${API_HELPER.URL.staffInviteValidity}?username=${username_uuid}&action=${action}`)
    return response
  } catch (error) {
    console.log('userInviteValidity service catch-----------------', error)
    return error
  }
}