import { isEmpty } from "lodash";
import ENUMS from "../constants/enum";

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export async function imageUrlToBase64(imageUrl) {
  // Create an image element
  const img = new Image();

  // Set up a cross-origin attribute to avoid security issues
  img.crossOrigin = "anonymous";

  return new Promise((resolve, reject) => {
    // Define an onload callback to handle the loaded image
    img.onload = function () {
      // Create a canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the canvas dimensions to match the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);

      // Get the Base64-encoded image data
      const base64 = canvas.toDataURL("image/jpeg"); // Change the format as needed

      // Resolve the promise with the Base64 data
      resolve(base64);
    };

    // Handle any error loading the image
    img.onerror = function () {
      reject(new Error("Failed to load the image."));
    };

    // Set the image source to trigger the onload callback
    img.src = imageUrl;
  });
}
export const isValidFileType = (file) => {
  const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
  return allowedFileTypes.includes(file.type);
};

export const isValidVideoFileType = (file) => {
  const allowedFileTypes = ["video/mp4", "video/webm"];
  return allowedFileTypes.includes(file.type);
};

export const isValidVideoFileSize = (file) => {
  const maxVideoSizeInBytes = 2 * 1024 * 1024 * 1024; // 2 GB in bytes
  return file.size <= maxVideoSizeInBytes;
};

export const isValidTextFileType = (file) => {
  const allowedFileTypes = ["text/plain"];
  return allowedFileTypes.includes(file.type);
};

export const setMultipleSelectedValue = (options = [], value = []) => {
  let selectedValue = [];
  if (!isEmpty(value)) {
    selectedValue = options.filter((e) =>
      value.includes(
        typeof e.value === "string" ? e.value.toUpperCase() : e.value
      )
    );
    if (
      options.length ===
      options.filter((e) =>
        value.includes(
          typeof e.value === "string" ? e.value.toUpperCase() : e.value
        )
      ).length
    ) {
      selectedValue.unshift(ENUMS.SELECTALL_OPTION);
    }
  }
  return selectedValue;
};

export const getVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.onerror = function (err) {
      reject(err);
    };
    video.src = URL.createObjectURL(file);
  });
};

export const formatDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const bytesToMB = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2); // Convert bytes to megabytes and round to 2 decimal places
};
