import axios from "axios";
import API_HELPER from "../constants/api-urls";
import { get as isArray } from "lodash";

const API_BASE_URL = API_HELPER.BASE;

async function handleRequest(
  method,
  url,
  requestData,
  token = null,
  responseType = "json"
) {
  const apiUrl = url.includes("translation.googleapis.com")
    ? url
    : `${API_BASE_URL}${url}`;
  const headers = {
    "Accept-Language": "en",
  };

  if (!url.includes("translation.googleapis.com")) {
    headers.Platform = "Admin";
  }

  if (token !== null) {
    headers.Authorization = `Token ${token}`;
  }

  try {
    const response = await axios({
      method: method,
      url: apiUrl,
      data: requestData,
      responseType: responseType,
      headers: headers,
    });

    if (method === "GET") {
      return {
        success: true,
        data:
          response.data && response.data.data
            ? response.data.data
            : response.data,
      };
    } else {
      return { success: true, data: response.data };
    }
  } catch (error) {
    handleApiError(error);
  }
}

export default async function post(
  url,
  requestData,
  token = null,
  responseType = "json"
) {
  let accessToken = localStorage.getItem("AccessToken");
  if (token !== null) {
    accessToken = token;
  }
  return handleRequest("POST", url, requestData, accessToken, responseType);
}

export async function get(url, token = null) {
  let accessToken = localStorage.getItem("AccessToken");
  if (token !== null) {
    accessToken = token;
  }
  return handleRequest("GET", url, null, accessToken);
}

export async function deleteAction(url) {
  let accessToken = localStorage.getItem("AccessToken");

  return handleRequest("DELETE", url, null, accessToken);
}

export async function patch(
  url,
  requestData,
  token = null,
  responseType = "json"
) {
  let accessToken = localStorage.getItem("AccessToken");
  if (token !== null) {
    accessToken = token;
  }
  return handleRequest("PATCH", url, requestData, accessToken, responseType);
}

function handleApiError(error) {
  if (
    error.request &&
    (!error.request.status || error.request.status === 502)
  ) {
    window.location.href = `${window.location.origin}/ui/undermaintenance`;
  } else if (error.response && error.response.status === 401) {
    window.location.href = `${window.location.origin}/ui`;
  } else {
    let errorMsg = "Something went wrong!";
    if (error.request && error.request.response) {
      const actual_msg = JSON.parse(error.request.response);
      if (actual_msg.non_field_errors) {
        errorMsg = actual_msg.non_field_errors[0];
      } else if (actual_msg.detail) {
        errorMsg = isArray(actual_msg.detail)
          ? actual_msg.detail[0]
          : actual_msg.detail;
      }
    }
    const errorResponse = { success: false, error_message: errorMsg };
    throw errorResponse;
  }
}
