import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Divider, FormHelperText, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import Layout from '../layout/layout'
import { ArrowBack, CloudUpload } from '@mui/icons-material'
import { FloatingButton, MinimalTextArea, MinimalTextField } from '../common/form-control'
import { useEffect, useRef, useState } from 'react'
import _, { get, isEmpty } from 'lodash'
import { validateSchema } from '../../helpers/schema-validation'
import * as yup from 'yup'
import { RingSpinnerOverlay } from 'react-spinner-overlay'
import { addTemplate, getTemplateDetailById } from '../../services/template-service'
import { useNavigate, useParams } from 'react-router-dom'
import ENUMS from '../../constants/enum'
import { imageUrlToBase64, isValidFileType } from '../../helpers/common-helper'
import getUserRole from '../../helpers/getuser-role'
import { DialogPrompt, PreviewDialogPrompt } from '../common/other-component'
import { languageTranslate } from '../../services/googletranslate-services'
import { Toast } from '../common/toast-utils'

function CharacterCounter({ text, limit }) {
  const remaining = limit - text.length

  return (
    <InputAdornment position='end'>
      <span style={{ color: '#F20000' }}>
        {text.length}/{limit}
      </span>
    </InputAdornment>
  )
}

function AddTemplate() {
  const checkUserRole = new getUserRole()
  const navigate = useNavigate()
  const titleCharLimit = 60
  const descriptionCharLimit = 400
  const urlParams = useParams()
  const [templateNameError, setTemplateNameError] = useState({})
  const [templateName, setTemplateName] = useState('')
  const [deErrors, setDeErrors] = useState({})
  const [enErrors, setEnErrors] = useState({})
  const [itErrors, setItErrors] = useState({})
  const [frErrors, setFrErrors] = useState({})
  const [nlErrors, setNlErrors] = useState({})
  const [esErrors, setEsErrors] = useState({})
  const [deContent, setDeContent] = useState({
    //german
    language_code: 'de',
    title: '',
    description: '',
    redirect_url: null,
    image: null,
  })
  const [enContent, setEnContent] = useState({
    //english
    language_code: 'en',
    title: '',
    description: '',
    redirect_url: null,
    image: null,
  })
  const [itContent, setItContent] = useState({
    //italian
    language_code: 'it',
    title: '',
    description: '',
    redirect_url: null,
    image: null,
  })
  const [frContent, setFrContent] = useState({
    //french
    language_code: 'fr',
    title: '',
    description: '',
    redirect_url: null,
    image: null,
  })
  const [nlContent, setNlContent] = useState({
    //dutch
    language_code: 'nl',
    title: '',
    description: '',
    redirect_url: null,
    image: null,
  })
  const [esContent, setEsContent] = useState({
    //spanish
    language_code: 'es',
    title: '',
    description: '',
    redirect_url: null,
    image: null,
  })
  const [loading, setLoading] = useState(false)
  const [focusedRef, setFocusedRef] = useState({})
  const textfieldRefs = {
    deTitle: useRef(),
    enTitle: useRef(),
    frTitle: useRef(),
    // itTitle: useRef(),
    // nlTitle: useRef(),
    // esTitle: useRef(),
  }
  const textareaRefs = {
    deDescription: useRef(),
    enDescription: useRef(),
    frDescription: useRef(),
    // itDescription: useRef(),
    // nlDescription: useRef(),
    // esDescription: useRef(),
  }
  const fileUploadInputRefs = {
    deInputRefs: useRef(),
    enInputRefs: useRef(),
    itInputRefs: useRef(),
    frInputRefs: useRef(),
    nlInputRefs: useRef(),
    esInputRefs: useRef(),
  }
  const [templateId, setTemplateId] = useState(0)
  const [showBackClickDialogue, setShowBackClickDialogue] = useState(false)
  const [previewContent, setPreviewContent] = useState({
    isVisible: false,
    title: '',
    description: '',
    image: null,
  })
  const [isDataChanged, setIsDataChanged] = useState(false)
  const isViewOnly = window.location.pathname.includes('/view') ? true : false

  useEffect(() => {
    setIsChangesMade(false)
    async function getTemplateById() {
      try {
        if (!isEmpty(urlParams) && urlParams.id !== 0) {
          setLoading(true)
          const res = await getTemplateDetailById(urlParams.id)
          if (res.success) {
            if (!isEmpty(res.data)) {
              const responseData = res.data
              setTemplateId(responseData.id)
              setTemplateName(responseData.name)
              if (!isEmpty(responseData.contents)) {
                const items = await Promise.all(
                  responseData.contents.map(async item => {
                    let imageBase64 = item.image
                    if (!isEmpty(item.image)) {
                      imageBase64 = await imageUrlToBase64(item.image)
                    }
                    return {
                      ...item,
                      image: imageBase64,
                    }
                  })
                )

                items.forEach(item => {
                  let detail_obj = {
                    language_code: item.language_code,
                    title: item.title,
                    description: item.description,
                    redirect_url: item.redirect_url,
                    image: item.image,
                  }
                  if (detail_obj.language_code === 'de') {
                    setDeContent(detail_obj)
                  } else if (detail_obj.language_code === 'en') {
                    setEnContent(detail_obj)
                  } else if (detail_obj.language_code === 'fr') {
                    setFrContent(detail_obj)
                  } else if (detail_obj.language_code === 'nl') {
                    setNlContent(detail_obj)
                  } else if (detail_obj.language_code === 'it') {
                    setItContent(detail_obj)
                  } else if (detail_obj.language_code === 'es') {
                    setEsContent(detail_obj)
                  }
                })
              }
            }
          } else {
            Toast.error(res.error_message)
          }
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
        Toast.error(error)
      }
    }
    getTemplateById()
  }, [urlParams])

  useEffect(() => {
    const focusOnFirstError = (errorFields, language_code) => {
      const firstErrorField = Object.keys(errorFields).find(key => errorFields[key] !== '')

      if (firstErrorField) {
        const input_id = language_code + '.' + firstErrorField
        const firstErrorElement = document.getElementById(input_id)
        setErrorTextFocus(firstErrorElement)
      }
    }
    if (_.isEmpty(templateName)) {
      const firstErrorElement = document.getElementById('template_name')
      setErrorTextFocus(firstErrorElement)
    } else if (!_.isEmpty(deErrors)) {
      focusOnFirstError(deErrors, 'de')
    } else if (!_.isEmpty(enErrors)) {
      focusOnFirstError(enErrors, 'en')
    } else if (!_.isEmpty(frErrors)) {
      focusOnFirstError(frErrors, 'fr')
    }
  }, [templateNameError, deErrors, enErrors, frErrors])

  const setErrorTextFocus = input_element => {
    if (input_element && input_element.parentElement.nextElementSibling && input_element.parentElement.nextElementSibling.classList.contains('Mui-error')) {
      const errorElement = input_element.parentElement.nextElementSibling
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }
  const CardTitle = () => {
    return (
      <>
        <IconButton
          size='small'
          onClick={() => {
            if (isViewOnly) {
              setIsChangesMade(false)
              navigate('/templates')
            } else if (isDataChanged) {
              setShowBackClickDialogue(true)
            } else {
              setIsChangesMade(false)
              navigate('/templates')
            }
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography component='span' display='inline-flex' alignItems='center' fontWeight='600'>
          {!isEmpty(urlParams) && urlParams.id !== 0 ? (isViewOnly ? 'View Template' : 'Edit Template') : 'Add Template'}
        </Typography>
      </>
    )
  }

  const handleFocusedTextField = () => {
    const focusedTextareaRef = Object.values(textareaRefs).find(ref => ref.current === document.activeElement)
    if (focusedTextareaRef) {
      setFocusedRef(focusedTextareaRef)
    } else {
      const focusedTextFieldRef = Object.values(textfieldRefs).find(ref => ref.current.contains(document.activeElement))
      if (focusedTextFieldRef) setFocusedRef(focusedTextFieldRef)
    }
  }

  const insertTextAtCursor = text => {
    const focusedInputRef = focusedRef
    if (focusedInputRef.current) {
      const forminput = focusedInputRef.current
      if (forminput.id && forminput.id.includes('.title') && forminput.value.includes(text)) {
        //allow same dynamic variable only once in title
        return
      }
      const start = forminput.selectionStart
      const end = forminput.selectionEnd
      const currentValue = forminput.value
      const newValue = currentValue.substring(0, start) + text + currentValue.substring(end)
      forminput.value = newValue
      const newCursorPosition = start + text.length
      forminput.setSelectionRange(newCursorPosition, newCursorPosition)
      forminput.focus()
      if (forminput.id) {
        const language_code = forminput.id.substring(0, forminput.id.indexOf('.'))
        const statePropertyName = forminput.id.split('.').pop()
        if (language_code === 'de') handleDeInputChange(statePropertyName, newValue)
        else if (language_code === 'en') handleEnInputChange(statePropertyName, newValue)
        else if (language_code === 'it') handleItInputChange(statePropertyName, newValue)
        else if (language_code === 'fr') handleFrInputChange(statePropertyName, newValue)
        else if (language_code === 'nl') handleNlInputChange(statePropertyName, newValue)
        else if (language_code === 'es') handleEsInputChange(statePropertyName, newValue)
      }
    }
  }

  const handleDeInputChange = (name, value) => {
    setIsChangesMade(true)
    if (name !== 'title' && name !== 'description') {
      setDeContent({ ...deContent, [name]: value })
    } else if (name === 'title' && value.length <= titleCharLimit) {
      setDeContent({ ...deContent, [name]: value })
    } else if (name === 'description' && value.length <= descriptionCharLimit) {
      setDeContent({ ...deContent, [name]: value })
    }
  }
  const handleEnInputChange = (name, value) => {
    setIsChangesMade(true)
    if (name !== 'title' && name !== 'description') {
      setEnContent({ ...enContent, [name]: value })
    } else if (name === 'title' && value.length <= titleCharLimit) {
      setEnContent({ ...enContent, [name]: value })
    } else if (name === 'description' && value.length <= descriptionCharLimit) {
      setEnContent({ ...enContent, [name]: value })
    }
  }
  const handleItInputChange = (name, value) => {
    setIsChangesMade(true)
    if (name !== 'title' && name !== 'description') {
      setItContent({ ...itContent, [name]: value })
    } else if (name === 'title' && value.length <= titleCharLimit) {
      setItContent({ ...itContent, [name]: value })
    } else if (name === 'description' && value.length <= descriptionCharLimit) {
      setItContent({ ...itContent, [name]: value })
    }
  }
  const handleFrInputChange = (name, value) => {
    setIsChangesMade(true)
    if (name !== 'title' && name !== 'description') {
      setFrContent({ ...frContent, [name]: value })
    } else if (name === 'title' && value.length <= titleCharLimit) {
      setFrContent({ ...frContent, [name]: value })
    } else if (name === 'description' && value.length <= descriptionCharLimit) {
      setFrContent({ ...frContent, [name]: value })
    }
  }
  const handleNlInputChange = (name, value) => {
    setIsChangesMade(true)
    if (name !== 'title' && name !== 'description') {
      setNlContent({ ...nlContent, [name]: value })
    } else if (name === 'title' && value.length <= titleCharLimit) {
      setNlContent({ ...nlContent, [name]: value })
    } else if (name === 'description' && value.length <= descriptionCharLimit) {
      setNlContent({ ...nlContent, [name]: value })
    }
  }
  const handleEsInputChange = (name, value) => {
    setIsChangesMade(true)
    if (name !== 'title' && name !== 'description') {
      setEsContent({ ...esContent, [name]: value })
    } else if (name === 'title' && value.length <= titleCharLimit) {
      setEsContent({ ...esContent, [name]: value })
    } else if (name === 'description' && value.length <= descriptionCharLimit) {
      setEsContent({ ...esContent, [name]: value })
    }
  }

  const handleBoxClick = fileInputRef => {
    // Trigger the hidden file input
    fileInputRef.current.click()
  }

  const handleFileSelect = event => {
    setIsChangesMade(true)
    const file = event.target.files[0]
    const fileInputLangCode = event.target.id.substring(0, event.target.id.indexOf('.'))
    const stateImagePropertyName = event.target.id.split('.').pop()
    if (file && isValidFileType(file)) {
      if (event.target.id) {
        const reader = new FileReader()
        reader.onload = e => {
          if (file.size > 2 * 1024 * 1024) {
            const error_message = { error: true, msg: ENUMS.COMMON_MSG.INVALID_IMAGE_SIZE }
            handleLanguageContentError(fileInputLangCode, stateImagePropertyName, error_message)
            return
          }

          const base64 = e.target.result
          const img = new Image()
          img.src = base64

          img.onload = () => {
            const width = img.width
            const height = img.height
            const aspectRatio = width / height
            let error_message = null
            if (Math.abs(aspectRatio - 16 / 9) > 0.01) {
              error_message = { error: true, msg: ENUMS.COMMON_MSG.INVALID_IMAGE_ASPECT_RATIO }
            } else {
              if (fileInputLangCode === 'de') {
                setDeContent({ ...deContent, [stateImagePropertyName]: e.target.result })
              } else if (fileInputLangCode === 'en') {
                setEnContent({ ...enContent, [stateImagePropertyName]: e.target.result })
              } else if (fileInputLangCode === 'it') {
                setItContent({ ...itContent, [stateImagePropertyName]: e.target.result })
              } else if (fileInputLangCode === 'fr') {
                setFrContent({ ...frContent, [stateImagePropertyName]: e.target.result })
              } else if (fileInputLangCode === 'nl') {
                setNlContent({ ...nlContent, [stateImagePropertyName]: e.target.result })
              } else if (fileInputLangCode === 'es') {
                setEsContent({ ...esContent, [stateImagePropertyName]: e.target.result })
              }
            }
            handleLanguageContentError(fileInputLangCode, stateImagePropertyName, error_message)
          }
        }
        reader.readAsDataURL(file)
        event.target.value = null
      }
    } else if (file && !isValidFileType(file)) {
      const error_message = { error: true, msg: ENUMS.COMMON_MSG.INVALID_IMAGE_FILE_FORMAT }
      handleLanguageContentError(fileInputLangCode, stateImagePropertyName, error_message)
    }
  }

  const handleLanguageContentError = (language_code, property_name, error_message = null) => {
    if (language_code === 'de') {
      setDeErrors({ ...deErrors, [property_name]: error_message })
    } else if (language_code === 'en') {
      setEnErrors({ ...enErrors, [property_name]: error_message })
    } else if (language_code === 'it') {
      setItErrors({ ...itErrors, [property_name]: error_message })
    } else if (language_code === 'fr') {
      setFrErrors({ ...frErrors, [property_name]: error_message })
    } else if (language_code === 'nl') {
      setNlErrors({ ...nlErrors, [property_name]: error_message })
    } else if (language_code === 'es') {
      setEsErrors({ ...esErrors, [property_name]: error_message })
    }
  }

  const clearImage = language_code => {
    setIsChangesMade(true)
    if (language_code === 'de') setDeContent({ ...deContent, image: null })
    else if (language_code === 'en') setEnContent({ ...enContent, image: null })
    else if (language_code === 'it') setItContent({ ...itContent, image: null })
    else if (language_code === 'fr') setFrContent({ ...frContent, image: null })
    else if (language_code === 'nl') setNlContent({ ...nlContent, image: null })
    else if (language_code === 'es') setEsContent({ ...esContent, image: null })
  }

  const handleSaveOrReviewBtnClick = async (e, is_review = false) => {
    e.preventDefault()
    const deContentValid = await checkContentValidation('de')
    const enContentValid = await checkContentValidation('en')
    // const itContentValid = await checkContentValidation('it')
    const frContentValid = await checkContentValidation('fr')
    // const nlContentValid = await checkContentValidation('nl')
    // const esContentValid = await checkContentValidation('es')

    // if (!isEmpty(templateName) && deContentValid === true && enContentValid === true && itContentValid === true && frContentValid === true && nlContentValid === true && esContentValid === true) {
    if (!isEmpty(templateName) && deContentValid === true && enContentValid === true && frContentValid === true) {
      console.log('form is valid')
      // let contentList = [deContent, enContent, itContent, frContent, nlContent, esContent]
      let contentList = [deContent, enContent, frContent]
      contentList = contentList.map(element => {
        if (!isEmpty(element.title)) {
          element.title = element.title.trim()
        }
        if (!isEmpty(element.description)) {
          element.description = element.description.trim()
        }
        if (!isEmpty(element.redirect_url)) {
          element.redirect_url = element.redirect_url.trim()
        }
        return element
      })
      let requestPayload = {
        name: templateName,
        contents: contentList,
        review: is_review,
      }
      if (templateId !== 0) {
        requestPayload.id = templateId
      }
      setLoading(true)
      try {
        const res = await addTemplate(requestPayload)
        if (res.success) {
          if (checkUserRole.isAdmin() || (checkUserRole.isStaff() && is_review === true)) {
            setIsChangesMade(false)
            navigate('/templates')
          } else if (checkUserRole.isStaff() && is_review === false && res.data.data) {
            setIsChangesMade(false)
            if (templateId === 0) {
              setTemplateId(res.data.id)
            }
          }
          Toast.success(res.data.detail)
        } else {
          Toast.error(res.error_message)
        }
      } catch (error) {
        Toast.error(error)
        console.log(error)
      }
      setLoading(false)
    } else if (isEmpty(templateName)) {
      setTemplateNameError({ ...templateNameError, error: true, msg: ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW })
    }
  }

  const checkContentValidation = async language_code => {
    let isValid = null
    const schema = yup.object().shape({
      title: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      description: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      redirect_url: yup
        .string()
        .test('valid-url', ENUMS.COMMON_MSG.INVALID_URL, value => {
          if (!value) return true // Allow empty input
          return /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value)
        })
        .nullable(true),
    })
    if (language_code === 'de') {
      isValid = await validateSchema(deContent, schema)
      setDeErrors(isValid)
    } else if (language_code === 'en') {
      isValid = await validateSchema(enContent, schema)
      setEnErrors(isValid)
    }
    // else if (language_code === 'it') {
    //   isValid = await validateSchema(itContent, schema)
    //   setItErrors(isValid)
    // }
    else if (language_code === 'fr') {
      isValid = await validateSchema(frContent, schema)
      setFrErrors(isValid)
    }
    // else if (language_code === 'nl') {
    //   isValid = await validateSchema(nlContent, schema)
    //   setNlErrors(isValid)
    // } else if (language_code === 'es') {
    //   isValid = await validateSchema(esContent, schema)
    //   setEsErrors(isValid)
    // }
    return isValid
  }

  const showPreview = (title, description, image) => {
    const detail = {
      title,
      description,
      image,
      isVisible: true,
    }
    setPreviewContent(detail)
  }

  const handleTranslate = async () => {
    setDeErrors({})
    setEnErrors({})
    setFrErrors({})
    if (!isEmpty(deContent.title) || !isEmpty(deContent.description)) {
      try {
        setLoading(true)
        let translationSource = []
        let textPlaceholders = {
          title: {},
          description: {},
        }
        let isAllDynamicVariableText = false
        if (!isEmpty(deContent.title) && !isEmpty(deContent.description)) {
          if (isOnlyDynamicVariableText(deContent.title) && isOnlyDynamicVariableText(deContent.description)) {
            isAllDynamicVariableText = true
          } else {
            //title detail
            let textDetail = getTextPlaceholders(deContent.title)
            textPlaceholders.title = textDetail.placeholders
            translationSource = [textDetail.modifiedText]
            //description detail
            textDetail = getTextPlaceholders(deContent.description)
            translationSource.push(textDetail.modifiedText)
            textPlaceholders.description = textDetail.placeholders
          }
        } else if (!isEmpty(deContent.title)) {
          if (isOnlyDynamicVariableText(deContent.title)) {
            isAllDynamicVariableText = true
          } else {
            let textDetail = getTextPlaceholders(deContent.title)
            textPlaceholders.title = textDetail.placeholders
            translationSource = [textDetail.modifiedText]
          }
        } else if (!isEmpty(deContent.description)) {
          if (isOnlyDynamicVariableText(deContent.title)) {
            isAllDynamicVariableText = true
          } else {
            let textDetail = getTextPlaceholders(deContent.title)
            translationSource = [textDetail.modifiedText]
            textPlaceholders.description = textDetail.placeholders
          }
        }

        if (isAllDynamicVariableText) {
          //if full text is with only dynamic variable then do not call google api
          let content_obj = getAutoCloneContentData(enContent)
          setEnContent(content_obj)
          // content_obj = getAutoCloneContentData(itContent)
          // setItContent(content_obj)
          content_obj = getAutoCloneContentData(frContent)
          setFrContent(content_obj)
          // content_obj = getAutoCloneContentData(nlContent)
          // setNlContent(content_obj)
          // content_obj = getAutoCloneContentData(esContent)
          // setEsContent(content_obj)
        } else {
          const [enResponse, frResponse] = await Promise.all([
            // english
            languageTranslate({ q: translationSource, target: 'en', source: 'de' }),
            // italian
            // languageTranslate({ q: translationSource, target: 'it', source: 'de' }),
            // french
            languageTranslate({ q: translationSource, target: 'fr', source: 'de' }),
            // // dutch
            // languageTranslate({ q: translationSource, target: 'nl', source: 'de' }),
            // // spanish
            // languageTranslate({ q: translationSource, target: 'es', source: 'de' }),
          ])
          if (enResponse && enResponse.data && enResponse.data.data && enResponse.data.data.translations) {
            let translationResponse = enResponse.data.data.translations
            let content_obj = getAutoCloneContentData(enContent, translationResponse, textPlaceholders)
            setEnContent(content_obj)
          }
          // if (itResponse && itResponse.data && itResponse.data.data && itResponse.data.data.translations) {
          //   let translationResponse = itResponse.data.data.translations
          //   let content_obj = getAutoCloneContentData(itContent, translationResponse, textPlaceholders)
          //   setItContent(content_obj)
          // }
          if (frResponse && frResponse.data && frResponse.data.data && frResponse.data.data.translations) {
            let translationResponse = frResponse.data.data.translations
            let content_obj = getAutoCloneContentData(frContent, translationResponse, textPlaceholders)
            setFrContent(content_obj)
          }
          // if (nlResponse && nlResponse.data && nlResponse.data.data && nlResponse.data.data.translations) {
          //   let translationResponse = nlResponse.data.data.translations
          //   let content_obj = getAutoCloneContentData(nlContent, translationResponse, textPlaceholders)
          //   setNlContent(content_obj)
          // }
          // if (esResponse && esResponse.data && esResponse.data.data && esResponse.data.data.translations) {
          //   let translationResponse = esResponse.data.data.translations
          //   let content_obj = getAutoCloneContentData(esContent, translationResponse, textPlaceholders)
          //   setEsContent(content_obj)
          // }
        }
      } catch (error) {
        console.error('Error translating text:', error)
      } finally {
        setLoading(false)
      }
    } else {
      if (!isEmpty(deContent.image) || !isEmpty(deContent.redirect_url)) {
        let content_obj = {}
        if (!isEmpty(deContent.image) && !isEmpty(deContent.redirect_url)) {
          content_obj = { image: deContent.image, redirect_url: deContent.redirect_url }
        } else if (!isEmpty(deContent.image)) {
          content_obj = { image: deContent.image }
        } else if (!isEmpty(deContent.redirect_url)) {
          content_obj = { redirect_url: deContent.redirect_url }
        }

        setEnContent({ ...enContent, ...content_obj })
        // setItContent({ ...itContent, ...content_obj })
        setFrContent({ ...frContent, ...content_obj })
        // setNlContent({ ...enContent, ...content_obj })
        // setEsContent({ ...esContent, ...content_obj })
      }
    }
  }

  const getTextPlaceholders = text => {
    const excludedWords = ['USER NAME', 'USER EMAIL']
    const placeholders = {}
    let modifiedText = text.replace(/\{\{([^{}]+)\}\}/g, (_, word) => {
      if (excludedWords.includes(word)) {
        const placeholder = `{{Z_${word.replace(/\s+/g, '').toUpperCase()}_Z}}`
        placeholders[placeholder] = `{{${word}}}`
        return placeholder
      } else {
        return `{{${word}}}`
      }
    })
    return { placeholders, modifiedText }
  }

  function isOnlyDynamicVariableText(text) {
    // Use regular expression to find all words enclosed in curly braces
    const matches = text.match(/\{\{[^{}]+\}\}/g)

    // If matches is not null and each match covers the entire text
    return matches !== null && matches.join('') === text.replace(/\s*(?={{)|(?<=}})\s*/g, '')
  }

  const translatedTextWithPlaceholder = (text, placeholders) => {
    Object.entries(placeholders).forEach(([placeholder, original]) => {
      text = text.replace(new RegExp(placeholder, 'g'), original)
    })

    return text
  }

  const getAutoCloneContentData = (oldContent, translationResponse = null, textPlaceholders = null) => {
    let content_obj = {}
    if (translationResponse !== null) {
      if (!isEmpty(deContent.title) && !isEmpty(deContent.description)) {
        content_obj = { ...oldContent, title: translatedTextWithPlaceholder(translationResponse[0].translatedText, textPlaceholders.title), description: translatedTextWithPlaceholder(translationResponse[1].translatedText, textPlaceholders.description) }
      } else if (!isEmpty(deContent.title)) {
        content_obj = { ...oldContent, title: translatedTextWithPlaceholder(translationResponse[0].translatedText, textPlaceholders.title) }
      } else if (!isEmpty(deContent.description)) {
        content_obj = { ...oldContent, description: translatedTextWithPlaceholder(translationResponse[1].translatedText, textPlaceholders.description) }
      }
    } else {
      if (!isEmpty(deContent.title) && !isEmpty(deContent.description)) {
        content_obj = { ...oldContent, title: deContent.title, description: deContent.description }
      } else if (!isEmpty(deContent.title)) {
        content_obj = { ...oldContent, title: deContent.title }
      } else if (!isEmpty(deContent.description)) {
        content_obj = { ...oldContent, description: deContent.description }
      }
    }
    if (!isEmpty(deContent.image)) {
      content_obj = { ...content_obj, image: deContent.image }
    }
    if (!isEmpty(deContent.redirect_url)) {
      content_obj = { ...content_obj, redirect_url: deContent.redirect_url }
    }
    return content_obj
  }

  const setIsChangesMade = isChangesMade => {
    if (isViewOnly === false) {
      setIsDataChanged(isChangesMade)
      if (isChangesMade === false && localStorage.key('IsChangesMade').length) {
        localStorage.removeItem('IsChangesMade')
      } else {
        localStorage.setItem('IsChangesMade', isChangesMade)
      }
    } else {
      if (localStorage.key('IsChangesMade').length) {
        localStorage.removeItem('IsChangesMade')
      }
    }
  }

  return (
    <Layout>
      <RingSpinnerOverlay loading={loading} overlayColor='rgba(0,0,0,0.2)' size={50} color='#2d38384d' borderWidth={3} />
      <Container component='div' maxWidth='xl' className='plr-0'>
        <Card component='div' sx={ENUMS.COMMON_STYLE.cardStyle}>
          <CardHeader title={<CardTitle />} className='template-cardheader' />
          <CardContent className='template-card-content-scroll theme-scroll-bar'>
            <MinimalTextField
              value={templateName}
              onChange={e => {
                setIsChangesMade(true)
                setTemplateName(e.target.value)
              }}
              placeholder='Template Name'
              isRequired={true}
              error={templateNameError && templateNameError.error}
              helperText={templateNameError && templateNameError.msg}
              onFocus={() => {
                setFocusedRef({})
                setTemplateNameError({ ...templateNameError, error: false, msg: '' })
              }}
              sx={{ marginBottom: '15px', marginTop: 0 }}
              id='template_name'
              disabled={isViewOnly}
            />
            {/* German */}
            <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle}>
              <CardHeader title='Content for German' action={<FloatingButton baseClassName='common-btn' text='Preview' sx={{ textTransform: 'none', fontSize: '16px' }} onClick={() => showPreview(deContent.title, deContent.description, deContent.image)} />} className='template-sub-cardheader' />
              <CardContent>
                <MinimalTextField
                  value={get(deContent, 'title', '')}
                  onChange={e => handleDeInputChange('title', e.target.value)}
                  placeholder='Announcement Title'
                  isRequired={true}
                  error={deErrors.title && deErrors.title.error}
                  helperText={deErrors.title && deErrors.title.msg}
                  onFocus={() => {
                    setDeErrors({ ...deErrors, title: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='de.title'
                  InputRef={textfieldRefs.deTitle}
                  endAdornmentProp={<CharacterCounter text={get(deContent, 'title', '')} limit={titleCharLimit} />}
                  sx={{ marginTop: 0 }}
                  disabled={isViewOnly}
                />
                <MinimalTextArea
                  value={get(deContent, 'description', '')}
                  onChange={e => handleDeInputChange('description', e.target.value)}
                  placeholder='Announcement Description'
                  isRequired={true}
                  error={deErrors.description && deErrors.description.error}
                  helperText={deErrors.description && deErrors.description.msg}
                  onFocus={() => {
                    setDeErrors({ ...deErrors, description: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='de.description'
                  InputRef={textareaRefs.deDescription}
                  endAdornmentProp={<CharacterCounter text={get(deContent, 'description', '')} limit={descriptionCharLimit} />}
                  disabled={isViewOnly}
                />
                <div className='mb-20'>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mt: '16px' }}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Box elevation={3} className='dashed-border no-record-box' sx={{ height: '150px', padding: '10px' }}>
                              <input ref={fileUploadInputRefs.deInputRefs} type='file' accept='image/*' style={{ display: 'none' }} onChange={handleFileSelect} required id='de.image' />
                              {isEmpty(deContent.image) && (
                                <>
                                  <CloudUpload sx={{ fontSize: '55px', color: ENUMS.THEME.MAIN_COLOR }} />
                                  <Typography component='p'>Upload your image here</Typography>
                                </>
                              )}
                              {!isEmpty(deContent.image) && <img src={deContent.image} alt='german content img' />}
                            </Box>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                            <div>
                              <FloatingButton
                                baseClassName='common-btn'
                                text='Clear'
                                onClick={() => {
                                  clearImage('de')
                                }}
                                sx={{ mb: 2 }}
                                disabled={isEmpty(deContent.image)}
                              />
                              <FloatingButton baseClassName='common-btn' text='Browse' onClick={() => handleBoxClick(fileUploadInputRefs.deInputRefs)} disabled={isViewOnly} />
                            </div>
                          </Grid>
                        </Grid>
                        <FormHelperText error={deErrors.image && deErrors.image.error}>{deErrors.image && deErrors.image.msg}</FormHelperText>
                        {/* Content for the left grid box */}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        {/* Content for the right grid box */}
                        <MinimalTextField
                          value={get(deContent, 'redirect_url', '') || ''}
                          onChange={e => handleDeInputChange('redirect_url', e.target.value)}
                          placeholder='Redirection URL'
                          isRequired={true}
                          error={deErrors.redirect_url && deErrors.redirect_url.error}
                          helperText={deErrors.redirect_url && deErrors.redirect_url.msg}
                          onFocus={() => {
                            setDeErrors({ ...deErrors, redirect_url: { error: false, msg: '' } })
                          }}
                          id='de.redirect_url'
                          disabled={isViewOnly}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <FloatingButton
                baseClassName='common-btn'
                text='Auto Clone'
                onClick={() => {
                  handleTranslate()
                }}
                disabled={isViewOnly}
                sx={{ mb: 2 }}
              />
            </Box>
            {/* English */}
            <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle}>
              <CardHeader title='Content for English' action={<FloatingButton baseClassName='common-btn' text='Preview' sx={{ textTransform: 'none', fontSize: '16px' }} onClick={() => showPreview(enContent.title, enContent.description, enContent.image)} />} className='template-sub-cardheader' />
              <CardContent>
                <MinimalTextField
                  value={get(enContent, 'title', '')}
                  onChange={e => handleEnInputChange('title', e.target.value)}
                  placeholder='Announcement Title'
                  isRequired={true}
                  error={enErrors.title && enErrors.title.error}
                  helperText={enErrors.title && enErrors.title.msg}
                  onFocus={() => {
                    setEnErrors({ ...enErrors, title: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='en.title'
                  InputRef={textfieldRefs.enTitle}
                  endAdornmentProp={<CharacterCounter text={get(enContent, 'title', '')} limit={titleCharLimit} />}
                  sx={{ marginTop: 0 }}
                  disabled={isViewOnly}
                />
                <MinimalTextArea
                  value={get(enContent, 'description', '')}
                  onChange={e => handleEnInputChange('description', e.target.value)}
                  placeholder='Announcement Description'
                  isRequired={true}
                  error={enErrors.description && enErrors.description.error}
                  helperText={enErrors.description && enErrors.description.msg}
                  onFocus={() => {
                    setEnErrors({ ...enErrors, description: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='en.description'
                  InputRef={textareaRefs.enDescription}
                  endAdornmentProp={<CharacterCounter text={get(enContent, 'description', '')} limit={descriptionCharLimit} />}
                  disabled={isViewOnly}
                />
                <div className='mb-20'>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mt: '16px' }}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Box elevation={3} className='dashed-border no-record-box' sx={{ height: '150px', padding: '10px' }}>
                              <input ref={fileUploadInputRefs.enInputRefs} type='file' accept='image/*' style={{ display: 'none' }} onChange={handleFileSelect} required id='en.image' />
                              {isEmpty(enContent.image) && (
                                <>
                                  <CloudUpload sx={{ fontSize: '55px', color: ENUMS.THEME.MAIN_COLOR }} />
                                  <Typography component='p'>Upload your image here</Typography>
                                </>
                              )}
                              {!isEmpty(enContent.image) && <img src={enContent.image} alt='english content img' />}
                            </Box>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                            <div>
                              <FloatingButton
                                baseClassName='common-btn'
                                text='Clear'
                                onClick={() => {
                                  clearImage('en')
                                }}
                                sx={{ mb: 2 }}
                                disabled={isEmpty(enContent.image)}
                              />
                              <FloatingButton baseClassName='common-btn' text='Browse' onClick={() => handleBoxClick(fileUploadInputRefs.enInputRefs)} disabled={isViewOnly} />
                            </div>
                          </Grid>
                        </Grid>
                        <FormHelperText error={enErrors.image && enErrors.image.error}>{enErrors.image && enErrors.image.msg}</FormHelperText>
                        {/* Content for the left grid box */}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        {/* Content for the right grid box */}
                        <MinimalTextField
                          value={get(enContent, 'redirect_url', '') || ''}
                          onChange={e => handleEnInputChange('redirect_url', e.target.value)}
                          placeholder='Redirection URL'
                          isRequired={true}
                          error={enErrors.redirect_url && enErrors.redirect_url.error}
                          helperText={enErrors.redirect_url && enErrors.redirect_url.msg}
                          onFocus={() => {
                            setEnErrors({ ...enErrors, redirect_url: { error: false, msg: '' } })
                          }}
                          id='en.redirect_url'
                          disabled={isViewOnly}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
            {/* Italian */}
            {/* <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle}>
              <CardHeader title='Content for Italian' action={<FloatingButton baseClassName='common-btn' text='Preview' sx={{ textTransform: 'none', fontSize: '16px' }} onClick={() => showPreview(itContent.title, itContent.description, itContent.image)} />} className='template-sub-cardheader' />
              <CardContent>
                <MinimalTextField
                  value={get(itContent, 'title', '')}
                  onChange={e => handleItInputChange('title', e.target.value)}
                  placeholder='Announcement Title'
                  isRequired={true}
                  error={itErrors.title && itErrors.title.error}
                  helperText={itErrors.title && itErrors.title.msg}
                  onFocus={() => {
                    setItErrors({ ...itErrors, title: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='it.title'
                  InputRef={textfieldRefs.itTitle}
                  endAdornmentProp={<CharacterCounter text={get(itContent, 'title', '')} limit={titleCharLimit} />}
                  sx={{ marginTop: 0 }}
                />
                <MinimalTextArea
                  value={get(itContent, 'description', '')}
                  onChange={e => handleItInputChange('description', e.target.value)}
                  placeholder='Announcement Description'
                  isRequired={true}
                  error={itErrors.description && itErrors.description.error}
                  helperText={itErrors.description && itErrors.description.msg}
                  onFocus={() => {
                    setItErrors({ ...itErrors, description: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='it.description'
                  InputRef={textareaRefs.itDescription}
                  endAdornmentProp={<CharacterCounter text={get(itContent, 'description', '')} limit={descriptionCharLimit} />}
                />
                <div className='mb-20'>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mt: '16px' }}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Box elevation={3} className='dashed-border no-record-box' sx={{ height: '150px', padding: '10px' }}>
                              <input ref={fileUploadInputRefs.itInputRefs} type='file' accept='image/*' style={{ display: 'none' }} onChange={handleFileSelect} required id='it.image' />
                              {isEmpty(itContent.image) && (
                                <>
                                  <CloudUpload sx={{ fontSize: '55px', color: ENUMS.THEME.MAIN_COLOR }} />
                                  <Typography component='p'>Upload your image here</Typography>
                                </>
                              )}
                              {!isEmpty(itContent.image) && <img src={itContent.image} alt='italian content img' />}
                            </Box>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                            <div>
                              <FloatingButton
                                baseClassName='common-btn'
                                text='Clear'
                                onClick={() => {
                                  clearImage('it')
                                }}
                                sx={{ mb: 2 }}
                                disabled={isEmpty(itContent.image)}
                              />
                              <FloatingButton baseClassName='common-btn' text='Browse' onClick={() => handleBoxClick(fileUploadInputRefs.itInputRefs)} />
                            </div>
                          </Grid>
                        </Grid>
                        <FormHelperText error={itErrors.image && itErrors.image.error}>{itErrors.image && itErrors.image.msg}</FormHelperText>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        <MinimalTextField
                          value={get(itContent, 'redirect_url', '') || ''}
                          onChange={e => handleItInputChange('redirect_url', e.target.value)}
                          placeholder='Redirection URL'
                          isRequired={true}
                          error={itErrors.redirect_url && itErrors.redirect_url.error}
                          helperText={itErrors.redirect_url && itErrors.redirect_url.msg}
                          onFocus={() => {
                            setItErrors({ ...itErrors, redirect_url: { error: false, msg: '' } })
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card> */}
            {/* French */}
            <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle}>
              <CardHeader title='Content for French' action={<FloatingButton baseClassName='common-btn' text='Preview' sx={{ textTransform: 'none', fontSize: '16px' }} onClick={() => showPreview(frContent.title, frContent.description, frContent.image)} />} className='template-sub-cardheader' />
              <CardContent>
                <MinimalTextField
                  value={get(frContent, 'title', '')}
                  onChange={e => handleFrInputChange('title', e.target.value)}
                  placeholder='Announcement Title'
                  isRequired={true}
                  error={frErrors.title && frErrors.title.error}
                  helperText={frErrors.title && frErrors.title.msg}
                  onFocus={() => {
                    setFrErrors({ ...frErrors, title: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='fr.title'
                  InputRef={textfieldRefs.frTitle}
                  endAdornmentProp={<CharacterCounter text={get(frContent, 'title', '')} limit={titleCharLimit} />}
                  sx={{ marginTop: 0 }}
                  disabled={isViewOnly}
                />
                <MinimalTextArea
                  value={get(frContent, 'description', '')}
                  onChange={e => handleFrInputChange('description', e.target.value)}
                  placeholder='Announcement Description'
                  isRequired={true}
                  error={frErrors.description && frErrors.description.error}
                  helperText={frErrors.description && frErrors.description.msg}
                  onFocus={() => {
                    setFrErrors({ ...frErrors, description: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='fr.description'
                  InputRef={textareaRefs.frDescription}
                  endAdornmentProp={<CharacterCounter text={get(frContent, 'description', '')} limit={descriptionCharLimit} />}
                  disabled={isViewOnly}
                />
                <div className='mb-20'>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mt: '16px' }}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Box elevation={3} className='dashed-border no-record-box' sx={{ height: '150px', padding: '10px' }}>
                              <input ref={fileUploadInputRefs.frInputRefs} type='file' accept='image/*' style={{ display: 'none' }} onChange={handleFileSelect} required id='fr.image' />
                              {isEmpty(frContent.image) && (
                                <>
                                  <CloudUpload sx={{ fontSize: '55px', color: ENUMS.THEME.MAIN_COLOR }} />
                                  <Typography component='p'>Upload your image here</Typography>
                                </>
                              )}
                              {!isEmpty(frContent.image) && <img src={frContent.image} alt='french content img' />}
                            </Box>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                            <div>
                              <FloatingButton
                                baseClassName='common-btn'
                                text='Clear'
                                onClick={() => {
                                  clearImage('fr')
                                }}
                                sx={{ mb: 2 }}
                                disabled={isEmpty(frContent.image)}
                              />
                              <FloatingButton baseClassName='common-btn' text='Browse' onClick={() => handleBoxClick(fileUploadInputRefs.frInputRefs)} disabled={isViewOnly} />
                            </div>
                          </Grid>
                        </Grid>
                        <FormHelperText error={frErrors.image && frErrors.image.error}>{frErrors.image && frErrors.image.msg}</FormHelperText>
                        {/* Content for the left grid box */}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        {/* Content for the right grid box */}
                        <MinimalTextField
                          value={get(frContent, 'redirect_url', '') || ''}
                          onChange={e => handleFrInputChange('redirect_url', e.target.value)}
                          placeholder='Redirection URL'
                          isRequired={true}
                          error={frErrors.redirect_url && frErrors.redirect_url.error}
                          helperText={frErrors.redirect_url && frErrors.redirect_url.msg}
                          onFocus={() => {
                            setFrErrors({ ...frErrors, redirect_url: { error: false, msg: '' } })
                          }}
                          id='fr.redirect_url'
                          disabled={isViewOnly}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
            {/* Dutch */}
            {/* <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle}>
              <CardHeader title='Content for Dutch' action={<FloatingButton baseClassName='common-btn' text='Preview' sx={{ textTransform: 'none', fontSize: '16px' }} onClick={() => showPreview(nlContent.title, nlContent.description, nlContent.image)} />} className='template-sub-cardheader' />
              <CardContent>
                <MinimalTextField
                  value={get(nlContent, 'title', '')}
                  onChange={e => handleNlInputChange('title', e.target.value)}
                  placeholder='Announcement Title'
                  isRequired={true}
                  error={nlErrors.title && nlErrors.title.error}
                  helperText={nlErrors.title && nlErrors.title.msg}
                  onFocus={() => {
                    setNlErrors({ ...nlErrors, title: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='nl.title'
                  InputRef={textfieldRefs.nlTitle}
                  endAdornmentProp={<CharacterCounter text={get(nlContent, 'title', '')} limit={titleCharLimit} />}
                  sx={{ marginTop: 0 }}
                />
                <MinimalTextArea
                  value={get(nlContent, 'description', '')}
                  onChange={e => handleNlInputChange('description', e.target.value)}
                  placeholder='Announcement Description'
                  isRequired={true}
                  error={nlErrors.description && nlErrors.description.error}
                  helperText={nlErrors.description && nlErrors.description.msg}
                  onFocus={() => {
                    setNlErrors({ ...nlErrors, description: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='nl.description'
                  InputRef={textareaRefs.nlDescription}
                  endAdornmentProp={<CharacterCounter text={get(nlContent, 'description', '')} limit={descriptionCharLimit} />}
                />
                <div className='mb-20'>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mt: '16px' }}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Box elevation={3} className='dashed-border no-record-box' sx={{ height: '150px', padding: '10px' }}>
                              <input ref={fileUploadInputRefs.nlInputRefs} type='file' accept='image/*' style={{ display: 'none' }} onChange={handleFileSelect} required id='nl.image' />
                              {isEmpty(nlContent.image) && (
                                <>
                                  <CloudUpload sx={{ fontSize: '55px', color: ENUMS.THEME.MAIN_COLOR }} />
                                  <Typography component='p'>Upload your image here</Typography>
                                </>
                              )}
                              {!isEmpty(nlContent.image) && <img src={nlContent.image} alt='dutch content img' />}
                            </Box>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                            <div>
                              <FloatingButton
                                baseClassName='common-btn'
                                text='Clear'
                                onClick={() => {
                                  clearImage('nl')
                                }}
                                sx={{ mb: 2 }}
                                disabled={isEmpty(nlContent.image)}
                              />
                              <FloatingButton baseClassName='common-btn' text='Browse' onClick={() => handleBoxClick(fileUploadInputRefs.nlInputRefs)} />
                            </div>
                          </Grid>
                        </Grid>
                        <FormHelperText error={nlErrors.image && nlErrors.image.error}>{nlErrors.image && nlErrors.image.msg}</FormHelperText>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        <MinimalTextField
                          value={get(nlContent, 'redirect_url', '') || ''}
                          onChange={e => handleNlInputChange('redirect_url', e.target.value)}
                          placeholder='Redirection URL'
                          isRequired={true}
                          error={nlErrors.redirect_url && nlErrors.redirect_url.error}
                          helperText={nlErrors.redirect_url && nlErrors.redirect_url.msg}
                          onFocus={() => {
                            setNlErrors({ ...nlErrors, redirect_url: { error: false, msg: '' } })
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card> */}
            {/* Spanish */}
            {/* <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle}>
              <CardHeader title='Content for Spanish' action={<FloatingButton baseClassName='common-btn' text='Preview' sx={{ textTransform: 'none', fontSize: '16px' }} onClick={() => showPreview(esContent.title, esContent.description, esContent.image)} />} className='template-sub-cardheader' />
              <CardContent>
                <MinimalTextField
                  value={get(esContent, 'title', '')}
                  onChange={e => handleEsInputChange('title', e.target.value)}
                  placeholder='Announcement Title'
                  isRequired={true}
                  error={esErrors.title && esErrors.title.error}
                  helperText={esErrors.title && esErrors.title.msg}
                  onFocus={() => {
                    setEsErrors({ ...esErrors, title: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='es.title'
                  InputRef={textfieldRefs.esTitle}
                  endAdornmentProp={<CharacterCounter text={get(esContent, 'title', '')} limit={titleCharLimit} />}
                  sx={{ marginTop: 0 }}
                />
                <MinimalTextArea
                  value={get(esContent, 'description', '')}
                  onChange={e => handleEsInputChange('description', e.target.value)}
                  placeholder='Announcement Description'
                  isRequired={true}
                  error={esErrors.description && esErrors.description.error}
                  helperText={esErrors.description && esErrors.description.msg}
                  onFocus={() => {
                    setEsErrors({ ...esErrors, description: { error: false, msg: '' } })
                    handleFocusedTextField()
                  }}
                  id='es.description'
                  InputRef={textareaRefs.esDescription}
                  endAdornmentProp={<CharacterCounter text={get(esContent, 'description', '')} limit={descriptionCharLimit} />}
                />
                <div className='mb-20'>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mt: '16px' }}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Box elevation={3} className='dashed-border no-record-box' sx={{ height: '150px', padding: '10px' }}>
                              <input ref={fileUploadInputRefs.esInputRefs} type='file' accept='image/*' style={{ display: 'none' }} onChange={handleFileSelect} required id='es.image' />
                              {isEmpty(esContent.image) && (
                                <>
                                  <CloudUpload sx={{ fontSize: '55px', color: ENUMS.THEME.MAIN_COLOR }} />
                                  <Typography component='p'>Upload your image here</Typography>
                                </>
                              )}
                              {!isEmpty(esContent.image) && <img src={esContent.image} alt='spanish content img' />}
                            </Box>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                            <div>
                              <FloatingButton
                                baseClassName='common-btn'
                                text='Clear'
                                onClick={() => {
                                  clearImage('es')
                                }}
                                sx={{ mb: 2 }}
                                disabled={isEmpty(esContent.image)}
                              />
                              <FloatingButton baseClassName='common-btn' text='Browse' onClick={() => handleBoxClick(fileUploadInputRefs.esInputRefs)} />
                            </div>
                          </Grid>
                        </Grid>
                        <FormHelperText error={esErrors.image && esErrors.image.error}>{esErrors.image && esErrors.image.msg}</FormHelperText>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box elevation={3} style={{ height: '170px' }}>
                        <MinimalTextField
                          value={get(esContent, 'redirect_url', '') || ''}
                          onChange={e => handleEsInputChange('redirect_url', e.target.value)}
                          placeholder='Redirection URL'
                          isRequired={true}
                          error={esErrors.redirect_url && esErrors.redirect_url.error}
                          helperText={esErrors.redirect_url && esErrors.redirect_url.msg}
                          onFocus={() => {
                            setEsErrors({ ...esErrors, redirect_url: { error: false, msg: '' } })
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card> */}
          </CardContent>
          <Divider sx={ENUMS.COMMON_STYLE.dividerStyle} />
          <CardActions className='template-cardfooter'>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Typography component='p' sx={{ whiteSpace: 'nowrap' }}>
                Recipient Variables:
              </Typography>
              <FloatingButton text='{{USER NAME}}' baseClassName='common-btn common-btn-gray' sx={{ marginLeft: '5px' }} onClick={() => insertTextAtCursor('{{USER NAME}}')} />
              <FloatingButton text='{{USER EMAIL}}' baseClassName='common-btn common-btn-gray' sx={{ marginLeft: '5px' }} onClick={() => insertTextAtCursor('{{USER EMAIL}}')} />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {checkUserRole.isStaff() && !isViewOnly && <FloatingButton text='Send for Review' baseClassName='common-btn' onClick={e => handleSaveOrReviewBtnClick(e, true)} sx={{ mr: 1 }} />}
              {!isViewOnly && <FloatingButton text='Save' baseClassName='common-btn' onClick={e => handleSaveOrReviewBtnClick(e)} />}
            </Box>
          </CardActions>
        </Card>
      </Container>
      <DialogPrompt
        open={showBackClickDialogue}
        onCancel={() => setShowBackClickDialogue(false)}
        onConfirm={() => {
          setIsChangesMade(false)
          navigate('/templates')
        }}
        title='Clicking on the back, will discard your changes'
      />
      <PreviewDialogPrompt open={previewContent.isVisible} title={previewContent.title} description={previewContent.description} image={previewContent.image} onCancel={() => setPreviewContent({ ...previewContent, isVisible: false })} />
    </Layout>
  )
}

export default AddTemplate
