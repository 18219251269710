import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Container,
  Divider,
  IconButton,
  Typography,
  CardActions,
  Box,
  Chip,
  Avatar,
  FormHelperText,
} from "@mui/material";
import Layout from "../layout/layout";
import { ArrowBack } from "@mui/icons-material";
import { useContext, useEffect, useRef, useState } from "react";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import { useNavigate, useParams } from "react-router-dom";
import ENUMS from "../../constants/enum";
import {
  FloatingButton,
  MinimalMultipleSelect,
  MinimalSingleSelect,
  MinimalTimePicker,
} from "../common/form-control";
import { DialogPrompt, LanguageContentCard } from "../common/other-component";
import enFlagImg from "../../assets/images/en_flag.png";
import duFlagImg from "../../assets/images/du_flag.png";
import frFlagImg from "../../assets/images/fr_flag.png";
import geFlagImg from "../../assets/images/ge_flag.png";
import itFlagImg from "../../assets/images/it_flag.png";
import spFlagImg from "../../assets/images/sp_flag.png";
import moment from "moment";
import _ from "lodash";
import {
  getTemplateDetailById,
  templateDropdownList,
} from "../../services/template-service";
import * as yup from "yup";
import { validateSchema } from "../../helpers/schema-validation";
import {
  addBirthday,
  birthdayExist,
  getBirthdayDetailById,
} from "../../services/birthday-services";
import {
  imageUrlToBase64,
  isValidFileType,
  setMultipleSelectedValue,
} from "../../helpers/common-helper";
import getUserRole from "../../helpers/getuser-role";
import { convertTimePartWithTimezone } from "../../helpers/datetime-util";
import { SkandikaContextData } from "../../context";
import { Toast } from "../common/toast-utils";

const country_codes = [
  { label: "EN", value: "en", image_src: enFlagImg },
  // { label: 'DU', value: 'nl', image_src: duFlagImg },
  { label: "FR", value: "fr", image_src: frFlagImg },
  { label: "GE", value: "de", image_src: geFlagImg },
  // { label: 'IT', value: 'it', image_src: itFlagImg },
  // { label: 'SP', value: 'es', image_src: spFlagImg },
];
const defaultFormContent = [
  {
    //english
    language_code: "en",
    title: "",
    description: "",
    redirect_url: null,
    image: null,
    is_visible: false,
  },
  // {
  //   //dutch
  //   language_code: 'nl',
  //   title: '',
  //   description: '',
  //   redirect_url: null,
  //   image: null,
  //   is_visible: false,
  // },
  {
    //french
    language_code: "fr",
    title: "",
    description: "",
    redirect_url: null,
    image: null,
    is_visible: false,
  },
  {
    //german
    language_code: "de",
    title: "",
    description: "",
    redirect_url: null,
    image: null,
    is_visible: false,
  },
  // {
  //   //italian
  //   language_code: 'it',
  //   title: '',
  //   description: '',
  //   redirect_url: null,
  //   image: null,
  //   is_visible: false,
  // },
  // {
  //   //spanish
  //   language_code: 'es',
  //   title: '',
  //   description: '',
  //   redirect_url: null,
  //   image: null,
  //   is_visible: false,
  // },
];
function AddBirthday() {
  const checkUserRole = new getUserRole();
  const titleCharLimit = 60;
  const descriptionCharLimit = 400;
  const urlParams = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [regionAndLanguageDetail, setRegionAndLanguageDetail] = useState({
    regions: [],
    selected_language: [],
    template: null,
  });
  const [regionError, setRegionError] = useState({});
  const [pushNotificationDetail, setPushNotificationDetail] = useState({
    date: null,
    time: null,
    week: [],
    month: [],
  });
  const [pushNotificationError, setPushNotificationError] = useState({});
  const [formContent, setFormContent] = useState([...defaultFormContent]);
  const [deErrors, setDeErrors] = useState({});
  const [enErrors, setEnErrors] = useState({});
  const [itErrors, setItErrors] = useState({});
  const [frErrors, setFrErrors] = useState({});
  const [nlErrors, setNlErrors] = useState({});
  const [esErrors, setEsErrors] = useState({});
  const fileUploadInputRefs = {
    deInputRefs: useRef(),
    enInputRefs: useRef(),
    itInputRefs: useRef(),
    frInputRefs: useRef(),
    nlInputRefs: useRef(),
    esInputRefs: useRef(),
  };
  const [targetAudience, setTargetAudience] = useState(null);
  const [targetError, setTargetError] = useState({});
  const [notifyBeforeError, setNotifyBeforeError] = useState({});
  const [birthdayId, setBirthdayId] = useState(0);
  const [showBackClickDialogue, setShowBackClickDialogue] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const isViewOnly = window.location.pathname.includes("/view") ? true : false;
  const { refetchPendingCountData } = useContext(SkandikaContextData);
  const [notifyBeforeDetail, setNotifyBeforeDetail] = useState({
    notify_before: null,
    birthday_notification_time: null,
  });
  const [isRegionDisable, setIsRegionDisable] = useState(false);

  useEffect(() => {
    setIsChangesMade(false);
    setLoading(true);
    (async () => {
      try {
        if (!_.isEmpty(urlParams) && urlParams.id !== 0) {
          setLoading(true);
          const res = await getBirthdayDetailById(urlParams.id);
          if (res.success) {
            if (!_.isEmpty(res.data)) {
              const responseData = res.data;
              setBirthdayId(responseData.id);
              const selectedRegions = setMultipleSelectedValue(
                [...ENUMS.COUNTRY_LIST],
                responseData.region
              );
              setRegionAndLanguageDetail({
                regions: selectedRegions,
                repeat_mode: 6,
                selected_language: !_.isEmpty(responseData.contents)
                  ? responseData.contents.map((e) => e.language_code)
                  : [],
              });
              if (
                !_.isNaN(responseData.repeat_mode) &&
                responseData.repeat_mode === ENUMS.REPEATE_MODE.BIRTHDAY
              ) {
                setIsRegionDisable(true);
              }
              if (!_.isEmpty(responseData.contents)) {
                const contents = await Promise.all(
                  responseData.contents.map(async (item) => {
                    let imageBase64 = item.image;
                    if (!_.isEmpty(item.image)) {
                      imageBase64 = await imageUrlToBase64(item.image);
                    }
                    return {
                      ...item,
                      image: imageBase64,
                      is_visible: true,
                    };
                  })
                );
                let actualFormContent = defaultFormContent.map((item) => {
                  if (
                    contents.some((e) => e.language_code === item.language_code)
                  ) {
                    item = contents.find(
                      (e) => e.language_code === item.language_code
                    );
                  }
                  return item;
                });
                setFormContent(actualFormContent);
              }

              const notifyBeforeDay = ENUMS.NOTIFYBEFORE_DAY_LIST.find(
                (e) => e.value === responseData.notify_before
              );
              const todayDateTime = moment();
              const timezoneTimeString = convertTimePartWithTimezone(
                responseData.birthday_notification_time
              );
              const combinedDateTime = moment(
                `${todayDateTime.format("YYYY-MM-DD")} ${timezoneTimeString}`,
                "YYYY-MM-DD HH:mm"
              );
              setNotifyBeforeDetail({
                ...notifyBeforeDay,
                notify_before: notifyBeforeDay,
                birthday_notification_time: combinedDateTime,
              });

              if (!_.isEmpty(responseData.target_devices)) {
                const targetDevice = ENUMS.TARGET_AUDIENCE_LIST.find(
                  (e) => e.value === responseData.target_devices
                );
                setTargetAudience(targetDevice);
              }
            }
          } else {
            Toast.error(res.error_message);
          }
        } else {
          const res = await templateDropdownList();
          if (res.success) {
            let list_data = res.data
              ? res.data.map((e) => ({ label: e.name, value: e.id }))
              : [];
            list_data.unshift({ label: "Select Template", value: 0 });
            setTemplateList(list_data);
          } else {
            setTemplateList([]);
            Toast.error(res.error_message);
          }
        }
      } catch (error) {
        console.log(error);
        Toast.error(error);
        setTemplateList([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [urlParams]);

  const CardTitle = () => {
    return (
      <>
        <IconButton
          size="small"
          onClick={() => {
            if (isViewOnly) {
              setIsChangesMade(false);
              navigate("/birthday");
            } else if (isDataChanged) {
              setShowBackClickDialogue(true);
            } else {
              setIsChangesMade(false);
              navigate("/birthday");
            }
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography
          component="span"
          display="inline-flex"
          alignItems="center"
          fontWeight="600"
        >
          {!_.isEmpty(urlParams) && urlParams.id !== 0
            ? isViewOnly
              ? "View Birthday"
              : "Edit Birthday"
            : "Add Birthday"}
        </Typography>
      </>
    );
  };

  const handleFileSelect = (event) => {
    setIsChangesMade(true);
    const file = event.target.files[0];
    const fileInputLangCode = event.target.id.substring(
      0,
      event.target.id.indexOf(".")
    );
    const stateImagePropertyName = event.target.id.split(".").pop();
    if (file && isValidFileType(file)) {
      if (event.target.id) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (file.size > 2 * 1024 * 1024) {
            const error_message = {
              error: true,
              msg: ENUMS.COMMON_MSG.INVALID_IMAGE_SIZE,
            };
            handleLanguageContentError(
              fileInputLangCode,
              stateImagePropertyName,
              error_message
            );
            return;
          }

          const base64 = e.target.result;
          const img = new Image();
          img.src = base64;

          img.onload = () => {
            const width = img.width;
            const height = img.height;
            const aspectRatio = width / height;
            let error_message = null;
            if (Math.abs(aspectRatio - 16 / 9) > 0.01) {
              error_message = {
                error: true,
                msg: ENUMS.COMMON_MSG.INVALID_IMAGE_ASPECT_RATIO,
              };
            } else {
              const updatedData = formContent.map((formValue) => {
                if (formValue.language_code === fileInputLangCode) {
                  return {
                    ...formValue,
                    [stateImagePropertyName]: base64,
                  };
                } else return formValue;
              });
              setFormContent(updatedData);
            }
            handleLanguageContentError(
              fileInputLangCode,
              stateImagePropertyName,
              error_message
            );
          };
        };
        reader.readAsDataURL(file);
        event.target.value = null;
      }
    } else if (file && !isValidFileType(file)) {
      const error_message = {
        error: true,
        msg: ENUMS.COMMON_MSG.INVALID_IMAGE_FILE_FORMAT,
      };
      handleLanguageContentError(
        fileInputLangCode,
        stateImagePropertyName,
        error_message
      );
    }
  };

  const handleLanguageContentError = (
    language_code,
    property_name,
    error_message = null
  ) => {
    if (language_code === "de") {
      setDeErrors({ ...deErrors, [property_name]: error_message });
    } else if (language_code === "en") {
      setEnErrors({ ...enErrors, [property_name]: error_message });
    } else if (language_code === "it") {
      setItErrors({ ...itErrors, [property_name]: error_message });
    } else if (language_code === "fr") {
      setFrErrors({ ...frErrors, [property_name]: error_message });
    } else if (language_code === "nl") {
      setNlErrors({ ...nlErrors, [property_name]: error_message });
    } else if (language_code === "es") {
      setEsErrors({ ...esErrors, [property_name]: error_message });
    }
  };
  const clearImage = (language_code) => {
    setIsChangesMade(true);
    const updatedData = formContent.map((formValue) => {
      if (formValue.language_code === language_code) {
        return {
          ...formValue,
          image: null,
        };
      } else return formValue;
    });
    setFormContent(updatedData);
  };

  const handleContentSelect = (language_code) => {
    setIsChangesMade(true);
    const updatedData = formContent.map((formValue) => {
      if (formValue.language_code === language_code) {
        return {
          ...formValue,
          is_visible: !formValue.is_visible,
        };
      } else return formValue;
    });

    setFormContent(updatedData);
    const selectedLanguage = updatedData
      .filter((e) => e.is_visible === true)
      .map((e) => e.language_code);
    setRegionError({
      ...regionError,
      selected_language: { error: false, msg: "" },
    });
    setRegionAndLanguageDetail({
      ...regionAndLanguageDetail,
      selected_language: selectedLanguage,
    });
  };

  const handleRegionAndLanguageInputChange = (name, opt) => {
    setIsChangesMade(true);
    setRegionAndLanguageDetail({ ...regionAndLanguageDetail, [name]: opt });
    if (name === "repeat_mode") {
      const resetData = {
        date: null,
        time: null,
        week: [],
        month: [],
      };
      setPushNotificationDetail({ ...pushNotificationDetail, ...resetData });
      setPushNotificationError(true);
      //select all regions and make field disable
      const selectedRegions = [...ENUMS.COUNTRY_LIST];
      selectedRegions.unshift(ENUMS.SELECTALL_OPTION);
      setRegionAndLanguageDetail({
        ...regionAndLanguageDetail,
        regions: selectedRegions,
        [name]: opt,
      });
      setIsRegionDisable(true);

      const resetNotifyBefore = {
        notify_before: null,
        birthday_notification_time: null,
      };
      setNotifyBeforeDetail({ ...notifyBeforeDetail, resetNotifyBefore });
      setNotifyBeforeError(true);
    }
    if (name === "template") {
      handleDetailOnTemplateChange(opt.value);
      setDeErrors(true);
      setNlErrors(true);
      setFrErrors(true);
      setItErrors(true);
      setEnErrors(true);
      setEsErrors(true);
    }
  };

  const handleInputChange = (e, language_code) => {
    setIsChangesMade(true);
    const { name, value } = e.target;
    const updatedData = formContent.map((formValue) => {
      if (
        formValue.language_code === language_code &&
        ((name !== "title" && name !== "description") ||
          (name === "title" && value.length <= titleCharLimit) ||
          (name === "description" && value.length <= descriptionCharLimit))
      ) {
        return {
          ...formValue,
          [name]: value,
        };
      } else return formValue;
    });

    setFormContent(updatedData);
  };

  const handleInputFocus = (e, language_code) => {
    const { name } = e.target;
    const error_message = { error: false, msg: "" };
    handleLanguageContentError(language_code, name, error_message);
  };

  const handleDetailOnTemplateChange = async (id) => {
    if (id !== 0) {
      setLoading(true);
      try {
        const res = await getTemplateDetailById(id);
        if (res.success) {
          if (res.data && res.data.contents.length) {
            const items = await Promise.all(
              formContent.map(async (formValue) => {
                const languageContentDetail = res.data.contents.find(
                  (e) => e.language_code === formValue.language_code
                );
                if (!_.isEmpty(languageContentDetail)) {
                  let imageBase64 = languageContentDetail.image;
                  if (!_.isEmpty(languageContentDetail.image)) {
                    imageBase64 = await imageUrlToBase64(
                      languageContentDetail.image
                    );
                  }
                  return {
                    title: languageContentDetail.title,
                    description: languageContentDetail.description,
                    redirect_url: languageContentDetail.redirect_url,
                    language_code: languageContentDetail.language_code,
                    image: imageBase64,
                    is_visible: formValue.is_visible,
                  };
                } else return formValue;
              })
            );
            setFormContent(items);
          } else if (res.data && res.data.contents.length === 0) {
            //set default form content
            const updatedData = defaultFormContent.map((formValue) => {
              const languageContentDetail = formContent.find(
                (e) => e.language_code === formValue.language_code
              );
              if (!_.isEmpty(languageContentDetail)) {
                return {
                  ...formValue,
                  is_visible: languageContentDetail.is_visible,
                };
              } else return formValue;
            });

            setFormContent(updatedData);
          }
        } else {
          Toast.error(res.error_message);
        }
      } catch (error) {
        Toast.error(error);
        console.log(error);
      }
      setLoading(false);
    } else {
      const updatedData = defaultFormContent.map((formValue) => {
        const languageContentDetail = formContent.find(
          (e) => e.language_code === formValue.language_code
        );
        if (!_.isEmpty(languageContentDetail)) {
          return {
            ...formValue,
            is_visible: languageContentDetail.is_visible,
          };
        } else return formValue;
      });

      setFormContent(updatedData);
    }
  };

  const handleTargetChange = (opt) => {
    setIsChangesMade(true);
    setTargetAudience(opt);
  };

  const handleNotifyBeforeChange = (name, value) => {
    setIsChangesMade(true);
    if (name === "birthday_notification_time") {
      setNotifyBeforeError({
        ...notifyBeforeError,
        [name]: { error: false, msg: "" },
      });
    }
    setNotifyBeforeDetail({ ...notifyBeforeDetail, [name]: value });
  };

  const handleSaveOrReviewBtnClick = async (e, is_review = false) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      let contentList = formContent
        .filter((e) => e.is_visible === true)
        .map((element) => {
          if (!_.isEmpty(element.title)) {
            element.title = element.title.trim();
          }
          if (!_.isEmpty(element.description)) {
            element.description = element.description.trim();
          }
          if (!_.isEmpty(element.redirect_url)) {
            element.redirect_url = element.redirect_url.trim();
          }
          return element;
        });

      let payload = {
        region: regionAndLanguageDetail.regions.length
          ? regionAndLanguageDetail.regions
              .filter((e) => e.value !== "*")
              .map((e) => e.value)
          : [],
        repeat_mode: 6,
        target_devices: targetAudience?.value,
        contents: [...contentList],
        review: is_review,
      };

      payload.notify_before = notifyBeforeDetail.notify_before?.value;
      payload.birthday_notification_time = !_.isEmpty(
        notifyBeforeDetail.birthday_notification_time
      )
        ? moment(notifyBeforeDetail.birthday_notification_time).format("HH:mm")
        : null;
      payload.is_birthday = true;
      if (birthdayId !== 0) {
        payload.id = birthdayId;
      }
      setLoading(true);
      try {
        const res = await addBirthday(payload);
        if (res.success) {
          if (
            checkUserRole.isAdmin() ||
            (checkUserRole.isStaff() && is_review === true)
          ) {
            setIsChangesMade(false);
            navigate("/birthday");
            await refetchPendingCountData();
          } else if (
            checkUserRole.isStaff() &&
            is_review === false &&
            res.data.data
          ) {
            setIsChangesMade(false);
            setBirthdayId(res.data.data.id);
          }
          Toast.success(res.data.detail);
        } else {
          Toast.error(res.error_message);
        }
      } catch (error) {
        Toast.error(error);
        console.log(error);
      }
      setLoading(false);
    }
  };

  const validateForm = async () => {
    //region block validation
    const region_schema = yup.object().shape({
      regions: yup.array().min(1, ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      selected_language: yup
        .array()
        .min(1, ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
    });
    const isRegionValid = await validateSchema(
      regionAndLanguageDetail,
      region_schema
    );
    setRegionError(isRegionValid);

    let isLanguageContentInValid = false;
    if (formContent.length) {
      //content block validation
      formContent.forEach(async (item) => {
        if (item.is_visible) {
          const schema = yup.object().shape({
            title: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
            description: yup
              .string()
              .required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
            redirect_url: yup
              .string()
              .test("valid-url", ENUMS.COMMON_MSG.INVALID_URL, (value) => {
                if (!value) return true; // Allow empty input
                return /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value);
              })
              .nullable(true),
          });
          const isContentValid = await validateSchema(item, schema);
          if (isContentValid !== true) {
            isLanguageContentInValid = true;
          }
          if (item.language_code === "de") setDeErrors(isContentValid);
          if (item.language_code === "en") setEnErrors(isContentValid);
          if (item.language_code === "nl") setNlErrors(isContentValid);
          if (item.language_code === "it") setItErrors(isContentValid);
          if (item.language_code === "fr") setFrErrors(isContentValid);
          if (item.language_code === "es") setEsErrors(isContentValid);
        }
      });
    }

    //target block validation
    const target_schema = yup.object().shape({
      target_audience: yup
        .object()
        .required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
    });
    const isTargetValid = await validateSchema(
      { target_audience: targetAudience },
      target_schema
    );
    setTargetError(isTargetValid);

    let isNotifyBeforeValid = false;
    const notifybefore_schema = yup.object().shape({
      notify_before: yup
        .object()
        .required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      birthday_notification_time: yup
        .date()
        .required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
    });
    isNotifyBeforeValid = await validateSchema(
      notifyBeforeDetail,
      notifybefore_schema
    );
    setNotifyBeforeError(isNotifyBeforeValid);

    //if all block valid return true
    if (
      isRegionValid === true &&
      isLanguageContentInValid === false &&
      isTargetValid === true &&
      isNotifyBeforeValid === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setIsChangesMade = (isChangesMade) => {
    if (isViewOnly === false) {
      setIsDataChanged(isChangesMade);
      if (isChangesMade === false && localStorage.key("IsChangesMade").length) {
        localStorage.removeItem("IsChangesMade");
      } else {
        localStorage.setItem("IsChangesMade", isChangesMade);
      }
    } else {
      if (localStorage.key("IsChangesMade").length) {
        localStorage.removeItem("IsChangesMade");
      }
    }
  };

  useEffect(() => {
    const focusOnFirstError = (errorFields, language_code = null) => {
      const firstErrorField = Object.keys(errorFields).find(
        (key) => errorFields[key] !== ""
      );

      if (firstErrorField) {
        let input_id = "";
        if (!_.isEmpty(language_code)) {
          input_id = language_code + "." + firstErrorField;
        } else {
          input_id = firstErrorField;
        }
        const firstErrorElement = document.getElementById(input_id);
        setErrorTextFocus(firstErrorElement);
      }
    };
    if (!_.isEmpty(regionError)) {
      focusOnFirstError(regionError);
    } else if (!_.isEmpty(pushNotificationError)) {
      focusOnFirstError(pushNotificationError);
    } else if (!_.isEmpty(deErrors)) {
      focusOnFirstError(deErrors, "de");
    } else if (!_.isEmpty(enErrors)) {
      focusOnFirstError(enErrors, "en");
    } else if (!_.isEmpty(frErrors)) {
      focusOnFirstError(frErrors, "fr");
    } else if (!_.isEmpty(targetError)) {
      focusOnFirstError(targetError);
    } else if (!_.isEmpty(notifyBeforeError)) {
      focusOnFirstError(notifyBeforeError);
    }
  }, [
    regionError,
    pushNotificationError,
    deErrors,
    enErrors,
    frErrors,
    targetError,
    notifyBeforeError,
  ]);

  const setErrorTextFocus = (input_element) => {
    if (input_element && input_element.closest(".reactselect-dropdown-block")) {
      //for dropdown control
      const selectElement = input_element.closest(
        ".reactselect-dropdown-block"
      );
      const errorElement = selectElement.querySelector(".Mui-error");
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } else if (
      input_element &&
      input_element.classList.contains("language-chips-block") &&
      input_element.nextElementSibling.classList.contains("Mui-error")
    ) {
      //for language chip control
      const errorElement = input_element.nextElementSibling;
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } else if (
      input_element &&
      input_element.classList.contains("datetime-pickers") &&
      input_element.nextElementSibling.classList.contains("Mui-error")
    ) {
      //for datetime related controls
      const errorElement = input_element.nextElementSibling;
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } else if (
      input_element &&
      input_element.parentElement.nextElementSibling &&
      input_element.parentElement.nextElementSibling.classList.contains(
        "Mui-error"
      )
    ) {
      const errorElement = input_element.parentElement.nextElementSibling;
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };
  return (
    <Layout>
      <RingSpinnerOverlay
        loading={loading}
        overlayColor="rgba(0,0,0,0.2)"
        size={50}
        color="#2d38384d"
        borderWidth={3}
      />
      <Container component="div" maxWidth="xl" className="plr-0">
        <Card component="div" sx={ENUMS.COMMON_STYLE.cardStyle}>
          <CardHeader title={<CardTitle />} className="template-cardheader" />
          <CardContent className="template-card-content-scroll theme-scroll-bar">
            <Card
              component="div"
              sx={ENUMS.COMMON_STYLE.innerCardStyle}
              style={{ overflow: "visible" }}
            >
              <CardHeader title="Regions" className="template-sub-cardheader" />
              <CardContent>
                <Grid container spacing={2} display={"flex"}>
                  <Grid item xs={4} className="reactselect-dropdown-block">
                    <MinimalMultipleSelect
                      placeholder="Select Region"
                      value={regionAndLanguageDetail.regions}
                      options={[...ENUMS.COUNTRY_LIST]}
                      onInputChange={(e) =>
                        handleRegionAndLanguageInputChange("regions", e)
                      }
                      onInputFocus={() => {
                        setRegionError({
                          ...regionError,
                          regions: { error: false, msg: "" },
                        });
                      }}
                      error={regionError.regions && regionError.regions.error}
                      helperText={
                        regionError.regions && regionError.regions.msg
                      }
                      id="regions"
                      disabled={isViewOnly || isRegionDisable}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              component="div"
              sx={ENUMS.COMMON_STYLE.innerCardStyle}
              style={{ overflow: "visible" }}
            >
              <CardHeader
                title="Languages"
                className="template-sub-cardheader"
              />
              <CardContent>
                <Grid container spacing={2} display={"flex"}>
                  <Grid item xs={8}>
                    <div
                      style={{ paddingTop: "10px" }}
                      id="selected_language"
                      className="language-chips-block"
                    >
                      {country_codes.length &&
                        country_codes.map((item, key) => {
                          const activeClass = formContent.some(
                            (e) =>
                              e.is_visible && e.language_code === item.value
                          )
                            ? " active"
                            : "";
                          const disableClass =
                            isViewOnly &&
                            formContent.some(
                              (e) =>
                                e.is_visible && e.language_code !== item.value
                            )
                              ? "disabled"
                              : "";
                          return (
                            <Chip
                              avatar={
                                <Avatar alt={item.value} src={item.image_src} />
                              }
                              label={item.label}
                              variant="outlined"
                              className={`flag-chip ${activeClass}`}
                              sx={{ mr: 1 }}
                              key={key}
                              onClick={() => handleContentSelect(item.value)}
                              disabled={isViewOnly}
                            />
                          );
                        })}
                    </div>
                    {country_codes.length && (
                      <FormHelperText
                        error={
                          regionError.selected_language &&
                          regionError.selected_language.error
                        }
                        sx={{ marginLeft: "14px" }}
                      >
                        {regionError.selected_language &&
                          regionError.selected_language.msg}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {(_.isEmpty(urlParams) || urlParams.id === 0) && (
              <Card
                component="div"
                sx={ENUMS.COMMON_STYLE.innerCardStyle}
                style={{ overflow: "visible" }}
              >
                <CardHeader
                  title="Templates"
                  className="template-sub-cardheader"
                />
                <CardContent>
                  <Grid container spacing={2} display={"flex"}>
                    <Grid item xs={4}>
                      {(_.isEmpty(urlParams) || urlParams.id === 0) && (
                        <MinimalSingleSelect
                          placeholder="Select Template"
                          value={regionAndLanguageDetail.template}
                          options={templateList}
                          onInputChange={(e) =>
                            handleRegionAndLanguageInputChange("template", e)
                          }
                          onInputFocus={() => {
                            setRegionError({
                              ...regionError,
                              template: { error: false, msg: "" },
                            });
                          }}
                          id="template"
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            {formContent.some((item) => item.is_visible === true) && (
              <Card sx={ENUMS.COMMON_STYLE.innerCardStyle}>
                <CardHeader
                  title="Content"
                  className="template-sub-cardheader"
                />
                <CardContent>
                  {formContent.map((item, key) => {
                    if (item.language_code === "de" && item.is_visible)
                      return (
                        <LanguageContentCard
                          title="Content for German"
                          errors={deErrors}
                          id_initials="de"
                          file_input_ref={fileUploadInputRefs.deInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() =>
                            clearImage(item.language_code)
                          }
                          onInputChange={(e) =>
                            handleInputChange(e, item.language_code)
                          }
                          onHandleFocus={(e) =>
                            handleInputFocus(e, item.language_code)
                          }
                          key={key}
                          disabled={isViewOnly}
                        />
                      );
                    if (item.language_code === "en" && item.is_visible)
                      return (
                        <LanguageContentCard
                          title="Content for English"
                          errors={enErrors}
                          id_initials="en"
                          file_input_ref={fileUploadInputRefs.enInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() =>
                            clearImage(item.language_code)
                          }
                          onInputChange={(e) =>
                            handleInputChange(e, item.language_code)
                          }
                          onHandleFocus={(e) =>
                            handleInputFocus(e, item.language_code)
                          }
                          key={key}
                          disabled={isViewOnly}
                        />
                      );
                    if (item.language_code === "it" && item.is_visible)
                      return (
                        <LanguageContentCard
                          title="Content for Italian"
                          errors={itErrors}
                          id_initials="it"
                          file_input_ref={fileUploadInputRefs.itInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() =>
                            clearImage(item.language_code)
                          }
                          onInputChange={(e) =>
                            handleInputChange(e, item.language_code)
                          }
                          onHandleFocus={(e) =>
                            handleInputFocus(e, item.language_code)
                          }
                          key={key}
                        />
                      );
                    if (item.language_code === "fr" && item.is_visible)
                      return (
                        <LanguageContentCard
                          title="Content for French"
                          errors={frErrors}
                          id_initials="fr"
                          file_input_ref={fileUploadInputRefs.frInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() =>
                            clearImage(item.language_code)
                          }
                          onInputChange={(e) =>
                            handleInputChange(e, item.language_code)
                          }
                          onHandleFocus={(e) =>
                            handleInputFocus(e, item.language_code)
                          }
                          key={key}
                          disabled={isViewOnly}
                        />
                      );
                    if (item.language_code === "nl" && item.is_visible)
                      return (
                        <LanguageContentCard
                          title="Content for French"
                          errors={nlErrors}
                          id_initials="nl"
                          file_input_ref={fileUploadInputRefs.nlInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() =>
                            clearImage(item.language_code)
                          }
                          onInputChange={(e) =>
                            handleInputChange(e, item.language_code)
                          }
                          onHandleFocus={(e) =>
                            handleInputFocus(e, item.language_code)
                          }
                          key={key}
                        />
                      );
                    if (item.language_code === "es" && item.is_visible)
                      return (
                        <LanguageContentCard
                          title="Content for Spanish"
                          errors={nlErrors}
                          id_initials="es"
                          file_input_ref={fileUploadInputRefs.esInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() =>
                            clearImage(item.language_code)
                          }
                          onInputChange={(e) =>
                            handleInputChange(e, item.language_code)
                          }
                          onHandleFocus={(e) =>
                            handleInputFocus(e, item.language_code)
                          }
                          key={key}
                        />
                      );
                  })}
                </CardContent>
              </Card>
            )}
            <Card
              component="div"
              sx={ENUMS.COMMON_STYLE.innerCardStyle}
              style={{ overflow: "visible" }}
            >
              <CardHeader title="Target" className="template-sub-cardheader" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="reactselect-dropdown-block">
                    <MinimalSingleSelect
                      placeholder="Select Target Audience"
                      value={targetAudience}
                      options={ENUMS.TARGET_AUDIENCE_LIST}
                      onInputChange={handleTargetChange}
                      error={
                        targetError.target_audience &&
                        targetError.target_audience.error
                      }
                      helperText={
                        targetError.target_audience &&
                        targetError.target_audience.msg
                      }
                      onInputFocus={() => {
                        setTargetError({
                          ...targetError,
                          target_audience: { error: false, msg: "" },
                        });
                      }}
                      info="The target audience comprises the device platforms, such as iOS and Android, for which the birthday is intended."
                      id="target_audience"
                      disabled={isViewOnly}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              component="div"
              sx={ENUMS.COMMON_STYLE.innerCardStyle}
              style={{ overflow: "visible" }}
            >
              <CardHeader
                title="Notify Before"
                className="template-sub-cardheader"
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} className="reactselect-dropdown-block">
                    <MinimalSingleSelect
                      placeholder="Select Day"
                      value={notifyBeforeDetail.notify_before}
                      options={ENUMS.NOTIFYBEFORE_DAY_LIST}
                      onInputChange={(e) =>
                        handleNotifyBeforeChange("notify_before", e)
                      }
                      error={
                        notifyBeforeError.notify_before &&
                        notifyBeforeError.notify_before.error
                      }
                      helperText={
                        notifyBeforeError.notify_before &&
                        notifyBeforeError.notify_before.msg
                      }
                      onInputFocus={() => {
                        setNotifyBeforeError({
                          ...notifyBeforeError,
                          notify_before: { error: false, msg: "" },
                        });
                      }}
                      id="notify_before"
                      disabled={isViewOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MinimalTimePicker
                      value={notifyBeforeDetail.birthday_notification_time}
                      onInputChange={(e) =>
                        handleNotifyBeforeChange(
                          "birthday_notification_time",
                          e
                        )
                      }
                      placeholder="Notification Time"
                      error={
                        notifyBeforeError.birthday_notification_time &&
                        notifyBeforeError.birthday_notification_time.error
                      }
                      helperText={
                        notifyBeforeError.birthday_notification_time &&
                        notifyBeforeError.birthday_notification_time.msg
                      }
                      onInputFocus={() => {
                        setNotifyBeforeError({
                          ...notifyBeforeError,
                          birthday_notification_time: {
                            error: false,
                            msg: "",
                          },
                        });
                      }}
                      label="Notification Time"
                      id="birthday_notification_time"
                      disabled={isViewOnly}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </CardContent>
          <Divider sx={ENUMS.COMMON_STYLE.dividerStyle} />
          <CardActions
            className="template-cardfooter"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box>
              {checkUserRole.isStaff() && !isViewOnly && (
                <FloatingButton
                  text="Send for Review"
                  baseClassName="common-btn"
                  onClick={(e) => handleSaveOrReviewBtnClick(e, true)}
                  sx={{ mr: 1 }}
                />
              )}
              {!isViewOnly && (
                <FloatingButton
                  text="Save"
                  baseClassName="common-btn"
                  onClick={(e) => handleSaveOrReviewBtnClick(e)}
                />
              )}
            </Box>
          </CardActions>
        </Card>
      </Container>
      <DialogPrompt
        open={showBackClickDialogue}
        onCancel={() => setShowBackClickDialogue(false)}
        onConfirm={() => {
          setIsChangesMade(false);
          navigate("/birthday");
        }}
        title="Clicking on the back, will discard your changes"
      />
    </Layout>
  );
}

export default AddBirthday;
