import post, { deleteAction, get } from './base-service'
import API_HELPER from '../constants/api-urls'
import _ from 'lodash'

export const annoucementListData = async requestData => {
  try {
    let url = `${API_HELPER.URL.advertisementFeature}?page_size=${requestData.page_size}&page=${requestData.page_index}`
    if (!_.isEmpty(requestData.search_string)) {
      url += '&search=' + requestData.search_string
    }
    if (!_.isEmpty(requestData.start_date)) {
      url += '&start_date=' + requestData.start_date
    }
    if (!_.isEmpty(requestData.end_date)) {
      url += '&end_date=' + requestData.end_date
    }
    if (!_.isEmpty(requestData.teaser_date)) {
      url += '&teaser_date=' + requestData.teaser_date
    }
    if (!_.isEmpty(requestData.target_audience)) {
      url += '&target_devices=' + requestData.target_audience
    }
    if (!_.isEmpty(requestData.status)) {
      url += '&status=' + requestData.status
    }
    if (!_.isEmpty(requestData.sorting)) {
      url += '&ordering=' + requestData.sorting
    }
    const response = await get(url)
    return response
  } catch (error) {
    console.log('annoucementListData service catch-----------------', error)
    return error
  }
}

export const addAnnouncement = async requestData => {
  try {
    const response = await post(`${API_HELPER.URL.advertisementFeature}`, requestData)
    return response
  } catch (error) {
    console.log('addAnnouncement service catch-----------------', error)
    return error
  }
}

export const announcementAction = async (id, requestData) => {
  try {
    const response = await post(`${API_HELPER.URL.advertisementFeature}${id}/action/`, requestData)
    return response
  } catch (error) {
    console.log('announcementAction service catch-----------------', error)
    return error
  }
}

export const getAnnouncementDetailById = async id => {
  try {
    const response = await get(`${API_HELPER.URL.advertisementFeature}${id}/`)
    return response
  } catch (error) {
    console.log('getAnnouncementDetailById service catch-----------------', error)
    return error
  }
}

export const deleteAnnouncement = async id => {
  try {
    const response = await deleteAction(`${API_HELPER.URL.advertisementFeature}${id}/`)
    return response
  } catch (error) {
    console.log('deleteAnnouncement service catch-----------------', error)
    return error
  }
}

export const getPendingCount = async () => {
  try {
    const response = await get(`${API_HELPER.URL.pendingCounts}`)
    return response
  } catch (error) {
    console.log('getPendingCount service catch-----------------', error)
    return error
  }
}

export const birthdayExist = async () => {
  try {
    const response = await get(`${API_HELPER.URL.birthdayExist}`)
    return response
  } catch (error) {
    console.log('birthdayExist service catch-----------------', error)
    return error
  }
}