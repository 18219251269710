import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { Card, CardContent, CardMedia, Container, Grid, Link, CssBaseline, InputAdornment, IconButton } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { validateSchema } from '../../helpers/schema-validation'
import { resetPassword, setPassword, userInviteValidity } from '../../services/auth-services'
import ENUMS from '../../constants/enum'
import { Link as RouterLink } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import { FloatingButton, MinimalTextField } from '../common/form-control'
import { isEmpty } from 'lodash'
import { Toast } from '../common/toast-utils'

export default function ResetPassword() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const urlParams = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState({
    email: { error: false, msg: '' },
    password: { error: false, msg: '' },
  })
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword)

  useEffect(() => {
    async function userValidityOnInviteSetPassword() {
      try {
        if (searchParams.get('is_setpassword') != null && searchParams.get('is_setpassword') === '1' && urlParams !== null && urlParams.tokenparam !== null) {
          const res = await userInviteValidity(urlParams.tokenparam, 'accept')
          if (res.success && res.data && res.data.detail === 'ok') {
            //do nothing
          } else if (res.success && res.data && res.data.login) {
            Toast.error(res.data.detail)
            //already accepted invite
            navigate('/', { replace: true })
          } else if (res.status && res.status === false && res.error_message) {
            navigate('/', { replace: true })
            Toast.error(res.error_message)
          } else {
            Toast.error(res.error_message)
          }
        }
      } catch (error) {
        console.log(error)
        Toast.error(error)
      }
    }
    userValidityOnInviteSetPassword()
  }, [urlParams, searchParams])

  const checkPasswordValid = async payload => {
    if (isEmpty(payload.confirmPassword)) {
      delete payload.confirmPassword
    }
    const schema = yup.object().shape({
      password: yup.string().matches(/^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, ENUMS.COMMON_MSG.INVALID_PASSWORD),
      confirmPassword: yup.string().oneOf([yup.ref('password'), null], ENUMS.COMMON_MSG.PASSWORD_NOTMATCH),
    })
    const isValid = await validateSchema(payload, schema)
    return isValid
  }

  const handlePasswordChange = async value => {
    setFormValues({ ...formValues, password: value })
    const payload = { ...formValues, password: value }
    const isValid = await checkPasswordValid(payload)
    setErrors(isValid)
    if (formValues.confirmPassword !== '' && value !== '' && isValid === true) {
      setEnableSubmitBtn(true)
    } else {
      setEnableSubmitBtn(false)
    }
  }

  const handleConfirmPasswordChange = async value => {
    setFormValues({ ...formValues, confirmPassword: value })
    const payload = { ...formValues, confirmPassword: value }
    const isValid = await checkPasswordValid(payload)
    setErrors(isValid)
    if (formValues.password !== '' && value !== '' && isValid === true) {
      setEnableSubmitBtn(true)
    } else {
      setEnableSubmitBtn(false)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    let newFormValues = { ...formValues }
    if (newFormValues.password !== '' && newFormValues.confirmPassword !== '') {
      setFormValues(newFormValues)
      setLoading(true)
      if (state !== null && state.resetpassword_token !== null) {
        const request = {
          new_password1: newFormValues.password,
          new_password2: newFormValues.confirmPassword,
        }
        resetPasswordConfirm(request)
      } else if (searchParams.get('is_setpassword') != null && searchParams.get('is_setpassword') === '1' && urlParams !== null && urlParams.tokenparam !== null) {
        const request = {
          password1: newFormValues.password,
          password2: newFormValues.confirmPassword,
          user_id: urlParams.tokenparam,
        }
        setPasswordConfirm(request)
      } else {
        Toast.error(ENUMS.COMMON_MSG.INVALID_TOKEN)
      }
    } else {
      Toast.error(ENUMS.COMMON_MSG.INVALID_TOKEN)
    }
  }

  const resetPasswordConfirm = async request => {
    try {
      const res = await resetPassword(request, state.resetpassword_token)
      if (res.success) {
        setFormValues({ password: '', confirmPassword: '' })
        setEnableSubmitBtn(false)
        Toast.success(res.data.detail)
        navigate('/')
      } else {
        Toast.error(res.error_message)
      }
    } catch (error) {
      Toast.error(error)
      console.log(error)
    }
    setLoading(false)
  }

  const setPasswordConfirm = async request => {
    try {
      const res = await setPassword(request)
      if (res.success) {
        setFormValues({ password: '', confirmPassword: '' })
        setEnableSubmitBtn(false)
        Toast.success(res.data.detail)
        navigate('/')
      } else {
        Toast.error(res.error_message)
      }
    } catch (error) {
      Toast.error(error)
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <div className='auth-section'>
      <Container component='main' maxWidth='sm'>
        <CssBaseline />
        <Card component='form' onSubmit={handleSubmit} noValidate className='auth-cardview'>
          <CardMedia className='auth-cardheader'>
            <img src={logo} alt='Skandika' onClick={() => window.location.reload()} />
          </CardMedia>
          <CardContent sx={{ width: '100%', padding: 4 }}>
            <MinimalTextField
              placeholder='New Password'
              error={errors.password && errors.password.error}
              helperText={errors.password && errors.password.msg}
              value={formValues.password}
              onChange={e => handlePasswordChange(e.target.value)}
              onFocus={() => {
                setErrors({ ...errors, password: { error: false, msg: '' } })
              }}
              isRequired={true}
              type={showPassword ? 'text' : 'password'}
              endAdornmentProp={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} sx={{ color: '#2D38384D' }}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <MinimalTextField
              placeholder='Confirm Password'
              error={errors.confirmPassword && errors.confirmPassword.error}
              helperText={errors.confirmPassword && errors.confirmPassword.msg}
              value={formValues.confirmPassword}
              onChange={e => handleConfirmPasswordChange(e.target.value)}
              onFocus={() => {
                setErrors({ ...errors, confirmPassword: { error: false, msg: '' } })
              }}
              isRequired={true}
              type={showConfirmPassword ? 'text' : 'password'}
              endAdornmentProp={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={handleClickShowConfirmPassword} sx={{ color: '#2D38384D' }}>
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FloatingButton type='submit' baseClassName='common-btn' disabled={!enableSubmitBtn || loading} text='Submit' onClick={() => {}} loading={loading} sx={{ mt: 3, mb: 2 }} />
            <Grid container>
              <Grid item xs>
                <Link variant='body2' underline='none' className='common-link' component={RouterLink} to='/'>
                  Sign In
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
