import { Card, CardContent, CardHeader } from "@mui/material";
import { MinimalTextArea, MinimalTextField } from "../common/form-control";
import { get } from "lodash";
import ENUMS from "../../constants/enum";
import { CharacterCounter } from "../common/other-component";

export const VTPLanguageContentCard = ({
  language,
  shortLanguage,
  vtpContent,
  handleInputChange,
  fieldErrors,
  setFieldErrors,
  textfieldRefs,
  textareaRefs,
  titleCharLimit,
  descriptionCharLimit,
  isViewOnly,
}) => {
  return (
    <Card component="div" sx={ENUMS.COMMON_STYLE.innerCardStyle}>
      <CardHeader
        title={`Content for ${language}`}
        className="template-sub-cardheader"
      />
      <CardContent>
        <MinimalTextField
          value={get(vtpContent, `name.${shortLanguage}.name`)}
          onChange={(e) =>
            handleInputChange(
              "name",
              `name.${shortLanguage}.name`,
              e.target.value
            )
          }
          onBlur={(e) => {
            handleInputChange(
              "name",
              `name.${shortLanguage}.name`,
              e.target.value?.trim()
            );
          }}
          placeholder="Program Title"
          isRequired={true}
          error={
            fieldErrors[`name.${shortLanguage}.name`] &&
            fieldErrors[`name.${shortLanguage}.name`].error
          }
          helperText={
            fieldErrors[`name.${shortLanguage}.name`] &&
            fieldErrors[`name.${shortLanguage}.name`].msg
          }
          onFocus={() => {
            setFieldErrors({
              ...fieldErrors,
              [`name.${shortLanguage}.name`]: { error: false, msg: "" },
            });
          }}
          id={`${shortLanguage}.name`}
          InputRef={textfieldRefs[`${shortLanguage}Title`]}
          endAdornmentProp={
            <CharacterCounter
              text={get(vtpContent, `name.${shortLanguage}.name`, "")}
              limit={titleCharLimit}
            />
          }
          sx={{ marginTop: 0 }}
          disabled={isViewOnly}
        />
        <MinimalTextArea
          value={get(
            vtpContent,
            `description.${shortLanguage}.description`,
            ""
          )}
          onChange={(e) =>
            handleInputChange(
              "description",
              `description.${shortLanguage}.description`,
              e.target.value
            )
          }
          onBlur={(e) => {
            handleInputChange(
              "description",
              `description.${shortLanguage}.description`,
              e.target.value?.trim()
            );
          }}
          placeholder="Program Introduction"
          isRequired={true}
          error={
            fieldErrors[`description.${shortLanguage}.description`] &&
            fieldErrors[`description.${shortLanguage}.description`].error
          }
          helperText={
            fieldErrors[`description.${shortLanguage}.description`] &&
            fieldErrors[`description.${shortLanguage}.description`].msg
          }
          onFocus={() => {
            setFieldErrors({
              ...fieldErrors,
              [`description.${shortLanguage}.description`]: {
                error: false,
                msg: "",
              },
            });
          }}
          id={`${shortLanguage}.description`}
          InputRef={textareaRefs[`${shortLanguage}Description`]}
          endAdornmentProp={
            <CharacterCounter
              text={get(
                vtpContent,
                `description.${shortLanguage}.description`,
                ""
              )}
              limit={descriptionCharLimit}
            />
          }
          disabled={isViewOnly}
        />
      </CardContent>
    </Card>
  );
};
