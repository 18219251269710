import moment from "moment";

const requiredKeys = ["date", "lat", "long", "speed", "altitude", "distance"];

function ConvertDMStoDecimal(coordStr) {
  try {
    // Split the coordinate string into components
    const parts = coordStr.split(" ");
    const deg = parseInt(parts[0]);
    const minStr = parts[2];
    const secStr = parts[3].slice(0, -1); // Remove the trailing "
    const direction = parts[4];

    // Extract numerical values
    const minVal = parseInt(minStr.slice(0, -1)); // Remove the trailing '
    const secVal = parseFloat(secStr);

    // Convert minutes and seconds to fractions of degrees
    const minFrac = minVal / 60;
    const secFrac = secVal / 3600;

    // Calculate the final decimal representation
    let decimalCoord = deg + minFrac + secFrac;

    // Adjust for south and west directions
    if (direction === "S" || direction === "W") {
      decimalCoord *= -1;
    }

    return decimalCoord;
  } catch (error) {
    console.error("Invalid coordinate string:", coordStr);
    return null;
  }
}

function DegreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function HaversineDistance(gpsData) {
  let totalDistance = 0.0;

  for (let i = 1; i < gpsData.length; i++) {
    const { lat: lat1, long: lon1 } = gpsData[i - 1];
    const { lat: lat2, long: lon2 } = gpsData[i];

    const dLat = DegreesToRadians(lat2 - lat1);
    const dLon = DegreesToRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(DegreesToRadians(lat1)) *
        Math.cos(DegreesToRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const earthRadius = 6371e3;
    const horizontalDistance = earthRadius * c;

    totalDistance += horizontalDistance;
  }

  return totalDistance;
}

export function extractUniqueRecords(data) {
  return new Promise((resolve, reject) => {
    const uniqueRecords = [];
    const uniqueByDate = {};

    data.forEach((entry) => {
      const dateStr = entry.date;

      // Parse the date string
      const roundedDate = moment(dateStr, "YYYY-MM-DD HH:mm:ss.SZ");

      // Check if milliseconds are not defined, then set them to 0
      if (!roundedDate.millisecond()) {
        roundedDate.millisecond(0);
      }

      // Parse and round date using Moment.js
      const roundedDateNew = moment.utc(
        roundedDate,
        "YYYY:MM:DD HH:mm:ss.S[Z]"
      );

      // Format with milliseconds and timezone
      const formattedDate = roundedDateNew.toISOString();

      // Split the date string at the decimal point, ignoring milliseconds
      const dateWithoutMs = formattedDate.split(".")[0];

      // Update entry date with rounded ISO string
      entry.date = formattedDate;

      // If the date without milliseconds isn't already in the object, add it
      if (!uniqueByDate[dateWithoutMs]) {
        uniqueByDate[dateWithoutMs] = true;
        uniqueRecords.push(entry);
      }
    });
    resolve(uniqueRecords);
  });
}

export function ParseGPSData(data) {
  return new Promise((resolve, reject) => {
    const lines = data.split("\n");
    const gpsData = [];
    let gpsObj = {};

    lines.forEach((line) => {
      const firstColonIndex = line.indexOf(":");
      const key = line.substring(0, firstColonIndex).trim();
      const value = line.substring(firstColonIndex + 1).trim();
      if (key === "GPS Date/Time") {
        if (Object.keys(gpsObj).length > 0) {
          if (!containsRequiredKeys(gpsObj)) {
            reject({ invalid_gps_data: true });
            return;
          }
          gpsData.push(gpsObj);
          gpsObj = {};
        }
        gpsObj.date = moment(value, "YYYY-MM-DD HH:mm:ss.S").toISOString();
      } else {
        switch (key) {
          case "GPS Latitude":
            gpsObj.lat = ConvertDMStoDecimal(value);
            break;
          case "GPS Longitude":
            gpsObj.long = ConvertDMStoDecimal(value);
            break;
          case "GPS Speed":
            gpsObj.speed = parseFloat(value);
            break;
          case "GPS Altitude":
            gpsObj.altitude = parseFloat(value);
            break;
          case "GPS Track":
            if (gpsData.length) {
              gpsObj.distance = HaversineDistance(gpsData);
            } else {
              gpsObj.distance = 0.0;
            }
            break;
          default:
            break;
        }
      }
    });

    if (!containsRequiredKeys(gpsObj)) {
      reject({ invalid_gps_data: true });
      return;
    }
    gpsData.push(gpsObj);

    resolve(gpsData);
  });
}

function containsRequiredKeys(obj) {
  return requiredKeys.every((key) => obj.hasOwnProperty(key));
}
