import API_HELPER from "../constants/api-urls"
import post from "./base-service"

export const languageTranslate = async (requestData) => {
    try {
      const response = await post(`${API_HELPER.URL.googleTranslateAPI}`, requestData)
      return response
    } catch (error) {
      console.log('languageTranslate service catch-----------------', error)
      return error
    }
  }