import React, { useState, useEffect } from 'react'
import { Drawer, FormHelperText, IconButton } from '@mui/material'
import * as yup from 'yup'
import { get } from 'lodash'
import { FloatingButton, MinimalSingleSelect, MinimalTextField } from '../common/form-control'
import { CloseOutlined } from '@mui/icons-material'
import { validateSchema } from '../../helpers/schema-validation'
import { sendInvite, userRoleListData } from '../../services/staff-service'
import ENUMS from '../../constants/enum'
import _ from 'lodash'
import { Toast } from '../common/toast-utils'

const InviteUser = ({ open, onClose, afterSubmit }) => {
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: null,
  })
  const [loading, setLoading] = useState(false)
  const [roleOpts, setRoleOpts] = useState([])

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      try {
        const res = await userRoleListData()
        if (res.data != null) {
          const roles = !_.isEmpty(res.data.results) ? res.data.results.map(e => ({ label: e.name, value: e.id })) : []
          setRoleOpts(roles)
        } else {
          setRoleOpts([])
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
        setRoleOpts([])
        setLoading(false)
      }
    })()
  }, [])

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value })
  }

  const validateForm = async () => {
    const schema = yup.object().shape({
      first_name: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      last_name: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      email: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW).email(ENUMS.COMMON_MSG.INVALID_EMAIL),
      role: yup.object().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
    })
    const isValid = await validateSchema(formData, schema)
    setErrors(isValid)
    if (isValid === true) {
      const request = {
        ...formData,
        role: formData.role.value,
      }
      sendStaffInvite(request)
    }
  }

  const sendStaffInvite = async request => {
    setLoading(true)
    try {
      const res = await sendInvite(request)
      if (res.success) {
        onClose()
        afterSubmit()
        Toast.success(res.data.detail)
      } else {
        Toast.error(res.error_message)
      }
    } catch (error) {
      Toast.error(error)
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <Drawer anchor='right' open={open} onClose={onClose} style={{ width: '100%' }}>
      <div className='sidebar-drawer-header' style={{ width: '100%' }}>
        <div>Invite Member </div>
        <IconButton aria-label='close' size='small' onClick={onClose}>
          <CloseOutlined style={{ color: '#fff' }} />
        </IconButton>
      </div>
      <div className='table-responsive dashboardtblScroll theme-scroll-bar' id='style-1' style={{ maxHeight: 'calc(100vh - 65px)', height: 'calc(100vh - 65px)', width: '450px' }}>
        <div style={{ padding: '10px' }}>
          <MinimalTextField
            value={get(formData, 'first_name', '')}
            onChange={e => handleInputChange('first_name', e.target.value)}
            placeholder='First Name'
            isRequired={true}
            error={errors.first_name && errors.first_name.error}
            helperText={errors.first_name && errors.first_name.msg}
            onFocus={() => {
              setErrors({ ...errors, first_name: { error: false, msg: '' } })
            }}
          />
          <MinimalTextField
            value={get(formData, 'last_name', '')}
            onChange={e => handleInputChange('last_name', e.target.value)}
            placeholder='Last Name'
            isRequired={true}
            error={errors.last_name && errors.last_name.error}
            helperText={errors.last_name && errors.last_name.msg}
            onFocus={() => {
              setErrors({ ...errors, last_name: { error: false, msg: '' } })
            }}
          />
          <MinimalTextField
            value={get(formData, 'email', '') || ''}
            onChange={e => handleInputChange('email', e.target.value)}
            placeholder='Email'
            isRequired={true}
            error={errors.email && errors.email.error}
            helperText={errors.email && errors.email.msg}
            onFocus={() => {
              setErrors({ ...errors, email: { error: false, msg: '' } })
            }}
          />
          <div className='reactselect-dropdown-block' style={{ marginTop: '16px' }}>
            <MinimalSingleSelect
              value={get(formData, 'role', '')}
              onInputChange={e => handleInputChange('role', e)}
              placeholder='Select Role'
              options={roleOpts}
              error={errors.role && errors.role.error}
              helperText={errors.role && errors.role.msg}
              onInputFocus={() => {
                setErrors({ ...errors, role: { error: false, msg: '' } })
              }}
            />
          </div>
        </div>
      </div>
      <div className='sidebar-drawer-footer' style={{ justifyContent: 'end' }}>
        <FloatingButton
          baseClassName='common-btn'
          disabled={loading}
          text='Invite'
          onClick={() => {
            validateForm()
          }}
          loading={loading}
        />
      </div>
    </Drawer>
  )
}

export default InviteUser
