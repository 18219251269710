import { ArrowBack } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import ENUMS from "../../constants/enum";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

export const CardTitle = ({
  previewVideo,
  isViewOnly,
  isDataChanged,
  setShowBackClickDialogue,
  setPreviewVideo,
}) => {
  const urlParams = useParams();
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        size="small"
        onClick={() => {
          if (!previewVideo) {
            if (isViewOnly) {
              navigate(ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.LIST);
            } else if (isDataChanged) {
              setShowBackClickDialogue(true);
            } else {
              navigate(ENUMS.ROUTES.VIRTUAL_TRAINING_PROGRAM.LIST);
            }
          } else {
            setPreviewVideo(false);
          }
        }}
      >
        <ArrowBack />
      </IconButton>
      <Typography
        component="span"
        display="inline-flex"
        alignItems="center"
        fontWeight="600"
      >
        {!previewVideo
          ? !_.isEmpty(urlParams) && urlParams.id !== 0
            ? isViewOnly
              ? `View ${ENUMS.COMMON_LABEL.Video}`
              : `Edit ${ENUMS.COMMON_LABEL.Video}`
            : `${ENUMS.COMMON_LABEL.Add} ${ENUMS.COMMON_LABEL.Video}`
          : "Preview"}
      </Typography>
    </>
  );
};
