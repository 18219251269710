import React from "react";

const Birthday = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9908_572)">
      <path
        d="M14.1658 15.8338H13.3325V15.4172C13.3348 13.3184 14.1696 11.3062 15.6537 9.82214C17.1378 8.33806 19.1499 7.50329 21.2487 7.50098H21.6654V8.33426H21.2487C19.3709 8.33636 17.5705 9.08326 16.2427 10.4111C14.9148 11.739 14.1679 13.5393 14.1658 15.4172V15.8338Z"
        fill="#2D3838"
      />
      <path
        d="M9.16621 14.5837H8.33293V14.1671C8.33172 13.1729 7.93626 12.2199 7.2333 11.5169C6.53035 10.8139 5.57728 10.4185 4.58315 10.4173H4.1665V9.58398H4.58315C5.79821 9.58542 6.9631 10.0687 7.82228 10.9279C8.68146 11.7871 9.16478 12.952 9.16621 14.1671V14.5837Z"
        fill="#2D3838"
      />
      <path
        d="M17.0823 15.0006C17.5425 15.0006 17.9156 14.6275 17.9156 14.1673C17.9156 13.7071 17.5425 13.334 17.0823 13.334C16.6221 13.334 16.249 13.7071 16.249 14.1673C16.249 14.6275 16.6221 15.0006 17.0823 15.0006Z"
        fill="#2D3838"
      />
      <path
        d="M17.9156 6.25104C18.3758 6.25104 18.7488 5.87797 18.7488 5.41776C18.7488 4.95755 18.3758 4.58447 17.9156 4.58447C17.4553 4.58447 17.0823 4.95755 17.0823 5.41776C17.0823 5.87797 17.4553 6.25104 17.9156 6.25104Z"
        fill="#2D3838"
      />
      <path
        d="M21.2488 11.6675C21.709 11.6675 22.0821 11.2945 22.0821 10.8343C22.0821 10.3741 21.709 10.001 21.2488 10.001C20.7886 10.001 20.4155 10.3741 20.4155 10.8343C20.4155 11.2945 20.7886 11.6675 21.2488 11.6675Z"
        fill="#2D3838"
      />
      <path
        d="M5.83304 14.1671C6.29325 14.1671 6.66633 13.794 6.66633 13.3338C6.66633 12.8736 6.29325 12.5005 5.83304 12.5005C5.37283 12.5005 4.99976 12.8736 4.99976 13.3338C4.99976 13.794 5.37283 14.1671 5.83304 14.1671Z"
        fill="#2D3838"
      />
      <path
        d="M11.2493 17.5005H12.9158V25.0001H11.2493V17.5005Z"
        fill="#2D3838"
      />
      <path
        d="M13.7493 17.5005H19.1656V25.0001H13.7493V17.5005Z"
        fill="#2D3838"
      />
      <path
        d="M4.99976 17.5005H10.4161V25.0001H4.99976V17.5005Z"
        fill="#2D3838"
      />
      <path
        d="M22.082 0.417969L22.7686 1.66331L24.1652 1.93163L23.1932 2.96949L23.3694 4.38066L22.082 3.77652L20.7946 4.38066L20.9708 2.96949L19.9988 1.93163L21.3954 1.66331L22.082 0.417969Z"
        fill="#2D3838"
      />
      <path
        d="M12.0826 8.08105L12.6317 9.07725L13.7492 9.29182L12.9713 10.1222L13.1125 11.2509L12.0826 10.768L11.0526 11.2509L11.1939 10.1222L10.416 9.29182L11.5335 9.07725L12.0826 8.08105Z"
        fill="#2D3838"
      />
      <path
        d="M10.7647 1.56219C10.6856 1.1104 10.4447 0.702855 10.087 0.415709C9.72933 0.128564 9.27935 -0.0185429 8.82114 0.00187218C8.36292 0.0222873 7.92781 0.208828 7.59709 0.526643C7.26638 0.844459 7.06268 1.27181 7.02406 1.72885L6.92031 3.05502L6.16619 1.95592C5.98033 1.68972 5.72899 1.47593 5.43642 1.33518C5.14385 1.19443 4.81994 1.13147 4.49596 1.15239C4.17197 1.17331 3.85884 1.27739 3.58681 1.4546C3.31477 1.6318 3.093 1.87614 2.94292 2.16403C2.79284 2.45192 2.7195 2.77364 2.72999 3.09813C2.74048 3.42263 2.83444 3.73894 3.00281 4.01653C3.17118 4.29413 3.40826 4.52363 3.69117 4.6829C3.97409 4.84216 4.29328 4.9258 4.61795 4.92575C4.67461 4.92575 4.73127 4.92325 4.78835 4.91783L7.35362 4.66785L9.70224 3.59874C10.0795 3.42376 10.3882 3.12896 10.5805 2.76023C10.7727 2.39151 10.8376 1.96956 10.7651 1.56011L10.7647 1.56219ZM4.71086 4.0883C4.52668 4.10553 4.34125 4.07316 4.17381 3.99455C4.02682 3.92654 3.89746 3.82561 3.79574 3.69957C3.69402 3.57354 3.62267 3.42578 3.58722 3.26775C3.55177 3.10972 3.55317 2.94564 3.59131 2.78824C3.62945 2.63083 3.70331 2.48432 3.80716 2.36004C3.91102 2.23575 4.04208 2.13704 4.19021 2.07155C4.33834 2.00605 4.49955 1.97553 4.66137 1.98234C4.82318 1.98915 4.98126 2.03311 5.12336 2.11082C5.26546 2.18853 5.38777 2.29791 5.48081 2.43048L6.49951 3.91539L4.71086 4.0883ZM9.7689 2.49506C9.66303 2.64574 9.51932 2.76588 9.35226 2.84337L7.71318 3.58958L7.85359 1.79801C7.87321 1.56663 7.96887 1.34824 8.12563 1.17693C8.2824 1.00561 8.49147 0.891004 8.72021 0.850984C8.78184 0.84015 8.84429 0.834713 8.90687 0.834735C9.09972 0.835085 9.2888 0.888297 9.45353 0.988585C9.61826 1.08887 9.75235 1.2324 9.84122 1.40357C9.93009 1.57473 9.97033 1.76698 9.95758 1.95942C9.94483 2.15185 9.87957 2.33711 9.7689 2.49506Z"
        fill="#2D3838"
      />
      <path
        d="M4.44016 16.4055L4.22601 16.0484C3.88035 15.476 3.39311 15.0022 2.81122 14.6727C2.22933 14.3432 1.57239 14.1692 0.903698 14.1673H0V13.334H0.903698C1.71611 13.3363 2.51424 13.5477 3.2212 13.948C3.92817 14.3483 4.52016 14.9238 4.94013 15.6193L5.1547 15.9767L4.44016 16.4055Z"
        fill="#2D3838"
      />
      <path
        d="M19.3518 16.3931L18.6064 16.0202L18.7927 15.6478C19.2004 14.8254 19.8304 14.1337 20.6112 13.6511C21.392 13.1685 22.2924 12.9144 23.2103 12.9175H24.1653V13.7508H23.2103C22.4477 13.7486 21.6998 13.9601 21.0512 14.3612C20.4026 14.7623 19.8793 15.337 19.5406 16.0202L19.3518 16.3931Z"
        fill="#2D3838"
      />
      <path
        d="M14.4879 2.3208L11.3027 3.1745C11.0275 3.69535 10.5881 4.11084 10.0528 4.35652L7.57206 5.48603L4.86805 5.74727C4.78056 5.75519 4.69889 5.75894 4.61807 5.75894C4.11138 5.76128 3.61451 5.61922 3.18565 5.34938L0 6.20308L0.647046 8.61752L15.135 4.73566L14.4879 2.3208Z"
        fill="#2D3838"
      />
    </g>
    <defs>
      <clipPath id="clip0_9908_572">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Birthday;
