import post, { deleteAction, get } from './base-service'
import API_HELPER from '../constants/api-urls'
import _ from 'lodash'

export const templateListData = async requestData => {
  try {
    let url = `${API_HELPER.URL.templateFeature}?page_size=${requestData.page_size}&page=${requestData.page_index}&search=${requestData.search_string}`
    if (!_.isEmpty(requestData.sorting)) {
      url += '&ordering=' + requestData.sorting
    }
    const response = await get(url)
    return response
  } catch (error) {
    console.log('templateListData service catch-----------------', error)
    return error
  }
}

export const addTemplate = async requestData => {
  try {
    const response = await post(`${API_HELPER.URL.templateFeature}`, requestData)
    return response
  } catch (error) {
    console.log('addTemplate service catch-----------------', error)
    return error
  }
}

export const templateDropdownList = async () => {
  try {
    const response = await get(`${API_HELPER.URL.templateDropdownList}`)
    return response
  } catch (error) {
    console.log('templateDropdownList service catch-----------------', error)
    return error
  }
}

export const getTemplateDetailById = async id => {
  try {
    const response = await get(`${API_HELPER.URL.templateFeature}${id}/`)
    return response
  } catch (error) {
    console.log('getTemplateDetailById service catch-----------------', error)
    return error
  }
}

export const templateAction = async (id, requestData) => {
  try {
    const response = await post(`${API_HELPER.URL.templateFeature}${id}/action/`, requestData)
    return response
  } catch (error) {
    console.log('templateAction service catch-----------------', error)
    return error
  }
}

export const deleteTemplate = async id => {
  try {
    const response = await deleteAction(`${API_HELPER.URL.templateFeature}${id}/`)
    return response
  } catch (error) {
    console.log('deleteTemplate service catch-----------------', error)
    return error
  }
}
