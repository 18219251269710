import { useState } from 'react'
import * as yup from 'yup'
import { Card, CardContent, CardMedia, Container, Grid, Link, CssBaseline } from '@mui/material'
import { validateSchema } from '../../helpers/schema-validation'
import { useNavigate } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import { sendCode } from '../../services/auth-services'
import ENUMS from '../../constants/enum'
import logo from '../../assets/images/logo.png'
import { FloatingButton, MinimalTextField } from '../common/form-control'
import { isEmpty } from 'lodash'
import { Toast } from '../common/toast-utils'

export default function ForgotPassword() {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    email: '',
  })
  const [errors, setErrors] = useState({
    email: { error: false, msg: '' },
  })
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(false)
  const [loading, setLoading] = useState(false)

  const checkEmailAddressValid = async payload => {
    const schema = yup.object().shape({
      email: yup.string().email(ENUMS.COMMON_MSG.INVALID_EMAIL),
    })
    const isValid = await validateSchema(payload, schema)
    return isValid
  }
  const handleEmailChange = async value => {
    if(!isEmpty(value)) value = value.trim()
    setFormValues({ ...formValues, email: value })
    const payload = { email: value }
    const isValid = await checkEmailAddressValid(payload)
    setErrors(isValid)
    if (value !== '' && isValid === true) {
      setEnableSubmitBtn(true)
    } else {
      setEnableSubmitBtn(false)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    let newFormValues = { ...formValues }
    if (newFormValues.email !== '') {
      const schema = yup.object().shape({
        email: yup.string().email(ENUMS.COMMON_MSG.INVALID_EMAIL),
      })
      const payload = {
        email: newFormValues.email,
      }
      const isValid = await validateSchema(payload, schema)
      setErrors(isValid)
      if (isValid === true) {
        setFormValues(newFormValues)
        setLoading(true)
        sendOtpCode(newFormValues)
      }
    }
  }

  const sendOtpCode = async request => {
    try {
      const res = await sendCode(request)      
      if (res.success) {
        Toast.success(res.data.detail)
        navigate('/verifycode', { state: { ...formValues, otp_expiration_time: res.data.otp_expiration_time_in_seconds } })
      } else {
        Toast.error(res.error_message)
      }
    } catch (error) {
      Toast.error(error)
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <div className='auth-section'>
      <Container component='main' maxWidth='sm'>
        <CssBaseline />
        <Card component='form' onSubmit={handleSubmit} noValidate className='auth-cardview'>
          <CardMedia className='auth-cardheader'>
            <img src={logo} alt='Skandika' onClick={() => window.location.reload()}/>
          </CardMedia>
          <CardContent sx={{ width: '100%', padding: 4 }}>
            <MinimalTextField
              placeholder='Email'
              error={errors.email && errors.email.error}
              helperText={errors.email && errors.email.msg}
              value={formValues.email}
              onInput={e => handleEmailChange(e.target.value)}
              onFocus={() => {
                setErrors({ ...errors, email: { error: false, msg: '' } })
              }}
              isRequired={true}
            />
            <FloatingButton type='submit' baseClassName='common-btn' disabled={!enableSubmitBtn || loading} text='Send Code' onClick={() => {}} loading={loading} sx={{ mt: 3, mb: 2 }} />
            <Grid container>
              <Grid item xs>
                <Link variant='body2' underline='none' className='common-link' component={RouterLink} to='/'>
                  Go Back
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
