import post, { deleteAction, get, patch } from "./base-service";
import API_HELPER from "../constants/api-urls";
import _ from "lodash";

export const virtualTrainingProgramListData = async (requestData) => {
  try {
    let url = `${API_HELPER.URL.listOrEditVirtualTraining}?page_size=${requestData.page_size}&page=${requestData.page_index}`;
    if (!_.isEmpty(requestData.search_string)) {
      url += "&search=" + requestData.search_string;
    }
    if (!_.isEmpty(requestData.updated_at)) {
      url += "&updated_at=" + requestData.updated_at;
    }
    if (!_.isEmpty(requestData.status)) {
      url += "&status=" + requestData.status;
    }
    if (!_.isEmpty(requestData.sorting)) {
      url += "&ordering=" + requestData.sorting;
    }
    const response = await get(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const addVirtualTrainingProgram = async (requestData) => {
  try {
    const response = await post(
      `${API_HELPER.URL.listOrEditVirtualTraining}`,
      requestData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateVirtualTrainingProgram = async (id, requestData) => {
  try {
    const response = await patch(
      `${API_HELPER.URL.updateOrDeleteVirtualTraining.replace(":id", id)}`,
      requestData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const virtualTrainingProgramAction = async (id, requestData) => {
  try {
    const response = await post(
      `${API_HELPER.URL.publishOrUnpublishVirtualTraining.replace(":id", id)}`,
      requestData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getVirtualTrainingProgramDetailById = async (id) => {
  try {
    const response = await get(
      `${API_HELPER.URL.listOrEditVirtualTraining}${id}/`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteVirtualTrainingProgram = async (id) => {
  try {
    const response = await deleteAction(
      `${API_HELPER.URL.updateOrDeleteVirtualTraining.replace(":id", id)}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const generatePresignedUrl = async (requestData) => {
  try {
    const response = await post(
      `${API_HELPER.URL.generatePresignedUrl}`,
      requestData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const completeVirtualTrainingUpload = async (requestData) => {
  try {
    const response = await post(
      `${API_HELPER.URL.completeVirtualTrainingUpload}`,
      requestData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const abortVirtualTrainingUpload = async (requestData) => {
  try {
    const response = await post(
      `${API_HELPER.URL.abortVirtualTrainingUpload}`,
      requestData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getVirtualTrainingUrlById = async (id) => {
  try {
    const response = await get(
      `${API_HELPER.URL.virtualTrainingURLByID}?id=${id}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompatibleDeviceForVTP = async () => {
  try {
    const response = await get(API_HELPER.URL.compatibleDevice);
    return response;
  } catch (error) {
    return error;
  }
};
