const API_HELPER = {
  BASE: process.env.REACT_APP_API_DOMAIN,
  URL: {
    login: "auth/staff/login/",
    sendCode: "auth/staff/password/reset/",
    verifyEmail: "auth/staff/password/verify-email/",
    resetPassword: "auth/staff/password/reset/confirm/",
    staffUserList: "auth/staff_user/",
    setPassword: "auth/staff/password/update/",
    sendInvite: "auth/send_invite/",
    userRoleList: "auth/staff_role/",
    deleteUser: "auth/staff_user/",
    rejectInvite: "auth/staff_user/reject/",
    templateFeature: "notifications/template/",
    templateDropdownList: "notifications/template/dropdown/",
    advertisementFeature: "notifications/advertisement/",
    staffInviteValidity: "auth/staff_invite_validity/",
    googleTranslateAPI:
      "https://translation.googleapis.com/language/translate/v2?key=" +
      process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY,
    pendingCounts: "notifications/advertisement/pending_counts/",
    birthdayExist: "notifications/advertisement/birthday_exists/",

    // Virtual Training Program Start
    generatePresignedUrl: "v2/fitness/virtual-training/generate_presigned_url/",
    completeVirtualTrainingUpload:
      "v2/fitness/virtual-training/complete_upload/",
    abortVirtualTrainingUpload: "v2/fitness/virtual-training/cancel_upload/",
    listOrEditVirtualTraining: "v2/fitness/virtual_training/",
    virtualTrainingURLByID: "v2/fitness/virtual_training/video/",
    publishOrUnpublishVirtualTraining:
      "v2/fitness/virtual_training/:id/action/",
    updateOrDeleteVirtualTraining: "v2/fitness/virtual_training/:id/",
    compatibleDevice: "v2/fitness/categories/",
    // Virtual Training Program End
  },
};
export default API_HELPER;
