import React from "react";
import "./ControlIcons.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import {
  FastRewind,
  FullscreenExit,
  FastForwardSharp,
  PlayArrowSharp,
  PauseSharp,
  VolumeUp,
  VolumeOff,
  Fullscreen,
  RestartAlt,
} from "@mui/icons-material";
import SpinnerIcons from "./Spinner";

const ControlIcons = ({
  playandpause,
  playing,
  rewind,
  fastForward,
  muting,
  muted,
  volumeChange,
  volumeSeek,
  volume,
  playRate,
  playerbackRate,
  fullScreenMode,
  onSeek,
  played,
  onSeekMouseUp,
  onSeekMouseDown,
  fullMovieTime,
  playedTime,
  fileName,
  screenfull,
  loading,
  restart,
  controlsVisible,
}) => {
  /* If you need to allow user to set speed of video then uncomment below code */
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const handlePopOver = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? "playbackrate-popover" : undefined;

  // function ValueLabelComponent(props) {
  //   const { children, value } = props;

  //   return (
  //     <Tooltip enterTouchDelay={0} placement="top" title={value}>
  //       {children}
  //     </Tooltip>
  //   );
  // }

  const prettoSliderStyles = {
    height: 5,
    padding: "5px 0",
    "& .MuiSlider-track": {
      border: "none",
      color: "#f1e7d5",
    },
    "& .MuiSlider-rail": {
      color: "#f1e7d5",
    },
    "& .MuiSlider-thumb": {
      color: "#f1e7d5",
      height: 16,
      width: 16,
      backgroundColor: "#2d3838",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  };

  return (
    <div className={`${controlsVisible || loading ? "controls__div" : ""}`}>
      {/* Top Controls */}
      {controlsVisible && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="start"
          style={{ padding: 16 }}
        >
          <Grid item>
            <Typography variant="h5" className={"light-theme-color"}>
              {fileName}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* Middle Controls */}
      {!loading ? (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            className="controls__icons"
            aria-label="reqind"
            onClick={rewind}
          >
            <FastRewind fontSize="large" className={"light-theme-color"} />
          </IconButton>

          <IconButton
            className="controls__icons"
            aria-label="reqind"
            onClick={playandpause}
          >
            {playing ? (
              <PauseSharp fontSize="large" className={"light-theme-color"} />
            ) : (
              <PlayArrowSharp
                fontSize="large"
                className={"light-theme-color"}
              />
            )}
          </IconButton>

          <IconButton
            className="controls__icons"
            aria-label="reqind"
            onClick={fastForward}
          >
            <FastForwardSharp
              fontSize="large"
              className={"light-theme-color"}
            />
          </IconButton>
        </Grid>
      ) : (
        <SpinnerIcons />
      )}

      {/* Bottom Controls */}
      {controlsVisible && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: 16 }}
        >
          {/* To set file name above the main slider uncomment the below code. */}
          {/* <Grid item>
          <Typography variant="h5" className={"light-theme-color"}>
            {fileName}
          </Typography>
        </Grid> */}

          <Grid item xs={12}>
            <Slider
              sx={prettoSliderStyles}
              min={0}
              max={100}
              value={played * 100}
              onChange={onSeek}
              onMouseDown={onSeekMouseDown}
              onChangeCommitted={onSeekMouseUp}
              // Uncomment below code to display tooltip on slider
              // valueLabelDisplay="auto"
              // aria-label="custom thumb label"
              // components={{
              //   ValueLabel: ValueLabelComponent,
              // }}
            />
            <Grid container direction="row" justifyContent="space-between">
              <Typography variant="h7" className={"light-theme-color"}>
                {playedTime}
              </Typography>
              <Typography variant="h7" className={"light-theme-color"}>
                {fullMovieTime}
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" direction="row">
              <IconButton
                className="controls__icons"
                aria-label="reqind"
                onClick={playandpause}
              >
                {playing ? (
                  <PauseSharp
                    fontSize="large"
                    className={"light-theme-color"}
                  />
                ) : (
                  <PlayArrowSharp
                    fontSize="large"
                    className={"light-theme-color"}
                  />
                )}
              </IconButton>
              <IconButton
                className="controls__icons"
                aria-label="reqind"
                onClick={restart}
              >
                <RestartAlt fontSize="large" className={"light-theme-color"} />
              </IconButton>

              {/* To enable volume control, remove the false condition below. */}
              {false && (
                <>
                  <IconButton
                    className="controls__icons"
                    aria-label="reqind"
                    onClick={muting}
                  >
                    {muted ? (
                      <VolumeOff
                        fontSize="large"
                        className={"light-theme-color"}
                      />
                    ) : (
                      <VolumeUp
                        fontSize="large"
                        className={"light-theme-color"}
                      />
                    )}
                  </IconButton>

                  <Typography className={"light-theme-color"}>
                    {volume * 100}
                  </Typography>
                  <Slider
                    min={0}
                    max={100}
                    value={volume * 100}
                    onChange={volumeChange}
                    onChangeCommitted={volumeSeek}
                    className="volume__slider"
                  />
                </>
              )}
            </Grid>
          </Grid>

          <Grid item>
            {/* If you need to allow user to set speed of video then uncomment below code */}
            {/* <Button
            variant="text"
            onClick={handlePopOver}
            className="bottom__icons"
          >
            <Typography>{playerbackRate}X</Typography>
          </Button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Grid container direction="column-reverse">
              {[0.5, 1, 1.5, 2].map((rate) => (
                <Button variant="text" onClick={() => playRate(rate)}>
                  <Typography
                    color={rate === playerbackRate ? "secondary" : "default"}
                  >
                    {rate}
                  </Typography>
                </Button>
              ))}
            </Grid>
          </Popover> */}

            <IconButton
              className="bottom__icons fullscreen"
              onClick={fullScreenMode}
            >
              {!screenfull.isFullscreen ? (
                <Fullscreen fontSize="large" />
              ) : (
                <FullscreenExit fontSize="large" />
              )}
            </IconButton>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ControlIcons;
