import React, { useState } from 'react'
import { Box, Typography, Dialog, DialogContent, DialogActions, Tab, Tabs, Grid, InputAdornment, FormHelperText, DialogTitle, IconButton } from '@mui/material'
import { FloatingButton, MinimalTextArea, MinimalTextField } from './form-control'
import { get, isEmpty } from 'lodash'
import { Close, CloudUpload } from '@mui/icons-material'
import Android from '../../assets/images/Android.png'
import Apple from '../../assets/images/Apple.png'
import BlankImage from '../../assets/images/BlankImage.jpg'
import ENUMS from '../../constants/enum'
export const StatusComponent = ({ color, label, size, filled, hasDarkContrast = false }) => {
  const padding = size === 'small' ? '2px 10px' : '2px 12px'
  const fontSize = size === 'small' ? '12px' : size === 'medium' ? '14px' : '16px'
  let styles = { padding, fontWeight: 400, borderRadius: size === 'small' ? '12px' : '12px', fontSize, background: `${color}21`, color, border: `1px solid ${color}`, whiteSpace: 'nowrap' }
  if (filled) styles = { padding, fontWeight: 400, borderRadius: size === 'small' ? '12px' : '12px', fontSize, background: `${color}`, color: hasDarkContrast ? '#000' : '#fff', whiteSpace: 'nowrap' }
  return (
    <Box component='span' style={styles}>
      {label}
    </Box>
  )
}

export const NoRecordFoundBox = ({ img_url, text = 'No records found' }) => {
  return (
    <Box className='no-record-box'>
      <img src={img_url} alt='No record found' style={{ width: '150px' }} />
      <Typography component='p'>{text}</Typography>
    </Box>
  )
}

export const DialogPrompt = ({ open, title, confirm_btntext = 'Yes', cancel_btntext = 'No', loading, onCancel, onConfirm }) => {
  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', boxShadow: 'unset' } }} maxWidth='xs' open={open}>
      <DialogContent>
        <Typography component='p'>{title}</Typography>
      </DialogContent>
      <DialogActions>
        <FloatingButton baseClassName='common-btn confirm-common-btn common-btn-gray' text={cancel_btntext} onClick={onCancel} />
        <FloatingButton baseClassName='common-btn confirm-common-btn' text={confirm_btntext} onClick={onConfirm} loading={loading} />
      </DialogActions>
    </Dialog>
  )
}

export const CharacterCounter = ({ text, limit }) => {
  return (
    <InputAdornment position='end'>
      <span style={{ color: '#F20000' }}>
        {text.length}/{limit}
      </span>
    </InputAdornment>
  )
}

export const LanguageContentCard = ({ title, id_initials, file_input_ref, form_input_content, errors, onInputChange = () => {}, onHandleFocus = () => {}, onHandleFileSelect = () => {}, onHandleClearImage = () => {}, disabled = false }) => {
  const titleCharLimit = 60
  const descriptionCharLimit = 400
  const [previewContent, setPreviewContent] = useState({
    isVisible: false,
    title: '',
    description: '',
    image: null,
  })

  const showPreview = (title, description, image) => {
    const detail = {
      title,
      description,
      image,
      isVisible: true,
    }
    setPreviewContent(detail)
  }
  const handleBoxClick = fileInputRef => {
    // Trigger the hidden file input
    fileInputRef.current.click()
  }
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component='h4'>
          <b>{title}</b>
        </Typography>
        <FloatingButton baseClassName='common-btn' text='Preview' onClick={() => showPreview(form_input_content.title, form_input_content.description, form_input_content.image)} />
      </Box>
      <MinimalTextField
        value={get(form_input_content, 'title', '')}
        onChange={onInputChange}
        placeholder='Announcement Title'
        isRequired={true}
        error={errors.title && errors.title.error}
        helperText={errors.title && errors.title.msg}
        id={id_initials + '.title'}
        endAdornmentProp={<CharacterCounter text={get(form_input_content, 'title', '')} limit={titleCharLimit} />}
        name='title'
        onFocus={onHandleFocus}
        disabled={disabled}
      />
      <MinimalTextArea
        value={get(form_input_content, 'description', '')}
        onChange={onInputChange}
        placeholder='Announcement Description'
        isRequired={true}
        error={errors.description && errors.description.error}
        helperText={errors.description && errors.description.msg}
        id={id_initials + '.description'}
        endAdornmentProp={<CharacterCounter text={get(form_input_content, 'description', '')} limit={descriptionCharLimit} />}
        name='description'
        onFocus={onHandleFocus}
        disabled={disabled}
      />
      <div style={{ marginBottom: '40px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mt: '16px' }}>
            <Box elevation={3} style={{ height: '170px' }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box elevation={3} className='dashed-border no-record-box' sx={{ height: '150px', padding: '10px' }}>
                    <input ref={file_input_ref} type='file' accept='image/*' style={{ display: 'none' }} onChange={onHandleFileSelect} required id={id_initials + '.image'} />
                    {disabled && isEmpty(form_input_content.image) && <Typography component='p'>No Image Available</Typography>}
                    {!disabled && isEmpty(form_input_content.image) && (
                      <>
                        <CloudUpload sx={{ fontSize: '55px', color: ENUMS.THEME.MAIN_COLOR }} />
                        <Typography component='p'>Upload your image here</Typography>
                      </>
                    )}
                    {!isEmpty(form_input_content.image) && <img src={form_input_content.image} alt='german content img' />}
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                  <div>
                    <FloatingButton baseClassName='common-btn' text='Clear' onClick={onHandleClearImage} sx={{ mb: 2 }} disabled={disabled || isEmpty(form_input_content.image)} />
                    <FloatingButton baseClassName='common-btn' text='Browse' onClick={() => handleBoxClick(file_input_ref)} disabled={disabled} />
                  </div>
                </Grid>
              </Grid>
              <FormHelperText error={errors.image && errors.image.error}>{errors.image && errors.image.msg}</FormHelperText>
              {/* Content for the left grid box */}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box elevation={3} style={{ height: '170px' }}>
              {/* Content for the right grid box */}
              <MinimalTextField value={get(form_input_content, 'redirect_url', '') || ''} onChange={onInputChange} placeholder='Redirection URL' isRequired={true} error={errors.redirect_url && errors.redirect_url.error} helperText={errors.redirect_url && errors.redirect_url.msg} name='redirect_url' onFocus={onHandleFocus} disabled={disabled} />
            </Box>
          </Grid>
        </Grid>
      </div>
      <PreviewDialogPrompt open={previewContent.isVisible} title={previewContent.title} description={previewContent.description} image={previewContent.image} onCancel={() => setPreviewContent({ ...previewContent, isVisible: false })} />
    </>
  )
}

export const PreviewDialogPrompt = ({ open, title = 'Announcement Title', description = 'Announcement Description', image = null, onCancel = () => {} }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }
  const selectedImage = !isEmpty(image) ? image : BlankImage
  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '100%' } }} open={open} className='preview-dialog' maxWidth='md'>
      <DialogTitle>Notification Preview</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#fff',
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Tabs value={selectedTab} onChange={handleTabChange} className='preview-tab'>
          <Tab label='Initial View' />
          <Tab label='Expand View' />
        </Tabs>
        {selectedTab === 0 && (
          <Box>
            <Grid container spacing={2} sx={{ mt: '16px', paddingBottom: '2px' }}>
              <Grid item xs={6}>
                <Typography component='p' sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  Apple
                </Typography>
                <div className='imageview-box'>
                  <img src={Apple} alt='iphone' />
                  <div className='contentview-box'>
                    <div className='image-view'>
                      <img src={selectedImage} alt='blank' />
                    </div>
                    <div className='details-view'>
                      <p>{title}</p>
                      <p className='twoline-epllipsis'>{description}</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography component='p' sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  Android
                </Typography>
                <div className='imageview-box'>
                  <img src={Android} alt='android' />
                  <div className='contentview-box'>
                    <div className='image-view'>
                      <img src={selectedImage} alt='blank' />
                    </div>
                    <div className='details-view'>
                      <p>{title}</p>
                      <p className='twoline-epllipsis'>{description}</p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        )}
        {selectedTab === 1 && (
          <Box>
            <Grid container spacing={2} sx={{ mt: '16px', paddingBottom: '2px' }}>
              <Grid item xs={6}>
                <Typography component='p' sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  Apple
                </Typography>
                <div className='imageview-box expand-view'>
                  <img src={Apple} alt='iphone' />
                  <div className='contentview-box'>
                    <div className='image-view'>
                      <img src={selectedImage} alt='blank' />
                    </div>
                    <div className='details-view'>
                      <p>{title}</p>
                      <p className='twoline-epllipsis'>{description}</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography component='p' sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  Android
                </Typography>
                <div className='imageview-box expand-view'>
                  <img src={Android} alt='android' />
                  <div className='contentview-box'>
                    <div className='image-view'>
                      <img src={selectedImage} alt='blank' />
                    </div>
                    <div className='details-view'>
                      <p>{title}</p>
                      <p className='twoline-epllipsis'>{description}</p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
